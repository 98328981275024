import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setEnvironment, setNewEnvironment } from "../../slices/riskSlice";

const TableCellEnviroment = ({item, addNew}) => {

    const [environment2, setEnvironment2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdenvironment, setCheckedIdenvironment] = useState("");
    const [addEnvironment, setAddEnvironment] = useState([]);
    const [addEnvironment2, setAddEnvironment2] = useState([]);
    const [environment, setStateEnvironment] = useState([]);
    const dispatch = useDispatch();

    return (
        <>     
            {/* {(!item && addNew) &&
                <>
                    {addEnvironment ? 
                        <input type="text" className="autofilssInput" value={environment} onChange={(e) => dispatch(setNewEnvironment(e.target.value)) && setStateEnvironment(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddEnvironment(true)}}>+ Add</button>
                    }
                </>
            }                                                                     */}
            {item &&
                <div class="hovers" style={item.environment[0] === "" ? {overflow: "hidden"} : {}}>                
                    <ul style={item.environment[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.environment.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addEnvironment.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setEnvironment2(
                                                                (environment2.some((el, ind) => ind == indx)) 
                                                                ? environment2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...environment2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setEnvironment(
                                                                    [   
                                                                        item.id, 
                                                                        (environment2.some((el, ind) => ind == indx)) 
                                                                        ? [...environment2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...environment2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddEnvironment([...addEnvironment, true]); setCheckedIdenvironment(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addEnvironment2 && checkedIdenvironment == item.id 
                                    //     ? 
                                    //     <input type="text" class="autofilssInput" value={checkedIdenvironment == item.id ? environment2 : ""} onChange={(e) => {dispatch(setEnvironment([item.id, [e.target.value]])) && setEnvironment2(e.target.value); setCheckedItem(item); setCheckedIdenvironment(item.id)}}></input>
                                    //     :
                                    //     <button onClick={(e) => {e.preventDefault(); setAddEnvironment2(true); setCheckedIdenvironment(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(environment.includes(index) && checkedIdenvironment == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault(); 
                                                environment.some(i => i == index)
                                                    ? dispatch(setEnvironment([item.id, (environment2.length != 0) ? [...environment.filter((i) =>  i !== index), ...environment2] : environment.filter((i) =>  i !== index)])) && setStateEnvironment(environment.filter((i) =>  i !== index))
                                                    : dispatch(setEnvironment([item.id, (environment2.length != 0) ? [...[...environment, index], ...environment2] : [...environment, index]])) && setStateEnvironment([...environment, index])
                                                setCheckedIdenvironment(item.id);
                                                }
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setEnvironment(environment.includes(index) ? environment.filter((i) =>  i !== index) : [...environment, index]); setCheckedIdenvironment(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addEnvironment2 && checkedIdenvironment == item.id 
                            && 
                            addEnvironment2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setEnvironment2(
                                            (environment2.some((el, ind) => ind == index)) 
                                            ? environment2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...environment2, e.target.value]
                                        ); 
                                        dispatch(
                                            setEnvironment([item.id,
                                                (environment2.some((el, ind) => ind == index)) 
                                                ? [...environment, ...[...environment2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...environment, ...[...environment2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.environment[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddEnvironment2([...environment2, true]); setCheckedIdenvironment(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addEnvironment2 && checkedIdenvironment == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={checkedIdenvironment == item.id ? environment2 : ""} onChange={(e) => {dispatch(setEnvironment([item.id, [...environment, e.target.value]])) && setEnvironment2([e.target.value]); setCheckedItem(item) ; setCheckedIdenvironment(item.id)}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddEnvironment2(true); setCheckedIdenvironment(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellEnviroment;