import React, { useState,  useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserId, setToken, setisLogin, setisAdmin, getProfile, createProfile, setDateUser, setNameUser } from '../slices/userSlise';
import Risk2Service from '../services/risk2-services';
import axios from 'axios';
import Main from './Main';
import { Modal} from "antd";
import '../App.css';
import ProfileSettingsService from '../services/profileSettings-service';
const Lkabinet = () => {
	const storedAuthToken = localStorage.getItem("authToken");
	// const currentUserId = localStorage.getItem("CurrentUserId");
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
	const { user } = useSelector((state) => state);
	const navigate = useNavigate();
	const [data, setData] = useState({risks: []});
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
  	const [error, setError] = useState(null);
	const [openSignUp , setOpenSignUp] = useState(false)
	const [day, setDay] = useState(userData ? userData.day : 1);
	const [monthBirthday, setMonthBirthday] = useState(userData ? userData.monthBirthday : "1");
    const [yearBirthday, setYearBirthday] = useState(userData ? userData.yearBirthday : new Date().getFullYear());
	const [gender, setGender] = useState(userData ? userData.gender : "");
	// const [username, setName] = useState((userData != null) ? userData.username : "");
	const [username, setName] = useState("");

	// console.log(username);
	const [userLastName, setLastName] = useState(userData ? userData.userLastName : "");
    const [password, setPassword] = useState(userData ? userData.password : "");
	const [workExperience, setWorkExperience] = useState(userData ? userData.workExperience : "");
	const [hseBio, setHSEBio] = useState(userData ? userData.hseBio : "");
	const [educationLevel, setEducationLevel] = useState(userData ? userData.educationLevel : "");
	const [otherEducational, setOtherEducational] = useState(userData ? userData.otherEducational : "");
    const [contact, setContact] = useState(userData ? userData.contact : "");
	const [language, setLanguage] = useState(userData ? userData.language : "1");
	const [oldPassword, setOldPassword] = useState(userData ? userData.oldPassword : "");
	const [newPassword, setNewPassword] = useState(userData ? userData.newPassword : "");
	// const [email, setEmail] = useState(userData ? userData.email : "");
	const [email, setEmail] = useState('');
    const [yearOfWork, setYearOfWork] = useState(userData ? userData.yearOfWork : "");
	const [monthOfWork, setMonthOfWork] = useState(userData ? userData.monthOfWork : "");
	const currentUserId = user.currentUserId;
	const [whoAmI, setWhoAmI] = useState('user');

	useEffect(() => {
		setEmail(user.email)
	}, [user])

	useEffect(() => {
		if(storedAuthToken && user.isLogin){
			async function getRisks(){
				const risks2 = await Risk2Service.getAllRisks2()
				setData({risks: risks2});
			}
			getRisks()
		}

	}, [user, storedAuthToken])
	useEffect(() => {
		if(storedAuthToken && user.isLogin){
			async function getProfile(){
				const profile = await ProfileSettingsService.getOneProfile(user.currentUserId);
				if(!!profile){
					dispatch(setNameUser(profile.username));
					setUserData(profile);
					setName(profile.username);
					setLastName(profile.userLastName);
				}					
			}
			getProfile()
		}

	}, [user, storedAuthToken])
	// console.log(userData)
	// useEffect(() => {
	// 	async function fetchData1() {
	// 		try {
	// 		if (user.currentUserId) {
	// 			console.log(user);
	// 		  const response = await axios.get(`http://${host}:3001/lk${user.currentUserId}`);
	// 		  const data = response.data
	// 		  console.log("DATA 1", data)
	// 		  setUserData(data);
	// 		  //setSecondName(data.user.username);
	// 		}
	// 		} catch (err) {
	// 		  console.log(err);
	// 		} 
	// 	  }
	// 	fetchData1();
	// 	async function fetchData() {
	// 	  try {
	// 		const response = await axios.get(`http://${host}:3001/risktable2/`);
	// 		const data = response.data
	// 		console.log(data)
	// 		setData(data);
	// 	  } catch (err) {
	// 		setError(err);
	// 	  } finally {
	// 		setIsLoading(false);
	// 	  }
	// 	}
	
	// 	fetchData();
	//   }, []);
	  
	const closeModalSignUp = () => {
		setOpenSignUp(false)
	}
	const handleDayChange = (value) => {
		console.log(value)
		setDay(value);
	  };
	const generateDays = () => {
		const days = [];
		for (let i = 1; i <= 31; i++) {
		  days.push(
			<option key={i} value={i}>
			  {i}
			</option>
		  );
		}
		return days;
	  };
	const currentYear = new Date().getFullYear();
  	const years = [];
  	for (let year = currentYear; year >= currentYear - 80; year--) {
    	years.push(year);
  	}
	const countries = [
	{ value: 'AF', label: 'Afghanistan' },
	{ value: 'AL', label: 'Albania' },
	{ value: 'DZ', label: 'Algeria' },
	{ value: 'AD', label: 'Andorra' },
	{ value: 'AO', label: 'Angola' },
	{ value: 'AG', label: 'Antigua and Barbuda' },
	{ value: 'AR', label: 'Argentina' },
	{ value: 'AM', label: 'Armenia' },
	{ value: 'AU', label: 'Australia' },
	{ value: 'AT', label: 'Austria' },
	{ value: 'AZ', label: 'Azerbaijan' },
	{ value: 'BS', label: 'Bahamas' },
	{ value: 'BH', label: 'Bahrain' },
	{ value: 'BD', label: 'Bangladesh' },
	{ value: 'BB', label: 'Barbados' },
	{ value: 'BY', label: 'Belarus' },
	{ value: 'BE', label: 'Belgium' },
	{ value: 'BZ', label: 'Belize' },
	{ value: 'BJ', label: 'Benin' },
	{ value: 'BT', label: 'Bhutan' },
	{ value: 'BO', label: 'Bolivia' },
	{ value: 'BA', label: 'Bosnia and Herzegovina' },
	{ value: 'BW', label: 'Botswana' },
	{ value: 'BR', label: 'Brazil' },
	{ value: 'BN', label: 'Brunei' },
	{ value: 'BG', label: 'Bulgaria' },
	{ value: 'BF', label: 'Burkina Faso' },
	{ value: 'BI', label: 'Burundi' },
	{ value: 'CV', label: 'Cabo Verde' },
	{ value: 'KH', label: 'Cambodia' },
	{ value: 'CM', label: 'Cameroon' },
	{ value: 'CA', label: 'Canada' },
	{ value: 'CF', label: 'Central African Republic' },
	{ value: 'TD', label: 'Chad' },
	{ value: 'CL', label: 'Chile' },
	{ value: 'CN', label: 'China' },
	{ value: 'CO', label: 'Colombia' },
	{ value: 'KM', label: 'Comoros' },
	{ value: 'CG', label: 'Congo' },
	{ value: 'CR', label: 'Costa Rica' },
	{ value: 'HR', label: 'Croatia' },
	{ value: 'CU', label: 'Cuba' },
	{ value: 'CY', label: 'Cyprus' },
	{ value: 'CZ', label: 'Czech Republic' },
	{ value: 'DK', label: 'Denmark' },
	{ value: 'DJ', label: 'Djibouti' },
	{ value: 'DM', label: 'Dominica' },
	{ value: 'DO', label: 'Dominican Republic' },
	{ value: 'EC', label: 'Ecuador' },
	{ value: 'EG', label: 'Egypt' },
	{ value: 'SV', label: 'El Salvador' },
	{ value: 'GQ', label: 'Equatorial Guinea' },
	{ value: 'ER', label: 'Eritrea' },
	{ value: 'EE', label: 'Estonia' },
	{ value: 'SZ', label: 'Eswatini' },
	{ value: 'ET', label: 'Ethiopia' },
	{ value: 'FJ', label: 'Fiji' },
	{ value: 'FI', label: 'Finland' },
	{ value: 'FR', label: 'France' },
	{ value: 'GA', label: 'Gabon' },
	{ value: 'GM', label: 'Gambia' },
	{ value: 'GE', label: 'Georgia' },
	{ value: 'DE', label: 'Germany' },
	{ value: 'GH', label: 'Ghana' },
	{ value: 'GR', label: 'Greece' },
	{ value: 'GD', label: 'Grenada' },
	{ value: 'GT', label: 'Guatemala' },
	{ value: 'GN', label: 'Guinea' },
	{ value: 'GW', label: 'Guinea-Bissau' },
	{ value: 'GY', label: 'Guyana' },
	{ value: 'HT', label: 'Haiti' },
	{ value: 'HN', label: 'Honduras' },
	{ value: 'HU', label: 'Hungary' },
	{ value: 'IS', label: 'Iceland' },
	{ value: 'IN', label: 'India' },
	{ value: 'ID', label: 'Indonesia' },
	{ value: 'IR', label: 'Iran' },
	{ value: 'IQ', label: 'Iraq' },
	{ value: 'IE', label: 'Ireland' },
	{ value: 'IL', label: 'Israel' },
	{ value: 'IT', label: 'Italy' },
	{ value: 'JM', label: 'Jamaica' },
	{ value: 'JP', label: 'Japan' },
	{ value: 'JO', label: 'Jordan' },
	{ value: 'KZ', label: 'Kazakhstan' },
	{ value: 'KE', label: 'Kenya' },
	{ value: 'KI', label: 'Kiribati' },
	{ value: 'KP', label: 'Korea, North' },
	{ value: 'KR', label: 'Korea, South' },
	{ value: 'XK', label: 'Kosovo' },
	{ value: 'KW', label: 'Kuwait' },
	{ value: 'KG', label: 'Kyrgyzstan' },
	{ value: 'LA', label: 'Laos' },
	{ value: 'LV', label: 'Latvia' },
	{ value: 'LB', label: 'Lebanon' },
	{ value: 'LS', label: 'Lesotho' },
	{ value: 'LR', label: 'Liberia' },
	{ value: 'LY', label: 'Libya' },
	{ value: 'LI', label: 'Liechtenstein' },
	{ value: 'LT', label: 'Lithuania' },
	{ value: 'LU', label: 'Luxembourg' },
	{ value: 'MG', label: 'Madagascar' },
	{ value: 'MW', label: 'Malawi' },
	{ value: 'MY', label: 'Malaysia' },
	{ value: 'MV', label: 'Maldives' },
	{ value: 'ML', label: 'Mali' },
	{ value: 'MT', label: 'Malta' },
	{ value: 'MH', label: 'Marshall Islands' },
	{ value: 'MR', label: 'Mauritania' },
	{ value: 'MU', label: 'Mauritius' },
	{ value: 'MX', label: 'Mexico' },
	{ value: 'FM', label: 'Micronesia' },
	{ value: 'MD', label: 'Moldova' },
	{ value: 'MC', label: 'Monaco' },
	{ value: 'MN', label: 'Mongolia' },
	{ value: 'ME', label: 'Montenegro' },
	{ value: 'MA', label: 'Morocco' },
	{ value: 'MZ', label: 'Mozambique' },
	{ value: 'MM', label: 'Myanmar (Burma)' },
	{ value: 'NA', label: 'Namibia' },
	{ value: 'NR', label: 'Nauru' },
	{ value: 'NP', label: 'Nepal' },
	{ value: 'NL', label: 'Netherlands' },
	{ value: 'NZ', label: 'New Zealand' },
	{ value: 'NI', label: 'Nicaragua' },
	{ value: 'NE', label: 'Niger' },
	{ value: 'NG', label: 'Nigeria' },
	{ value: 'MK', label: 'North Macedonia (formerly Macedonia)' },
	{ value: 'NO', label: 'Norway' },
	{ value: 'OM', label: 'Oman' },
	{ value: 'PK', label: 'Pakistan' },
	{ value: 'PW', label: 'Palau' },
	{ value: 'PA', label: 'Panama' },
	{ value: 'PG', label: 'Papua New Guinea' },
	{ value: 'PY', label: 'Paraguay' },
	{ value: 'PE', label: 'Peru' },
	{ value: 'PH', label: 'Philippines' },
	{ value: 'PL', label: 'Poland' },
	{ value: 'PT', label: 'Portugal' },
	{ value: 'QA', label: 'Qatar' },
	{ value: 'RO', label: 'Romania' },
	{ value: 'RU', label: 'Russia' },
	{ value: 'RW', label: 'Rwanda' },
	{ value: 'KN', label: 'Saint Kitts and Nevis' },
	{ value: 'LC', label: 'Saint Lucia' },
	{ value: 'VC', label: 'Saint Vincent and the Grenadines' },
	{ value: 'WS', label: 'Samoa' },
	{ value: 'SM', label: 'San Marino' },
	{ value: 'ST', label: 'Sao Tome and Principe' },
	{ value: 'SA', label: 'Saudi Arabia' },
	{ value: 'SN', label: 'Senegal' },
	{ value: 'RS', label: 'Serbia' },
	{ value: 'SC', label: 'Seychelles' },
	{ value: 'SL', label: 'Sierra Leone' },
	{ value: 'SG', label: 'Singapore' },
	{ value: 'SK', label: 'Slovakia' },
	{ value: 'SI', label: 'Slovenia' },
	{ value: 'SB', label: 'Solomon Islands' },
	{ value: 'SO', label: 'Somalia' },
	{ value: 'ZA', label: 'South Africa' },
	{ value: 'SS', label: 'South Sudan' },
	{ value: 'ES', label: 'Spain' },
	{ value: 'LK', label: 'Sri Lanka' },
	{ value: 'SD', label: 'Sudan' },
	{ value: 'SR', label: 'Suriname' },
	{ value: 'SE', label: 'Sweden' },
	{ value: 'CH', label: 'Switzerland' },
	{ value: 'SY', label: 'Syria' },
	{ value: 'TW', label: 'Taiwan' },
	{ value: 'TJ', label: 'Tajikistan' },
	{ value: 'TZ', label: 'Tanzania' },
	{ value: 'TH', label: 'Thailand' },
	{ value: 'TG', label: 'Togo' },
	{ value: 'TO', label: 'Tonga' },
	{ value: 'TT', label: 'Trinidad and Tobago' },
	{ value: 'TN', label: 'Tunisia' },
	{ value: 'TR', label: 'Turkey' },
	{ value: 'TM', label: 'Turkmenistan' },
	{ value: 'TV', label: 'Tuvalu' },
	{ value: 'UG', label: 'Uganda' },
	{ value: 'UA', label: 'Ukraine' },
	{ value: 'AE', label: 'United Arab Emirates' },
	{ value: 'GB', label: 'United Kingdom' },
	{ value: 'US', label: 'United States' },
	{ value: 'UY', label: 'Uruguay' },
	{ value: 'UZ', label: 'Uzbekistan' },
	{ value: 'VU', label: 'Vanuatu' },
	{ value: 'VA', label: 'Vatican City' },
	{ value: 'VE', label: 'Venezuela' },
	{ value: 'VN', label: 'Vietnam' },
	{ value: 'YE', label: 'Yemen' },
	{ value: 'ZM', label: 'Zambia' },
	{ value: 'ZW', label: 'Zimbabwe' }
	];
	const [selectedCountry, setSelectedCountry] = useState(userData ? userData.selectedCountry : "");
	
	const handleChange = (event) => {
		setSelectedCountry(event.target.value);
	};

		//console.log("state", user)
  	const dispatch = useDispatch();
	const handleOnSubmit = async (e) => {
        e.preventDefault();
		try {
			dispatch(createProfile({ 
				currentUserId,
				username,	
				userLastName, 
				oldPassword, 
				newPassword, 
				password, 
				email, 
				contact, 
				language, 
				day, 
				monthBirthday, 
				yearBirthday, 
				gender, 
				selectedCountry, 
				educationLevel, 
				otherEducational,
				monthOfWork,
				yearOfWork,
				workExperience,
				hseBio,
			}));
			setOpenSignUp(true);
		} catch (error) {
			console.log(error);
		}
    }
	// const handleOnSubmit = async (e) => {
		
    //     e.preventDefault();
    //     let result = await fetch(
    //     `http://${host}:3001/lk`, {
    //         method: "post",
    //         body: JSON.stringify({ userId: currentUserId,
	// 								username,
	// 			                   userLastName, 
	// 							   oldPassword, 
	// 							   newPassword, 
	// 							   password, 
	// 							   email, 
	// 							   contact, 
	// 							   language, 
	// 							   day, 
	// 							   monthBirthday, 
	// 							   yearBirthday, 
	// 							   gender, 
	// 							   selectedCountry, 
	// 							   educationLevel, 
	// 							   otherEducational,
	// 							   monthOfWork,
	// 							   yearOfWork,
	// 							   workExperience,
	// 							   hseBio,
	// 							}),
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
		
    //    	result = await result.json();
	// 	console.log("LK result", result)
    //     if (result.message) {
	// 		console.log("login error", result)
	// 		setError(result.message);
	// 	} else {
	// 		dispatch(setToken(result.token));
	// 		localStorage.setItem("authToken", result.token);
	// 		dispatch(setisLogin(true));
	// 		setError(null);
    //         //dispatch(setCurrentUserId(result.user.id));
	// 		setOpenSignUp(true);
	// 		navigate('/');
    //     }
    // }
	const handleOnUpdate = async (e) => {
        e.preventDefault();
		let result = await fetch(
			`http://${host}:3001/profile/update/${currentUserId}`, {
				method: "put",
				body: JSON.stringify({ 	
					userId: currentUserId,
					username,
					userLastName, 
					oldPassword, 
					newPassword, 
					password, 
					email, 
					contact, 
					language, 
					day, 
					monthBirthday, 
					yearBirthday, 
					gender, 
					selectedCountry, 
					educationLevel, 
					otherEducational,
					monthOfWork,
					yearOfWork,
					workExperience,
					hseBio,
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${storedAuthToken}`
				}
			}
		)
		result = await result.json();
		console.log("LK result", result)
		if (result.message) {
			console.log("login error", result)
			setError(result.message);
		} else {
			dispatch(setToken(result.token));
			setError(null);
			dispatch(setisLogin(true));
			//dispatch(setCurrentUserId(result.user.id));
			//setOpenSignUp(true);
			//navigate('/');
		}
		setOpenSignUp(true);
		setPassword('')
		setOldPassword('')
		setNewPassword('')
	}
	useEffect(() => {
		if(user.isAdmin){
			setWhoAmI('admin')
		}else if(user.isReviewer){
			setWhoAmI('reviewer')
		}else if(user.isApprover){
			setWhoAmI('approver')
		}
	}, [user])
	const renderCurrentNotification = () => {
		switch (whoAmI) {
			case "user":
				return (
					<>
					<span class="iconnoty2"> 
						<span class="iconnoty-count">
							{data.risks.filter(item => item.userid == user.currentUserId).length}
						</span> 
					</span>
					</>
				)
			case "admin":
				return (
					<>
						<span class="iconnoty2"> 
							<span class="iconnoty-count">{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '1' && item.statusApprove == '1').length}</span>
						</span>
					</>
				)
			case "reviewer":
				return (
					<>
						<span class="iconnoty2"> 
							<span class="iconnoty-count">
								{
									data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '0').length +
									data.risks.filter(item => item.userid == user.currentUserId).length
								}
							</span>
						</span>
					</>
				)
			case "approver":
				return (
					<>
						<span class="iconnoty2"> 
							<span class="iconnoty-count">
								{
									data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '1' && item.statusApprove == '0').length +
									data.risks.filter(item => item.userid == user.currentUserId).length
								}
							</span>
						</span>
					</>
				)
			default:
				return null;
		}
	}
	return (
		<div>
			{storedAuthToken ? 
			<main>
				<div class="container">
					<div class="lk-lefttoright">
						<div class="lk-lefttoright-l">
							<div class="maxforms">
								<div class="lkblockedits">
									<form class="setting-table" method="post" target="_top" action="" >
										<div class="heads-form">
											<div class="heads-form1">Profile settings</div>
											<div class="heads-form2"><img src="/img/account_box.svg"/><span class="textfr">Edit</span><span class="editicon fellow"></span></div>
										</div>
										<div class="body-form">
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Profile name</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value={username} onChange={(e) => setName(e.target.value)} placeholder={userData ? userData.username : "First Name"} />
														<span class="editicon"></span>
													</div>
													<div class="form-one-blocks3">
														<input   type="text" class="input"  name="LAST_NAME" value={userLastName} onChange={(e) => setLastName(e.target.value)}  placeholder={userData ? userData.userLastName : "Last Name"}/>
														<span class="editicon"></span>
													</div>
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">email</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<input type="text" class="input" name="EMAIL" placeholder={user.email}  value={email} onChange={(e) => setEmail(e.target.value)}/>
														<span class="editicon"></span>
													</div>														 
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Password</div>
												<div class="form-one-blocks2">
												<div class="form-one-blocks3">
														<input type="password" class="input jsshowpass3" name="OLD_PASSWORD" placeholder="Old password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}/>
														<span class="editicon"></span>
													</div>
													<div class="form-one-blocks3">
														<input type="password" class="input jsshowpass3" name="NEW_PASSWORD" placeholder="New password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
														<span class="editicon"></span>
													</div>
													<div class="form-one-blocks3">
														<input class="input jsshowpass3"  type="password"  name="NEW_PASSWORD_CONFIRM"  autoComplete="off" placeholder="Confirm new password" value={password} onChange={(e) => setPassword(e.target.value)}/>
														<span class="editicon"></span>
													</div>												 
												</div>
											</div>
											
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Contact no.</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<input type="text" class="input" name="UF_CONTNO" placeholder={userData ? userData.contact : "Contact No."} value={contact} onChange={(e) => setContact(e.target.value)} />
														<span class="editicon"></span>
													</div>											 
												</div>
											</div>
											
											<div class="form-one-blocks">
												<div class="form-one-blocks1">language</div>
												<div class="form-one-blocks2">
													<div class="form-is-select">
														<select value={language} onChange={(e) => setLanguage(e.target.value)}>
															<option  value="1">English</option>
															<option  value="2">Russian</option>
														</select>
													</div>												
												</div>
											</div>
											
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Birthday</div>
												<div class="form-one-blocks2">
													<div class="form-is-select">
														<select value={monthBirthday} onChange={(e) => setMonthBirthday(e.target.value)}>
															<option  value="1">January</option>
															<option  value="2">February</option>
															<option  value="3">March</option>
															<option  value="4">April</option>
															<option  value="5">May</option>
															<option  value="6">June</option>
															<option  value="7">July</option>
															<option  value="8">August</option>
															<option  value="9">September</option>
															<option  value="10">October</option>
															<option  value="11">November</option>
															<option  value="12">December</option>
														</select>
													</div>
													
													<div class="form-is-select">
														<select id="day" name="day" value={day} onChange={(e) => handleDayChange(e.target.value)} >
															{generateDays()}
														</select>
													</div>
													
													<div class="form-is-select">
														<select id="yearSelect" value={yearBirthday} onChange={(e) => setYearBirthday(e.target.value)}>
															{years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
															))}
														</select>
													</div>											 
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Gender</div>
												<div class="form-one-blocks2">
													<div class="form-is-select">
														<select name="gender" id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
															
															<option value="male">Male</option>
															<option value="female">Female</option>
															<option value="other">Other</option>
														</select>
													</div>												
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Nationality</div>
												<div class="form-one-blocks2">
													<div class="form-is-select">
														<select id="nationality" value={selectedCountry} onChange={handleChange}>
															{countries.map((country) => (
															<option key={country.value} value={country.label}>
																{country.label}
															</option>
															))}
														</select>
													</div>												
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Education level</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<input type="text" class="input"  placeholder={userData ? userData.educationLevel : "Level"} value={educationLevel} onChange={(e) => setEducationLevel(e.target.value)} />
														<span class="editicon"></span>
													</div>											 
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Other educational experience</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<input type="text" class="input" placeholder={userData ? userData.educationLevel : "Level"} value={otherEducational} onChange={(e) => setOtherEducational(e.target.value)}/>
														<span class="editicon"></span>
													</div>											 
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Year of work Experience</div>
												<div class="form-one-blocks2">
													<div class="form-is-select">
														<select id="yearOfWorkSelect" value={yearOfWork} onChange={(e) => setYearOfWork(e.target.value)}>
															{years.map((year, index) => (
															<option key={index} value={year}>
																{year}
															</option>
															))}
														</select>
													</div>	
													<div class="form-is-select">
														<select value={monthOfWork} onChange={(e) => setMonthOfWork(e.target.value)}>
															<option  value="1">January</option>
															<option  value="2">February</option>
															<option  value="3">March</option>
															<option  value="4">April</option>
															<option  value="5">May</option>
															<option  value="6">June</option>
															<option  value="7">July</option>
															<option  value="8">August</option>
															<option  value="9">September</option>
															<option  value="10">October</option>
															<option  value="11">November</option>
															<option  value="12">December</option>
														</select>
													</div>										 
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">Work Experience</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<input type="text" class="input"  placeholder={userData ? userData.workExperience : "Experience"} value={workExperience} onChange={(e) => setWorkExperience(e.target.value)} />
														<span class="editicon"></span>
													</div>											 
												</div>
											</div>
											<div class="form-one-blocks">
												<div class="form-one-blocks1">HSE Bio</div>
												<div class="form-one-blocks2">
													<div class="form-one-blocks3">
														<textarea aria-rowcount={2} class="input"  placeholder={userData ? userData.hseBio : "Text here"} value={hseBio} onChange={(e) => setHSEBio(e.target.value)}/>
														<span class="editicon"></span>
													</div>											 
												</div>
											</div>
											{error ? <div style={{color: "red"}}>{error}</div> : <></>}
										</div>
										<div class="footer-form">
											{userData ? 
												<input type="submit" value="save" class="buttonsave" onClick={handleOnUpdate}/>
											: 
												<input type="submit" value="save" class="buttonsave" onClick={handleOnSubmit}/>
											}												
										</div>
									</form>
								</div>
							</div>
						</div>

						<div class="lk-lefttoright-r">
							<ul class="lk-menu">
								<li><Link to="/lk/" class="active">Profile setting</Link></li>
								<li><Link to="/lk/dashboard">Dashboard</Link></li>
								<li><Link to="/lk/notification">notification</Link></li>
							</ul>
						</div>
					</div>
					<div class="overpopsups">
						<div class="overpopsups-icon"></div>
						<div class="overpopsups-data">
							<div class="overpopsups-profile">
								<div class="overpopsups-profile-l"><img src="/img/account_circle_head.svg" /></div>
								<div class="overpopsups-profile-r">{user.name}</div>
							</div>
							<div class="menunospace">
								<Link class="menupops" to="/myrisk">Notifications
									<div class="overpopsups-profile-r">
										{!isLoading 
											?
											<div class="notylk2">
												{renderCurrentNotification()}
												{/* {user.isAdmin 
													? 
													<>
														<span class="iconnoty2"> 
															<span class="iconnoty-count">{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusApprove == 1).length}</span>
														</span>
													</> 
													: 
													<>
														<span class="iconnoty2"> 
															<span class="iconnoty-count">{data.risks.filter(item => item.userid == user.currentUserId).length}</span> 
														</span>
													</>
												} */}
											</div>
											: 
											<div class="notylk2">
												<span class="iconnoty"> 
													<span class="iconnoty-count"></span>
												</span>
												<span class="iconnoty-data">
													<span class="iconnoty-data-item"></span>
												</span>
											</div>
										}
									</div>
								</Link>
							</div>
							<div class="menunospace">
								<Link class="menupops" to="">Home/News Feed</Link>
								<Link class="menupops" to="">Connections</Link>
								<Link class="menupops" to="">Email</Link>
								<Link class="menupops" to="/lk/">Settings</Link>
							</div>
							<Link class="menupops" to="/risk/">Create & Register H&R Assessment</Link>
							<Link class="menupops" to="/myrisk/">Review H&R Assessment</Link>
							<Link class="menupops" to="/dashboard/">Dashboard</Link>
						</div>
					</div>
				</div>
			</main>
			: <Main />
			}
			<div>
				<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openSignUp} onCancel={closeModalSignUp} id="getmylogin"  footer={null}>
					<div class="succespopup aboutforms">
						<div class="succtext1">You have successfully signed up</div>
						<div class="succtext1"><img src="/img/task_alt.svg"/></div>
						<div data-fancybox-close class="succtext3"  type="button" onClick={closeModalSignUp}>ok</div>
					</div>
				</Modal>
			</div>
		</div>
	)
}
export default Lkabinet;