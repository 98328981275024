import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {  Link, useNavigate } from 'react-router-dom'
import { Modal} from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserId, setToken, setisLogin, setisAdmin, setLocationUser, setDateUser, setNameUser, setTimeUser, setTaskUser, registration } from '../slices/userSlise';
import '../App.css';
import { login } from '../slices/userSlise';
import TaskService from '../services/task-service';
const Main = () => {
	const dispatch = useDispatch();
	const storedAuthToken = localStorage.getItem("authToken");
	// Проверяем наличие токена в localStorage при загрузке страницы
	window.addEventListener("DOMContentLoaded", () => {
		if (storedAuthToken) {
			dispatch(setisLogin(true));
		} else {
			dispatch(setisLogin(false));
		}
	});
	// const [error, setError] = useState(null);
	const [open , setOpenModal] = useState(false)
	const [openLogin , setOpenLogin] = useState(false)
	const [openSignUp , setOpenSignUp] = useState(false)
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
    const [password, setPassword] = useState("");
	const [username, setName] = useState("");
    const [location, setLocation] = useState("");
	const [locationDirty, setLocationDirty] = useState(false);
	const [locationError, setLocationError] = useState("Location can't be empty.");
	const [task, setTask] = useState("");
	const [taskDirty, setTaskDirty] = useState(false);
	const [taskError, setTaskError] = useState("Task Assessment can't be empty.")
	const [suggestions, setSuggestions] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const currentDate = new Date();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state);
	const error = useSelector(({risk}) => risk.error);
	const [rememberMe, setRememberMe] = useState(false);
	const day = String(currentDate.getDate()).padStart(2, '0');
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const year = String(currentDate.getFullYear());
	const hours = String(currentDate.getHours()).padStart(2, '0');
	const minutes = String(currentDate.getMinutes()).padStart(2, '0');
	const seconds = String(currentDate.getSeconds()).padStart(2, '0');
	const formattedTime = `${hours}:${minutes}`;
	const formattedDate = `${year}-${month}-${day}`;
	const [date, setDate] = useState(formattedDate);
	const [time, setTime] = useState(formattedTime);
	const [secondName, setSecondName] = useState(user.name);
	
	async function getCityByCoordinates(latitude, longitude) {
		const apiKey = "b5b1b009c0a3433092f5a76adfc59543"; // Ваш API-ключ OpenCage Geocoder
	  
		const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
	  
		try {
		  const response = await axios.get(url);
		  const city = response.data.results[0].components.county;
		  setLocation(city)
		  return city;
		} catch (error) {
		  console.error('Ошибка при получении города:', error);
		  return null;
		}
	}
	  
	// Получение текущих координат
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;
				// Получение города по координатам
				const city = await getCityByCoordinates(latitude, longitude);
				setLocation(city)
			},
			(error) => {
				console.error('Ошибка при получении геолокации:', error);
			}
		);
	} else {
		console.error('Геолокация не поддерживается в данном браузере.');
	}
	useEffect(() => {
		if(storedAuthToken && user.isLogin){
			async function getTasks(){
				try {
					const tasks = await TaskService.getAllTasks();
					setSuggestions(tasks)
				} catch (error) {
					console.log(error)
				}
			}
			getTasks()
		}
	}, [storedAuthToken, user])


	const checkIfTaskExists = (array, task) => {
		array.some((item) => {
			if(item.task === task){
				console.log(item.task, '>>>>', task)
			}
		})
		return array.some((item) => item.task === task);
	}

	const handleInputChange = (event) => {
		const value = event.target.value;
		setTask(value);
	
		const filteredSuggestions = suggestions.filter((suggestion) =>
		suggestion
		);
		setSuggestions(filteredSuggestions);
		setIsDropdownOpen(false);
	};

	const handleInputFocus = () => {
		// При фокусе на поле ввода открываем список
		setIsDropdownOpen(true);
	};
	const handleSuggestionClick = (suggestion) => {
		setTask(suggestion);
		//setSuggestions([]);
		setIsDropdownOpen(false);
	};
	
  	const checkHandler = () => {
		setRememberMe(!rememberMe)
	} 

	const handleOnSubmitLogin = async (e) => {
        e.preventDefault();
		if(rememberMe){
			try {
				await dispatch(login({username, password, rememberMe})).unwrap();
				navigate('/lk');
			} catch (error) {
				console.log(error)
			}

		}else{
			try {
				await dispatch(login({username, password, rememberMe})).unwrap();
				navigate('/lk');	
			} catch (error) {
				console.log(error)
			}
		}

    }
    const handleOnSubmitSignUp = async (e) => {
        e.preventDefault();
		dispatch(registration({username, password}))
		navigate('/lk');

    }

	const handleOnSubmitTask = async (e) => {
        // e.preventDefault();
		if(!checkIfTaskExists(suggestions, task)) {
			console.log('not exist')
			try {
				await TaskService.createTask(task);
				dispatch(setTaskUser(task));
				navigate('/risktable');
			} catch (error) {
				console.log(error)
			}
			
			// let result = await fetch(
			// `http://${host}:3001/task`, {
			// 	method: "post",
			// 	body: JSON.stringify({ task }),
			// 	headers: {
			// 		'Content-Type': 'application/json'
			// 	}
			// })
			// result = await result.json();
			// console.log("Task result", result)
			// if (result) {
			// 	dispatch(setNameUser(secondName));
			//     dispatch(setDateUser(date));
			// 	dispatch(setTimeUser(time));
			//     dispatch(setLocationUser(location));
			//     dispatch(setTaskUser(task));
			// 	navigate('/risktable');
			// }
		} else {
			dispatch(setTaskUser(task));
			navigate('/risktable');
			console.log('exist')
			// dispatch(setNameUser(secondName));
			// dispatch(setDateUser(date));
			// dispatch(setTimeUser(time));
			// dispatch(setLocationUser(location));
			// dispatch(setTaskUser(task));
			// navigate('/risktable');
		}
    }

	const closeModalLogin = () => {
		setOpenLogin(false)
	}
	const closeModalSignUp = () => {
		setOpenSignUp(false)
	}
	const closeModal = () => {
		setOpenModal(false)
	}
	const handleClick = () => {
		setOpenModal(true);
	};

	const click = async (e) => {
		e.preventDefault();
		if(!location){
			let elem = document.getElementById('location');
			elem.classList.add('notValidInput');
			elem.placeholder = "";
			setLocationDirty(true);
		}
		if(!task){
			let elem = document.getElementById('task');
			console.log(elem)
			elem.classList.add('notValidInput');
			elem.placeholder = "";
			setTaskDirty(true);
		}
		if(location && task){
			console.log('yes');
			handleOnSubmitTask();
			// try{
			// 	await handleOnSubmitTask()
			// }catch(e){
			// 	alert(e);
			// }
		}
		return;
	}


	return (
	<div>
	<main>
		<div class="container">
		<div class="thohalfs">
			<div class="thohalfs-l">
				<div class="aboutforms">
					<div class="formstitle">Register Hazard & Risk Assesment </div>
					<form class="preform1" method="post" action="">
						<input type="text" placeholder="Name" name="Name" value={(!secondName) ? user.email : secondName} onChange={(e) => setSecondName(e.target.value)}/>
						<input type="date"  placeholder="Date" name="Date" defaultValue={date} onChange={(e) => setDate(e.target.value)}/>
						<input type="time"  placeholder="Time" name="Time" value={time} onChange={(e) => setTime(e.target.value)}/>
						{(locationDirty && locationError) && <label id='location' style={{color:'red', position: 'relative', top: '15px'}}>{locationError}</label> }
						<input type="text"  placeholder="Location" id='location' name="Location" value={location} onChange={(e) => setLocation(e.target.value)}/>
						{(taskDirty && taskError) && <label id='task' style={{color:'red', position: 'relative', top: '15px'}}>{taskError}</label> }
						<input type="text" placeholder="Task Assessment" id='task' name="Task" value={task} onChange={handleInputChange} onFocus={handleInputFocus}/>
						{isDropdownOpen && ( 
							<ul>
							{suggestions.map((index) => (
								<li key={index.id} onClick={() => handleSuggestionClick(index.task)}>
								{index.task}
								</li>
							))}
							</ul>
						)}
						{storedAuthToken ? 
						<input type="submit" placeholder="" value="SUBMIT" onClick={click}/>
						: 
						<input type="button" placeholder="" value="SUBMIT" onClick={() => setOpenSignUp(true)}/>
						}
					</form>
				</div>
			</div>
			<div class="thohalfs-r">
				<div class="about-mains">
					<div class="about-mains-t1"><span>SIMS</span> SOLUTIONS</div>
					<div class="about-mains-t2">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br/> 
					sed do eiusmod tempor incididunt ut labore et dolore <br/> 
					magna aliqua. <br/> 
					<br/>
					Ut enim ad minim veniam, quis nostrud<br/> 
					exercitation ullamco laboris nisi ut aliquip ex ea commo<br/> 
					do consequat. <br/> 
					<br/>
					Lorem ipsum dolor sit amet, consectetur <br/> 
					adipiscing elit, sed do eiusmod tempor incididunt ut <br/> 
					labore et dolore magna aliqua. 
					</div>
					<div class="about-mains-t3">
						{storedAuthToken ? <></> :
						<div class="about-mains-butts">
							<a class="jsgetlogin" type="button" onClick={() => setOpenLogin(true)}>Log in</a>
							<a class="jsgetlogin" type="button" onClick={() => setOpenSignUp(true)}>Sigh up</a>
						</div>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
	</main>
	<div>
		<Modal style={{padding: 0}} open={openLogin} onCancel={closeModalLogin}  id="getmylogin" class="fancybox-content" footer={null}>
		<div class="pop-container aboutforms">
			<div class="pop-container-tabs">
				<div type="button" onClick={() => {setOpenLogin(true); setOpenSignUp(false)}} className="spre1 pop-container-tabsd active" >log in</div>
				<div type="button" onClick={() => {setOpenSignUp(true); setOpenLogin(false)}} className="spre2 pop-container-tabsd" >Sign up</div>
				
			</div>

			<div class="pop-container-tabs_bot">
				<div class="tospre1 pop-container-tabsd_bot active">
					<form class="authajax" name="system_auth_form" target="_top" action="">

						<input type="text" placeholder="User name/email address" value={username} onChange={(e) => setName(e.target.value)} required/>
						<input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
						{error ? <div style={{color: "red"}}>{error}</div> : <></>}
						<div class="blockremembr">
							<div class="blockremembr-l">
								<input type="checkbox" checked={rememberMe} onChange={checkHandler} id="USER_REMEMBER_frm" name="USER_REMEMBER" value="Y" />
								<label for="USER_REMEMBER_frm" >Remember me?</label>
							</div>
							<div class="blockremembr-r"><Link to="/forgot/">Forgot password?</Link></div>

						</div>

						<input name="Login" type="submit" onClick={(e) => handleOnSubmitLogin(e)}/>

					</form>
				</div>
			</div>
		</div>
		</Modal>
		<Modal style={{padding: 0}} open={openSignUp} onCancel={closeModalSignUp}  id="getmylogin" class="fancybox-content" footer={null}>
		<div class="pop-container aboutforms">
			<div class="pop-container-tabs">
				<div type="button" onClick={() => {setOpenLogin(true); setOpenSignUp(false)}} class="spre1 pop-container-tabsd">log in</div>
				<div type="button" onClick={() => {setOpenSignUp(true); setOpenLogin(false)}} class="spre2 pop-container-tabsd active">Sign up</div>
			</div>

			<div class="pop-container-tabs_bot">
				<div class="tospre1 pop-container-tabsd_bot active">
					<form class="authajax" name="system_auth_form" method="post" action="">
						<input type="text" placeholder="User name/email address" name="USER_LOGIN" value={username} onChange={(e) => setName(e.target.value)} required/>
						<input type="password" placeholder="Password" name="USER_PASSWORD" value={password} onChange={(e) => setPassword(e.target.value)} required/>
						<input type="password" placeholder="Confirm password" name="pass2" required/>
						<input name="SignUp" type="submit" onClick={handleOnSubmitSignUp} placeholder="" value="continue"/>
					</form>
				</div>
			</div>
		</div>
		</Modal>
		<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={open} onCancel={closeModal} id="getmylogin"  footer={null}>
				<div class="succespopup aboutforms">
					<div class="succtext1">You have to sign up</div>
					<div class="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close class="succtext3"  type="button" onClick={closeModal}>ok</div>
				</div>
			</Modal>
	</div>
</div>
	  )
}
export default Main;