import $api from '../http';

const getOneProfile = (id) => {
    console.log(id)
    return $api.get(`/profile/get${id}`)
    .then((response) => {
        // console.log(response.data)
        return response.data;
    });
}

const createProfileSettings = 
    ( 
        userId,
        username,
        userLastName, 
        oldPassword, 
        newPassword, 
        password, 
        email, 
        contact, 
        language, 
        day, 
        monthBirthday, 
        yearBirthday, 
        gender, 
        selectedCountry, 
        educationLevel, 
        otherEducational,
        monthOfWork,
        yearOfWork,
        workExperience,
        hseBio
    ) => {
        console.log(        userId,
            username,
            userLastName, 
            oldPassword, 
            newPassword, 
            password, 
            email, 
            contact, 
            language, 
            day, 
            monthBirthday, 
            yearBirthday, 
            gender, 
            selectedCountry, 
            educationLevel, 
            otherEducational,
            monthOfWork,
            yearOfWork,
            workExperience,
            hseBio)
    return $api.post('/profile/create', {
        userId,
        username,
        userLastName, 
        oldPassword, 
        newPassword, 
        password, 
        email, 
        contact, 
        language, 
        day, 
        monthBirthday, 
        yearBirthday, 
        gender, 
        selectedCountry, 
        educationLevel, 
        otherEducational,
        monthOfWork,
        yearOfWork,
        workExperience,
        hseBio
    })
    .then((response) => {
        return response.data;
    });
}

const updateProfileSettings = 
    ( 
        userId,
        username,
        userLastName, 
        oldPassword, 
        newPassword, 
        password, 
        email, 
        contact, 
        language, 
        day, 
        monthBirthday, 
        yearBirthday, 
        gender, 
        selectedCountry, 
        educationLevel, 
        otherEducational,
        monthOfWork,
        yearOfWork,
        workExperience,
        hseBio
    ) => {
    return $api.post('/profile/update', {
        userId,
        username,
        userLastName, 
        oldPassword, 
        newPassword, 
        password, 
        email, 
        contact, 
        language, 
        day, 
        monthBirthday, 
        yearBirthday, 
        gender, 
        selectedCountry, 
        educationLevel, 
        otherEducational,
        monthOfWork,
        yearOfWork,
        workExperience,
        hseBio
    })
    .then((response) => {
        if(response.data.token) {
            localStorage.setItem('authToken', response.data.token);
        }
        return response.data;
    });
}

const ProfileSettingsService = {
    getOneProfile,
    createProfileSettings,
    updateProfileSettings,
}


export default ProfileSettingsService;