import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Main from './Main';
import '../App.css';
const LkDashboard = () => {
	const storedAuthToken = localStorage.getItem("authToken");
	const { user } = useSelector((state) => state);
	const navigate = useNavigate();
	return (
		<div>
		{storedAuthToken ? 
		<main>
			<div class="container">
				<div class="lk-lefttoright">
					<div class="lk-lefttoright-l">
						<div class="maxforms">
							<div class="lkblockedits">
								<form class="setting-table" method="post" name="form1" action="" enctype="multipart/form-data">
									<div class="heads-form">
										<div class="heads-form1">edit: variables</div>
									</div>
									<div class="body-form">
										<div class="form-one-blocks splitos">
											<div class="form-one-blocks1">General</div>
											<div class="form-one-blocks2 outoversmy4">

												<span class="oversmy4">
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
													</div>
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
													</div>
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
													</div>
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
													</div>
												</span>
																						
											</div>
										</div>

										<div class="form-one-blocks splitos">
											<div class="form-one-blocks1">custom options</div>
											<div class="form-one-blocks2 thoinline">
												<div class="form-one-blocks3">
													<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
												</div>
												<div class="form-one-blocks3">
													<input   type="text" class="input"  name="LAST_NAME" value="" placeholder="Last Name" />
												</div>												
											</div>
										</div>

										<div class="form-one-blocks splitos">
											<div class="form-one-blocks1">selection options</div>
											<div class="form-one-blocks2 ">
												<span class="oversmy">
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
													</div>

													<div class="form-one-blocks3">
														<label class="labelraios">
															<input type="hidden" value="0" name="UF_NOTYEMAIL"/>
															<input type="checkbox"  name="UF_NOTYEMAIL" value="1"  />
															<span></span>
														</label>
													</div>
												</span>

												<span class="oversmy">
													<div class="form-one-blocks3">
														<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
													</div>

													<div class="form-one-blocks3">
														<label class="labelraios">
															<input type="hidden" value="0" name="UF_NOTYEMAIL"/>
															<input type="checkbox"  name="UF_NOTYEMAIL" value="1"  />
															<span></span>
														</label>
													</div>
												</span>												
											</div>
										</div>

										<div class="form-one-blocks splitos">
											<div class="form-one-blocks1">preview of values</div>
											<div class="form-one-blocks2 thoinlinesm">
												<div class="form-one-blocks3">
													<input type="text" class="input" name="NAME" value=""   placeholder="First Name" />
												</div>

												<div class="form-one-blocks3">
													<input   type="text" class="input"  name="LAST_NAME" value="" placeholder="Last Name" />
												</div>												
											</div>
										</div>
									</div>
									<div class="footer-form">
										<input type="submit" name="save" value="update" class="buttonsave"/>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="lk-lefttoright-r">
						<ul class="lk-menu">
							<li><Link to="/lk/" >Profile setting</Link></li>
							<li><Link to="/lk/dashboard" class="active">Dashboard</Link></li>
							<li><Link to="/lk/notification">notification</Link></li>
						</ul>
					</div>
				</div>
				<div class="overpopsups">
					<div class="overpopsups-icon"></div>
					<div class="overpopsups-data">
						<div class="overpopsups-profile">
							<div class="overpopsups-profile-l"><img src="img/account_circle_head.svg" /></div>
							<div class="overpopsups-profile-r"></div>
						</div>
						<div class="menunospace">
							<Link class="menupops" to="/">Home/News Feed</Link>
							<Link class="menupops" to="">Connections</Link>
							<Link class="menupops" to="">Email</Link>
							<Link class="menupops" to="/lk/">Settings</Link>
						</div>
						<Link class="menupops" to="/risk/">Create & Register H&R Assessment</Link>
						<Link class="menupops" to="/myrisk/">Review H&R Assessment</Link>
						<Link class="menupops" to="/dashboard/">Dashboard</Link>
					</div>
				</div>
			</div>
		</main>
		: <Main />
	}
	</div>
		  )
}
export default LkDashboard;