import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setNewPhysically, setPhysically } from "../../slices/riskSlice";


const TableCellPhysically = ({item, addNew}) => {

    const [physically2, setPhysically2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
	const [checkedIdphysically, setCheckedIdphysically] = useState("");
    // const [addPhysically, setAddPhysically] = useState(false);
    // const [addPhysically2, setAddPhysically2] = useState(false);
    const [addPhysically, setAddPhysically] = useState([]);
    const [addPhysically2, setAddPhysically2] = useState([]);
    const [physically, setStatePhysically] = useState([]);
    const dispatch = useDispatch();

    return (
        <>	
            {/* {(!item && addNew) &&
                <>
                    {addPhysically ? 
                    <input type="text" className="autofilssInput" value={physically} onChange={(e) => dispatch(setNewPhysically(e.target.value)) && setStatePhysically(e.target.value)}></input>
                    :
                        <button onClick={(e) => {e.preventDefault(); setAddPhysically(true)}}>+ Add</button>
                    }
                </>
            }	  */}
            {(item) &&
                <div class="hovers" style={item.physically[0] === "" ? {overflow: "hidden"} : {}}>
        
                    <ul style={item.physically[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.physically.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addPhysically.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            console.log(e.target.value)
                                                            setPhysically2(
                                                                (physically2.some((el, ind) => ind == indx)) 
                                                                ? physically2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...physically2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setPhysically(
                                                                    [   
                                                                        item.id, 
                                                                        (physically2.some((el, ind) => ind == indx)) 
                                                                        ? [...physically2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...physically2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddPhysically([...addPhysically, true]); setCheckedIdphysically(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addPhysically2 && checkedIdphysically == item.id 
                                    //     ? 
                                    //     <input type="text" class="autofilssInput" value={checkedIdphysically == item.id ? physically2 : ""} onChange={(e) => {dispatch(setPhysically([item.id, [e.target.value]])) && setPhysically2(e.target.value); setCheckedItem(item); setCheckedIdphysically(item.id)}}></input>
                                    //     :
                                    //     <button onClick={(e) => {e.preventDefault(); setAddPhysically2(true); setCheckedIdphysically(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(physically.includes(index) && checkedIdphysically == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault();
                                                                    physically.some(item => item == index)
                                                                    ? dispatch(setPhysically([item.id, (physically2.length != 0) ? [...physically.filter(i => i != index), ...physically2] : physically.filter(i => i != index)])) && setStatePhysically(physically.filter(i => i != index))
                                                                    : dispatch(setPhysically([item.id, (physically2.length != 0) ? [...[...physically, index], ...physically2] : [...physically, index]])) && setStatePhysically([...physically, index]);
                                                                    setCheckedIdphysically(item.id);
                                                                }
                                            }></span>
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addPhysically2 && checkedIdphysically == item.id 
                            && 
                            addPhysically2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setPhysically2(
                                            (physically2.some((el, ind) => ind == index)) 
                                            ? physically2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...physically2, e.target.value]
                                        ); 
                                        dispatch(
                                            setPhysically([item.id,
                                                (physically2.some((el, ind) => ind == index)) 
                                                ? [...physically, ...[...physically2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...physically, ...[...physically2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.physically[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddPhysically2([...physically2, true]); setCheckedIdphysically(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addPhysically2 && checkedIdphysically == item.id 
                            ? 
                            <input type="text" class="autofilssInput" value={physically2} onChange={(e) => {dispatch(setPhysically([item.id, [...physically, e.target.value]])) && setPhysically2([e.target.value]); setCheckedItem(item); setCheckedIdphysically(item.id)}}></input>
                            :
                            <button onClick={(e) => {e.preventDefault(); setAddPhysically2(true); setCheckedIdphysically(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellPhysically;