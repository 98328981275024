import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setAsset, setNewAsset } from "../../slices/riskSlice";

const TableCellAssetNew = ({item, addNew}) => {

    const dispatch = useDispatch();
    const [asset2, setStateAsset2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdasset, setCheckedIdasset] = useState("");

	const [addAsset2, setAddAsset2] = useState(false);
    const [asset, setStateAsset] = useState([]);
    const [assetArray, setStateAssetArray] = useState([]);
    
    const [assetArrayUl, setStateAssetArrayUl] = useState([]);

    return (
        <>
            {assetArray.map((element, index) => 
                <>
                    {element
                        &&
                        <div className={`hovers ${index}`}>
                            <ul style={{padding: '0', margin: '0'}}>
                                {
                                    // console.log(assetArrayUl)
                                    assetArrayUl.map((item, indx) =>
                                        (indx == index) && item.map((i, ind) =>
                                        i &&
                                        <li class="autofilss">
                                            <input type="text" className="autofilssInput" onChange={(e) => dispatch(setNewAsset([index.toString(), ind, e.target.value ]))}></input>
                                        </li>
                                    ))
                                }
                                <div className="autofilss">
                                    <button onClick={(e) => {e.preventDefault(); setStateAssetArrayUl(assetArrayUl.map((item, ind) => (ind == index) ? item = [...item, true] : item));}}>+ Add</button>
                                </div>  
                            </ul>
                        </div>   
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStateAssetArray([...assetArray, true]); setStateAssetArrayUl([...assetArrayUl, [true]])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellAssetNew;