import $api from '../http';

export default class RiskService {

    static async getAllRisks() {
        return $api.get('/risk/risktable')
        .then((response) => {
            return response.data.response;
        });
    }

    static async login(email, password) {
        return $api.post('/login', {email, password});
    }

    static async logout() {
        return $api.post('/logout');
    }

    static async auth(token) {
        return $api.post('/auth');
    }

}