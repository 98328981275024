import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setThreat, setNewThreat } from "../../slices/riskSlice";

const TableCellThreat = ({item, threatSave}) => {

    const dispatch = useDispatch();
    // const [threat2, setStateThreat2] = useState("");
    const [threat2, setStateThreat2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdthreat, setCheckedIdthreat] = useState((typeof threatSave == 'object') ? Object.keys(threatSave).toString() : "");
	const [addThreat, setAddThreat] = useState([]);
	// const [addThreat2, setAddThreat2] = useState(false);
	// const [addThreat2, setAddThreat2] = useState((typeof threatSave == 'object') ? Object.values(threatSave).flat().filter((elem, index) => elem != item.threat[index]) : []);
    // const [threat, setStateThreat] = useState((typeof threatSave == 'object') ? Object.values(threatSave).flat().filter((elem, index) => elem == item.threat[index]) : []);
    const [addThreat2, setAddThreat2] = useState((typeof threatSave == 'object') ? Object.values(threatSave).flat().filter((elem, index) => !item.threat.includes(elem)) : []);
    const [threat, setStateThreat] = useState((typeof threatSave == 'object') ? Object.values(threatSave).flat().filter((elem, index) => item.threat.includes(elem)) : []);

    // if(typeof threatSave == 'object'){
    //     console.log(threatSave)
    //     const a = Object.values(threatSave).flat().filter((elem, index) => elem != item.threat[index])
    //     console.log('a >>>>', a)
    //     console.log(Object.values(threatSave))
    //     console.log(threatSave)
    //     console.log(item.threat)

    // }

    // console.log('checkedIdthreat >>>', checkedIdthreat)
    // console.log('threat >>>',threat)
    // console.log('threat2 >>>',threat2)
    // console.log('addthreat >>>',addThreat)
    // console.log('addthreat2 >>>',addThreat2)

    return (
        <>	
            {(item) &&
                <div class="hovers" style={item.threat[0] === "" ? {overflow: "hidden"} : {}}>
    
                    <ul style={item.threat[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.threat.map((index) => (
                            <>	
                                {index == "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addThreat.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            setStateThreat2(
                                                                (threat2.some((el, ind) => ind == indx)) 
                                                                ? threat2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...threat2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setThreat(
                                                                    [   
                                                                        item.id, 
                                                                        (threat2.some((el, ind) => ind == indx)) 
                                                                        ? [...threat2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...threat2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddThreat([...addThreat, true]); setCheckedIdthreat(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addThreat2 && checkedIdthreat == item.id 
                                    //     ? 
                                    //     <input type="text" class="autofilssInput" value={checkedIdthreat == item.id ? threat2 : ""} onChange={(e) => {setStateThreat2(e.target.value); setCheckedItem(item); setCheckedIdthreat(item.id)}}></input>
                                    //     :
                                    //     <button onClick={(e) => {e.preventDefault(); setAddThreat2(true); setCheckedIdthreat(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss main" key={index.id} >
                                            <input type="checkbox" checked={(threat.includes(index) && checkedIdthreat == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault();
                                                                    console.log(item, index, threat);
                                                                    threat.some(item => item == index)
                                                                    ? dispatch(setThreat([item.id, (addThreat2.length != 0) ? [...threat.filter(i => i != index), ...addThreat2] : threat.filter(i => i != index)])) && setStateThreat(threat.filter(i => i != index))
                                                                    : dispatch(setThreat([item.id, (addThreat2.length != 0) ? [...[...threat, index], ...addThreat2] : [...threat, index]])) && setStateThreat([...threat, index]);
                                                                    setCheckedIdthreat(item.id);
                                                                }
                                            }></span>
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                            {/* Индекс существует -> добавляем новый li в ul (saved) && отображение сохраненных li */}
                            {addThreat2 && checkedIdthreat == item.id 
                            && 
                            addThreat2.map((elem, index) => 
                                elem &&
                                <li class="autofilss newInUl">
                                    <input type="text" defaultValue={(typeof elem == 'string') ? elem : ''} class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setAddThreat2(
                                            (addThreat2.some((el, ind) => ind == index)) 
                                            ? addThreat2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...addThreat2, e.target.value]
                                        ); 
                                        dispatch(
                                            setThreat([item.id,
                                                (addThreat2.some((el, ind) => ind == index)) 
                                                ? [...threat, ...[...addThreat2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...threat, ...[...addThreat2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                            }
                        {item.threat[0] != "" &&
                            <div class="autofilss newInUl">
                                <button onClick={(e) => {e.preventDefault(); setAddThreat2([...addThreat2, true]); setCheckedIdthreat(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addThreat2 && checkedIdthreat == item.id 
                            ? 
                            <input type="text" class="autofilssInput" value={threat2} onChange={(e) => {dispatch(setThreat([item.id, [...threat, e.target.value]])) && setStateThreat2([e.target.value]); setCheckedItem(item); setCheckedIdthreat(item.id)}}></input>
                            :
                            <button onClick={(e) => {e.preventDefault(); setAddThreat2(true); setCheckedIdthreat(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }	 
        </>
    )
};

export default TableCellThreat;