import $api from '../http';

const registration = (email, password) => {
    return $api.post('/user/registration', {email, password})
    .then((response) => {
        if(response.data.token) {
            localStorage.setItem('authToken', response.data.token);
        }
        return response.data;
    });
}

const login = (email, password, remember) => {
    return $api.post('/user/login', {email, password, remember})
    .then((response) => {
        if(response.data.token) {
            localStorage.setItem('authToken', response.data.token);
        }
        return response.data;
    });
}

const logout = () => {
    localStorage.clear();
    return true;
}

const auth = (authToken, username) => {
    return $api.post('/user/auth', {authToken, username})
    .catch((error) => {
        localStorage.clear();
    })
    .then((response) => {
        if (response.data.token) {
            localStorage.setItem('authToken', response.data.token);
        }
        return response.data;
    });
}

const getAllUsersCount = () => {
    return $api.get('/user/allusers')
    .then((response) => {
        return response.data.user;
    });
}

const recovery = (email, password) => {
    return $api.put('/user/recovery', {email, password})
    // .then((response) => {
    //     if(response.data.token) {
    //         localStorage.setItem('authToken', response.data.token);
    //     }
    //     return response.data;
    // });
}

const AuthService = {
    registration,
    login,
    logout,
    auth,
    recovery,
    getAllUsersCount,
}


export default AuthService;