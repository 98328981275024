import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Main from './Main';
import axios from 'axios';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import { Modal, Spin, Button, Popover} from "antd";
import '../App.css';
import RiskService from '../services/risk-services';
import AuthService from '../services/auth-service';
const Dashboard = () => {
	const storedAuthToken = localStorage.getItem("authToken");
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
	const fakedata = [{name: 'Normal', value: 19}, {name: 'Height', value: 12}];
	const [activities , setActivities ] = useState(null);
	const [hazards, setHazards] = useState(null);
	const [risk, setRisk] = useState(null);
	const [data1, setData1] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);
	const [dataUsers, setDataUsers] = useState(null);
	const { user } = useSelector((state) => state);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
  	const [error, setError] = useState(null);

	const currentDate = new Date();
	const day = String(currentDate.getDate()).padStart(2, '0');
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const year = String(currentDate.getFullYear());
	const formattedDate = `${year}-${month}-${day}`;
	const [date, setDate] = useState(formattedDate);

    const COLORS = ["rgb(253, 239, 83)", "#DB0000" ];
	const COLORS2 = ["rgb(253, 239, 83)", "#DB0000", "rgb(160, 24, 249)" ];
	const COLORS3 = ["rgb(253, 239, 83)", "#DB0000", "rgb(22, 97, 250)", "rgb(23, 173, 4)" ];
	const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };
	useEffect(() => {
		async function fetchData() {
		  try {
			const response = await RiskService.getAllRisks()
			// const response = await axios.get(`http://${host}:3001/risktable/`);
			console.log(response)
			const data = response
			const threat = data.reduce((accumulator, currentValue) => currentValue.threat != "" ? accumulator + 1 : accumulator,
				0,
			  );
			const hazardsList = data.reduce((accumulator, currentValue) => currentValue.hazardsList != "" ? accumulator + 1 : accumulator,
				0,
			  );
			const physically = data.reduce((accumulator, currentValue) => currentValue.physically != "" ? accumulator + 1 : accumulator,
			  0,
			);
			const mentally = data.reduce((accumulator, currentValue) => currentValue.mentally != "" ? accumulator + 1 : accumulator,
				0,
			  );
			const emotionally = data.reduce((accumulator, currentValue) => currentValue.emotionally != "" ? accumulator + 1 : accumulator,
			  0,
			);
			const asset = data.reduce((accumulator, currentValue) => currentValue.asset != "" ? accumulator + 1 : accumulator,
				0,
			  );
			const people = data.reduce((accumulator, currentValue) => currentValue.people != "" ? accumulator + 1 : accumulator,
			  0,
			);
			const environment = data.reduce((accumulator, currentValue) => currentValue.environment != "" ? accumulator + 1 : accumulator,
				0,
			  );
			const reputation = data.reduce((accumulator, currentValue) => currentValue.reputation != "" ? accumulator + 1 : accumulator,
			  0,
			);
			const activities = data.reduce((accumulator, currentValue) => currentValue.approve || currentValue.decline || (!currentValue.approve && !currentValue.decline) ? accumulator + 1 : accumulator,
			  0,
			);
			console.log("DATA", hazardsList, threat)
			setHazards(hazardsList);
			setRisk(threat);
			setActivities(activities);
			setData1([{name: 'Hazard', value: hazardsList}, {name: 'Threat', value: threat}]);
			setData2([{name: 'Emotionally', value: emotionally}, {name: 'Physically', value: physically}, {name: 'Mentally', value: mentally}]);
			setData3([{name: 'Asset', value: asset}, {name: 'People', value: people}, {name: 'Reputation', value: reputation}, {name: 'Environment', value: environment}]);
		  } catch (err) {
			setError(err);
		  } finally {
			setIsLoading(false);
		  }
		}
		async function fetchDataUsers() {
			try {
			//   const response = await axios.get(`http://${host}:3001/login`);
			const response = await AuthService.getAllUsersCount();
			  const data = response;
			  setDataUsers(data);
			} catch (err) {
			  setError(err);
			} finally {
			  setIsLoading(false);
			}
		  }
		fetchDataUsers();
		fetchData();
		
	  }, []);
	  console.log(data1)
	  console.log(data2)
	  console.log(data3)
	  console.log(hazards)
	  console.log(risk)
	  console.log(activities)
	return (
		<div>
			{storedAuthToken ? 
		<main>
			<>{dataUsers ? 
			<div class="container">
				<div class="lk-lefttoright revers offbgredis">
					<div class="lk-lefttoright-r">
						<input class="hsearchbeb" type="text" placeholder="Search"/>
						<div class="ebititle">Dashboards</div>
						<ul class="lk-menu-tho">
							<li><Link href="/dashboard/" class="active">overview</Link></li>
							<li><Link href="" >navigation</Link></li>
							<li><Link href="" >History</Link></li>
							<li><Link to="" >Edit</Link></li>
						</ul>
					</div>

					<div class="lk-lefttoright-l">
						<div class="maxforms">
							<div class="lkblockedits">
								<div class="listoff-aprouvs">
									<div style={{paddingTop: 0}} class="heads-form istho">
										<div class="heads-form1">
											<div class="aupsfrei">Dashboards</div>
											APPROVALS
										</div>
										<div class="heads-form-right">
											<input style={{color: "#000"}} class="jsdatefind" type="date" defaultValue={date} placeholder="Date"/>
										</div>
									</div>
									<div class="heads-form newhh-des awminik">
										<div class="graflist">
											<div class="graflist-items">
												<div class="graflist-items-cvs">
												<div className="gx-py-3" id="graph1">
													<ResponsiveContainer width={284} height={284}>
													<PieChart>
														<Tooltip/>
														<text
														x="50%" className="h1"
														y="50%" textAnchor="middle" dominantBaseline="middle">
														{`%`}
														</text>
														<Pie
														data={data1} dataKey="value"
														cx="50%"
														cy="50%"
														innerRadius={75}
														outerRadius={90}
														labelLine={false}
                                    					label={renderCustomizedLabel}
														>
														{
														data1.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
														}
														</Pie>
													</PieChart>
													</ResponsiveContainer>
												</div>
												</div>
											<div class="graflist-items-title1">HAZARD & RISK</div>
											<div class="graflist-items-title2"></div>
											</div>

											<div class="graflist-items">
											<div class="graflist-items-cvs">
												<div className="gx-py-3" id="graph2">
													<ResponsiveContainer width={284} height={284}>
													<PieChart>
														<Tooltip/>
														<text
														x="50%" className="h1"
														y="50%" textAnchor="middle" dominantBaseline="middle">
														
														</text>
														<Pie
														data={data2} dataKey="value"
														cx="50%"
														cy="50%"
														innerRadius={75}
														outerRadius={90}
														labelLine={false}
                                    					label={renderCustomizedLabel}
														>
														{
														data2.map((entry, index) => <Cell key={index} fill={COLORS2[index % COLORS2.length]}/>)
														}
														</Pie>
													</PieChart>
													</ResponsiveContainer>
												</div>
											</div>
											<div class="graflist-items-title1">Risk Effect</div>
											<div class="graflist-items-title2"></div>
											</div>

											<div class="graflist-items">
											<div class="graflist-items-cvs">
											<div className="gx-py-3" id="graph2">
													<ResponsiveContainer width={301} height={284}>
													<PieChart>
														<Tooltip/>
														<text
														x="50%" className="h1"
														y="50%" textAnchor="middle" dominantBaseline="middle">
														
														</text>
														<Pie
														data={data3} dataKey="value"
														cx="50%"
														cy="50%"
														innerRadius={75}
														outerRadius={90}
														labelLine={false}
                                    					label={renderCustomizedLabel}
														>
														{
														data3.map((entry, index) => <Cell key={index} fill={COLORS3[index % COLORS3.length]}/>)
														}
														</Pie>
													</PieChart>
													</ResponsiveContainer>
												</div>
											</div>
											<div class="graflist-items-title1">Who is at Risk</div>
											<div class="graflist-items-title2"></div>
											</div>
										</div>
									</div>
									<div class="heads-form newhh-des awminik">
										<div class="tominik">Quick stats</div>
										<div class="listing-fourli">
											<div class="offfours lifo1">
											<div class="offfours-h1">activities</div>
											<div class="offfours-h2">
											<div class="offfours-h21">{activities ? activities + dataUsers : 0}</div>
											<div class="offfours-h22"></div>
											</div>
											</div>

											<div class="offfours lifo2">
												<div class="offfours-h1">hazards</div>
												<div class="offfours-h2">
													<div class="offfours-h21">{hazards ? hazards : 0}</div>
													<div class="offfours-h22"></div>
												</div>
											</div>

											<div class="offfours lifo3">
											<div class="offfours-h1">risk</div>
											<div class="offfours-h2">
											<div class="offfours-h21">{risk ? risk : 0}</div>
											<div class="offfours-h22"></div>
											</div>
											</div>

											<div class="offfours lifo4">
											<div class="offfours-h1">sign ups</div>
											<div class="offfours-h2">
											<div class="offfours-h21">{dataUsers ? dataUsers : 0}</div>
											<div class="offfours-h22"></div>
											</div>
											</div>
										</div>
									</div>
									<div class="heads-form newhh-des awminik">
										<div class="thotoone">
											<div class="thotoonel">
												<div class="tablesone">
													<div class="tables-title">Countries</div>
													<div class="tables">
														<div class="tables-item">
															<div class="tables-item1"></div>
															<div class="tables-item2"></div>
														</div>
														<a class="awwsge" href="#">all countries</a>
													</div>
												</div>
											</div>
											<div class="thotoonel">
												<div class="tablesone">
													<div class="tables-title">most visited pages</div>
													<div class="tables">
														<div class="tables-item">
															<div class="tables-item1">United States</div>
															<div class="tables-item2">62,4%</div>
														</div>
														<a class="awwsge" href="#">view all pages</a>
													</div>
												</div>
											</div>
										</div>

										<div class="tablesone">
											<div class="tables-title">devices and resolutions</div>
											<div class="tables">
												<div class="tables-item">
													<div class="tables-item1"></div>
													<div class="tables-item2"></div>
												</div>
												<a class="awwsge" href="#">View all devices</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			: <Spin style={{margin: "auto"}}></Spin> }
			</>
		</main>
		: <Main />
	}
	</div>
		  )
}
export default Dashboard;