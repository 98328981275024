import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/auth-service';
import jwt_decode from 'jwt-decode';
import ProfileSettingsService from '../services/profileSettings-service';

export const ratingSlice = createSlice({
	name: 'rating',
	initialState: {
        likelihood: [],
        severity: [],
        danger: [],
        resultsColors: [],
        resultsLabels: [],
	},
	reducers: {
        setLikelihood: (state, {payload}) => {
            console.log(payload);
            state.likelihood = payload;
        },
        setSeverity: (state, {payload}) => {
            state.severity = payload;
        },
        setDanger: (state, {payload}) => {
            state.danger = payload;
            // console.log(payload)
            // console.log(state.severity)
            // console.log(state.likelihood)
        },
        setResultsLabels: (state, {payload}) => {
            state.resultsLabels = payload;
        },
        setResultsColors: (state, {payload}) => {
            state.resultsColors = payload;
        },
	},
});

export const {
    setLikelihood,
    setSeverity,
    setDanger,
    setResultsLabels,
    setResultsColors,
} = ratingSlice.actions;

export default ratingSlice.reducer;