import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setReputation, setNewReputation } from "../../slices/riskSlice";

const TableCellReputation = ({item, addNew}) => {

    const [reputation2, setReputation2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdreputation, setCheckedIdreputation] = useState("");
    const [addReputation, setAddReputation] = useState([]);
    const [addReputation2, setAddReputation2] = useState([]);
    const [reputation, setStateReputation] = useState([]);
    const dispatch = useDispatch();

    return (
        <>  
            {/* {(!item && addNew) &&
                <>
                    {addReputation ? 
                        <input type="text" className="autofilssInput" value={reputation} onChange={(e) => dispatch(setNewReputation(e.target.value)) && setStateReputation(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddReputation(true)}}>+ Add</button>
                    }
                </>
            }                                                                      */}
            {item &&
                <div class="hovers" style={item.reputation[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.reputation[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.reputation.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addReputation.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setReputation2(
                                                                (reputation2.some((el, ind) => ind == indx)) 
                                                                ? reputation2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...reputation2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setReputation(
                                                                    [   
                                                                        item.id, 
                                                                        (reputation2.some((el, ind) => ind == indx)) 
                                                                        ? [...reputation2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...reputation2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddReputation([...addReputation, true]); setCheckedIdreputation(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addReputation2 && checkedIdreputation == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdreputation == item.id ? reputation2 : ""} onChange={(e) => {dispatch(setReputation([item.id, [e.target.value]])) && setReputation2(e.target.value); setCheckedItem(item); setCheckedIdreputation(item.id)}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddReputation2(true); setCheckedIdreputation(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(reputation.includes(index) && checkedIdreputation == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault(); 
                                                reputation.some(i => i == index)
                                                    ? dispatch(setReputation([item.id, (reputation2.length != 0) ? [...reputation.filter((i) =>  i !== index), ...reputation2] : reputation.filter((i) =>  i !== index)])) && setStateReputation(reputation.filter((i) =>  i !== index))
                                                    : dispatch(setReputation([item.id, (reputation2.length != 0) ? [...[...reputation, index], ...reputation2] : [...reputation, index]])) && setStateReputation([...reputation, index])
                                                setCheckedIdreputation(item.id)}
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setReputation(reputation.includes(index) ? reputation.filter((i) =>  i !== index) : [...reputation, index]); setCheckedIdreputation(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addReputation2 && checkedIdreputation == item.id 
                            && 
                            addReputation2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setReputation2(
                                            (reputation2.some((el, ind) => ind == index)) 
                                            ? reputation2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...reputation2, e.target.value]
                                        ); 
                                        dispatch(
                                            setReputation([item.id,
                                                (reputation2.some((el, ind) => ind == index)) 
                                                ? [...reputation, ...[...reputation2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...reputation, ...[...reputation2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.reputation[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddReputation2([...reputation2, true]); setCheckedIdreputation(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addReputation2 && checkedIdreputation == item.id 
                            ? 
                            <input type="text" class="autofilssInput" value={checkedIdreputation == item.id ? reputation2 : ""} onChange={(e) => {dispatch(setReputation([item.id, [...reputation, e.target.value]])) && setReputation2([e.target.value]); setCheckedItem(item); setCheckedIdreputation(item.id)}}></input>
                            :
                            <button onClick={(e) => {e.preventDefault(); setAddReputation2(true); setCheckedIdreputation(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellReputation;