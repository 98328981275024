import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setMentally, setNewMentally } from "../../slices/riskSlice";

const TableCellMentally = ({item, addNew}) => {

    const [mentally2, setMentally2] = useState([]);
	const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdmentally, setCheckedIdmentally] = useState("");
    const [addMentally, setAddMentally] = useState([]);
    const [addMentally2, setAddMentally2] = useState([]);
    const [mentally, setStateMentally] = useState([]);
    const dispatch = useDispatch();


    return (
        <> 
            {/* {(!item && addNew) &&
                <>
                    {addMentally ? 
                    <input type="text" className="autofilssInput" value={mentally} onChange={(e) => dispatch(setNewMentally(e.target.value)) && setStateMentally(e.target.value)}></input>
                    :
                    <button onClick={(e) => {e.preventDefault(); setAddMentally(true)}}>+ Add</button>
                    }
                </>
            } */}
            {item &&
                <div class="hovers" style={item.mentally[0] === "" ? {overflow: "hidden"} : {}}>
            
                    <ul style={item.mentally[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.mentally.map((index) => (
                            <>	
                                {index === "" 
                                    ?
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addMentally.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setMentally2(
                                                                (mentally2.some((el, ind) => ind == indx)) 
                                                                ? mentally2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...mentally2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setMentally(
                                                                    [   
                                                                        item.id, 
                                                                        (mentally2.some((el, ind) => ind == indx)) 
                                                                        ? [...mentally2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...mentally2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddMentally([...addMentally, true]); setCheckedIdmentally(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addMentally2 && checkedIdmentally == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdmentally == item.id ? mentally2 : ""} onChange={(e) => {dispatch(setMentally([item.id, [e.target.value]])) && setMentally2([e.target.value]); setCheckedItem(item); setCheckedIdmentally(item.id)}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddMentally2(true); setCheckedIdmentally(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(mentally.includes(index) && checkedIdmentally == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault(); 
                                                mentally.some(i => i == index)
                                                    ? dispatch(setMentally([item.id, (mentally2.length != 0) ? [...mentally.filter(i => i != index), ...mentally2] : mentally.filter(i => i != index)])) && setStateMentally(mentally.filter(i => i != index))
                                                    : dispatch(setMentally([item.id, (mentally2.length != 0) ? [...[...mentally, index], ...mentally2] : [...mentally, index]])) && setStateMentally([...mentally, index]);
                                                setCheckedIdmentally(item.id)}
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setMentally(mentally.includes(index) ? mentally.filter((i) =>  i !== index) : [...mentally, index]); setCheckedIdmentally(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addMentally && checkedIdmentally == item.id 
                            && 
                            addMentally.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setStateMentally(
                                            (mentally2.some((el, ind) => ind == index)) 
                                            ? mentally2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...mentally2, e.target.value]
                                        ); 
                                        dispatch(
                                            setMentally([item.id,
                                                (mentally2.some((el, ind) => ind == index)) 
                                                ? [...mentally, ...[...mentally2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...mentally, ...[...mentally2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                            }
                        {item.mentally[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddMentally2([...mentally2, true]); setCheckedIdmentally(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addMentally2 && checkedIdmentally == item.id ? 
                            <input type="text" class="autofilssInput" value={checkedIdmentally == item.id ? mentally2 : ""} onChange={(e) => {dispatch(setMentally([item.id, [...mentally, e.target.value]])) && setMentally2([e.target.value]); setCheckedItem(item); setCheckedIdmentally(item.id)}}></input>
                            :
                            <button onClick={(e) => {e.preventDefault(); setAddMentally2(true); setCheckedIdmentally(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellMentally;