import '../App.css';
const Solutions = () => {
 return (
	<div>
		<main>
			<div class="container">
				<div class="header-title center">
					<h1>WHAT WE OFFER</h1> 
					<p>The only place you get the best HSE Solution</p>
				</div>
				<div class="solutions-list">
				<div class="solutions-item">
					<div class="solutions-itemin" id="">
						<div class="solutions-item-pic"><img src="img/solutions1.jpg"/>
							<div class="solutions-item-text">to provide</div>
						</div>
						<div class="solutions-item-other">
							<div class="solutions-item-title">
							DEVELOP AND DESIGN HSE MANAGEMENT SYSTEMS
							</div>
						</div>
					</div>
				</div>
				<div class="solutions-item">
					<div class="solutions-itemin" id="">
						<div class="solutions-item-pic"><img src="img/solutions2.jpg"/>
							<div class="solutions-item-text">to provide</div>
						</div>
						<div class="solutions-item-other">
							<div class="solutions-item-title">
							HSE CONSULTANCY SERVICES
							</div>
						</div>
					</div>
				</div>
				<div class="solutions-item">
					<div class="solutions-itemin" id="">
						<div class="solutions-item-pic"><img src="img/solutions3.jpg"/>
							<div class="solutions-item-text">to provide</div>
						</div>
						<div class="solutions-item-other">
							<div class="solutions-item-title">
							SELLING OF INNOVATIVE HSE SOFTWARE PRODUCTS
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		</main>
	</div>
 )
}
export default Solutions;