import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/auth-service';
import jwt_decode from 'jwt-decode';
import ProfileSettingsService from '../services/profileSettings-service';
import { resetRiskSlice, setERROR } from './riskSlice';

// export const token = localStorage.getItem('authToken');

const date = new Date();
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0');
const year = String(date.getFullYear());
const hours = String(date.getHours()).padStart(2, '0');
const minutes = String(date.getMinutes()).padStart(2, '0');
const formattedTime = `${hours}:${minutes}`;
const formattedDate = `${year}-${month}-${day}`;

export const login = createAsyncThunk(
	'user/login',
	async ({username, password, rememberMe}, thunkApi) => {
		try {
			const response = await AuthService.login(username, password, rememberMe);
			thunkApi.dispatch(setToken(response.token));
			thunkApi.dispatch(setCurrentUserId(response.user.id));
			thunkApi.dispatch(setEmailUser(response.user.username));
			thunkApi.dispatch(setDateUser(formattedDate));
			thunkApi.dispatch(setTimeUser(formattedTime));
			thunkApi.dispatch(setisAdmin(response.user.isAdmin));
			thunkApi.dispatch(setisReviewer(response.user.isReviewer));
			thunkApi.dispatch(setisApprover(response.user.isApprover));
			thunkApi.dispatch(setERROR(''));
			return response.token;
		} catch (error) {
			thunkApi.dispatch(setERROR(error.response.data.message))
			// const message = (error.response && error.response.data && error.response.data.message);
			return thunkApi.rejectWithValue(error.response);
		}
	}
);

export const registration = createAsyncThunk(
	'user/registration',
	async ({username, password}, thunkApi) => {
		try {
			const response = await AuthService.registration(username, password);
			thunkApi.dispatch(setToken(response.token));
			thunkApi.dispatch(setToken(response.token));
			thunkApi.dispatch(setCurrentUserId(response.user.id));
			thunkApi.dispatch(setEmailUser(response.user.username));
			thunkApi.dispatch(setDateUser(formattedDate));
			thunkApi.dispatch(setTimeUser(formattedTime));
			thunkApi.dispatch(setisAdmin(response.user.isAdmin));
			thunkApi.dispatch(setisReviewer(response.user.isReviewer));
			thunkApi.dispatch(setisApprover(response.user.isApprover));
			return response.token;
		} catch (error) {
			// const message = (error.response && error.response.data && error.response.data.message);
			return thunkApi.rejectWithValue();
		}
	}
);

export const logout = createAsyncThunk(
	'user/logout',
	async (data, thunkAPI) => {
		await AuthService.logout();
		thunkAPI.dispatch(resetUserSlice());
		thunkAPI.dispatch(resetRiskSlice());
	}
)

export const recoveryPassword = createAsyncThunk(
	'user/recovery',
	async ({username, password}) => {
		await AuthService.recovery(username, password);
	}
)

export const auth = createAsyncThunk(
	'user/auth',
	async (token, thunkApi) => {
		try {
			const {storedAuthToken} = token; 
			const {username} = jwt_decode(storedAuthToken);
			const response = await AuthService.auth(storedAuthToken, username);
			thunkApi.dispatch(setToken(response.token));
			thunkApi.dispatch(setCurrentUserId(response.user.id));
			thunkApi.dispatch(setEmailUser(response.user.username));
			thunkApi.dispatch(setDateUser(formattedDate));
			thunkApi.dispatch(setTimeUser(formattedTime));
			thunkApi.dispatch(setisAdmin(response.user.isAdmin));
			thunkApi.dispatch(setisReviewer(response.user.isReviewer));
			thunkApi.dispatch(setisApprover(response.user.isApprover));
		} catch (error) {
			localStorage.clear()
			return thunkApi.rejectWithValue();
		}
	}
);

export const getProfile = createAsyncThunk(
	'/profile/get',
	async (token, thunkApi) => {
		try {
			const { id } = jwt_decode(token);
			const response = await ProfileSettingsService.getOneProfile(id);
			console.log(response)
			thunkApi.dispatch(setNameUser(response.username));
			return response;
		} catch (error) {
			return thunkApi.rejectWithValue();
		}
	}
);

export const createProfile = createAsyncThunk(
	'profile/create',
	async ({ 
		currentUserId,
		username,
		userLastName, 
		oldPassword, 
		newPassword, 
		password, 
		email, 
		contact, 
		language, 
		day, 
		monthBirthday, 
		yearBirthday, 
		gender, 
		selectedCountry, 
		educationLevel, 
		otherEducational,
		monthOfWork,
		yearOfWork,
		workExperience,
		hseBio,
	}, thunkApi) => {
		try {
			const response = await ProfileSettingsService.createProfileSettings(
				currentUserId,
				username,
				userLastName, 
				oldPassword, 
				newPassword, 
				password, 
				email, 
				contact, 
				language, 
				day, 
				monthBirthday, 
				yearBirthday, 
				gender, 
				selectedCountry, 
				educationLevel, 
				otherEducational,
				monthOfWork,
				yearOfWork,
				workExperience,
				hseBio,
			);
			thunkApi.dispatch(setNameUser(response.name))
		} catch (error) {
			return thunkApi.rejectWithValue();
		}
	}
);

const initialState = {
	currentUserId: null,
	token: null,
	isLogin: false,
	isAdmin: false,
	isReviewer: false,
	isApprover: false,
	task: "", 
	name: "",
	email: "",
	date: "", 
	time: "",
	location: "",
	// error: "",
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetUserSlice: () => initialState,
		// setERROR: (state, { payload }) => {
		// 	state.error = payload;
		// },
		setCurrentUserId: (state, { payload }) => {
		state.currentUserId = payload;
		},
		setisLogin: (state, { payload }) => {
		state.isLogin = payload;
		},
		setisAdmin: (state, { payload }) => {
			state.isAdmin = payload;
		},
		setisReviewer: (state, { payload }) => {
			state.isReviewer = payload;
		},
		setisApprover: (state, { payload }) => {
			state.isApprover = payload;
		},
		setToken: (state, { payload }) => {
			state.token = payload;
		},
		setTaskUser: (state, { payload }) => {
			state.task = payload;
		},
		setNameUser: (state, { payload }) => {
			state.name = payload;
		},
		setEmailUser: (state, { payload }) => {
			state.email = payload;
		},
		setDateUser: (state, { payload }) => {
			state.date = payload;
		},
		setTimeUser: (state, { payload }) => {
			state.time = payload;
		},
		setLocationUser: (state, { payload }) => {
			state.location = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(login.fulfilled, (state, action) => {
			state.isLogin = true;
		})
		builder.addCase(registration.fulfilled, (state, action) => {
			state.isLogin = true;
		})
		builder.addCase(login.rejected, (state, action) => {
			state.isLogin = false;
		})
		builder.addCase(logout.fulfilled, (state, action) => {
			state.currentUserId = null;
			state.token = null;
			state.isLogin = false;
			state.isAdmin = false;
			state.task = ""; 
			state.name = "";
			state.email = "";
			state.date = ""; 
			state.time = "";
			state.location = "";
		})
		builder.addCase(auth.fulfilled, (state, action) => {
			state.isLogin = true;
		})
		builder.addCase(auth.rejected, (state, action) => {
			state.isLogin = false;
		})
		// builder.addCase(create.fulfilled, (state, action) => {
		// 	state.isLogin = true;
		// })
		// builder.addCase(create.rejected, (state, action) => {
		// 	state.isLogin = true;
		// })
		// [auth.fulfilled]: (state, action) => {
		// 	state.isLogin = true;
		// 	// state.isAdmin = true;
		// },
		// [auth.pending]: (state, action) => {
		// 	state.isLogin = true;
		// },
		// [auth.rejected]: (state, action) => {
		// 	state.isLogin = false;
		// },
		// [login.fulfilled]: (state, action) => {
		// 	state.isLogin = true;
		// 	// state.isAdmin = true;
		// },
		// [login.rejected]: (state, action) => {
		// 	state.isLogin = false;
		// },
		// [logout.fulfilled]: (state, action) => {
		// 	state.currentUserId = null;
		// 	state.token = null;
		// 	state.isLogin = false;
		// 	state.isAdmin = false;
		// 	state.task = ""; 
		// 	state.name = "";
		// 	state.email = "";
		// 	state.date = ""; 
		// 	state.time = "";
		// 	state.location = "";
		// },
	}
});

export const {
	resetUserSlice,
	// setERROR,
	setCurrentUserId,
	setisLogin,
	setisAdmin,
	setisReviewer,
	setisApprover,
	setToken,
	setDateUser,
	setTimeUser,
	setNameUser,
	setTaskUser,
	setLocationUser,
	setEmailUser,
} = userSlice.actions;

export default userSlice.reducer;