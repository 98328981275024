import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setRegistrar } from "../../slices/riskSlice";

const TableCellRegistrar = ({item}) => {

    const [registrar2, setRegistrar2] = useState("");
    const [checkedIdregistrar, setCheckedIdregistrar] = useState("");
    const [addRegistrar2, setAddRegistrar2] = useState(false);
    const user = useSelector(({user}) => user);

    return (
        <>
            {item.registrar === "" 
                ? 
                <div class="autofilss" key={item.id}>
                    <input type="text" class="autofilssInput" placeholder={(user.name == '') ? user.email : user.name} onChange={(e) => {setRegistrar2(e.target.value);  setCheckedIdregistrar(item.id);}}></input>
                    {/* {addRegistrar2 && checkedIdregistrar == item.id
                    ? 
                    <input type="text" class="autofilssInput" value={checkedIdregistrar == item.id ? registrar2 : ""} onChange={(e) => {setRegistrar2(e.target.value);  setCheckedIdregistrar(item.id);}}></input>
                    :
                    <button onClick={(e) => {e.preventDefault(); setAddRegistrar2(true); setCheckedIdregistrar(item.id);}}>+ Add</button>
                    } */}
                </div> 
                : 
                <div class="autofilss" key={item.id}>
                    <span>
                        <input type="checkbox"/>
                    </span>
                    <Popover content={item.registrar}>
                        <div class="truncate" style={{width: "300px"}}>{item.registrar}</div>
                    </Popover>
                </div>
            }
        </>
    )
};

export default TableCellRegistrar;