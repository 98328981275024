import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setBehavior, setNewBehavior } from "../../slices/riskSlice";

const TableCellBehavior = ({item, addNew}) => {

    const [behavior2, setBehavior2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdbehavior, setCheckedIdbehavior] = useState("");
    const [addBehavior, setAddBehavior] = useState([]);
    const [addBehavior2, setAddBehavior2] = useState([]);
    const [behavior, setStateBehavior] = useState([]);
    const dispatch = useDispatch();

    return (
        <>		
            {/* {(!item && addNew) && 
                <>
                    {addBehavior ? 
                        <input type="text" className="autofilssInput" value={behavior} onChange={(e) => dispatch(setNewBehavior(e.target.value)) && setStateBehavior(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddBehavior(true)}}>+ Add</button>
                    }
                </>
            }																 */}
            {item &&
                <div class="hovers" style={item.behavior[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.behavior[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.behavior.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addBehavior.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setBehavior2(
                                                                (behavior2.some((el, ind) => ind == indx)) 
                                                                ? behavior2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...behavior2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setBehavior(
                                                                    [   
                                                                        item.id, 
                                                                        (behavior2.some((el, ind) => ind == indx)) 
                                                                        ? [...behavior2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...behavior2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddBehavior([...addBehavior, true]); setCheckedIdbehavior(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addBehavior2 && checkedIdbehavior == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdbehavior == item.id ? behavior2 : ""} onChange={(e) => {dispatch(setBehavior([item.id, [e.target.value]])) && setBehavior2(e.target.value); setCheckedItem(item); ; setCheckedIdbehavior(item.id)}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddBehavior2(true); setCheckedIdbehavior(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <>
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(behavior.includes(index) && checkedIdbehavior == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault(); 
                                                behavior.some(i => i == index)
                                                    ? dispatch(setBehavior([item.id, (behavior2.length != 0) ? [...behavior.filter((i) =>  i !== index), ...behavior2] : behavior.filter((i) =>  i !== index)])) && setStateBehavior(behavior.filter((i) =>  i !== index))
                                                    : dispatch(setBehavior([item.id, (behavior2.length != 0) ? [...[...behavior, index], ...behavior2] : [...behavior, index]])) && setStateBehavior([...behavior, index])
                                                setCheckedIdbehavior(item.id)}
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setBehavior(behavior.includes(index) ? behavior.filter((i) =>  i !== index) : [...behavior, index]); setCheckedIdbehavior(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addBehavior2 && checkedIdbehavior == item.id 
                            && 
                            addBehavior2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setBehavior2(
                                            (behavior2.some((el, ind) => ind == index)) 
                                            ? behavior2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...behavior2, e.target.value]
                                        ); 
                                        dispatch(
                                            setBehavior([item.id,
                                                (behavior2.some((el, ind) => ind == index)) 
                                                ? [...behavior, ...[...behavior2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...behavior, ...[...behavior2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.behavior[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddBehavior2([...behavior2, true]); setCheckedIdbehavior(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addBehavior2 && checkedIdbehavior == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={ checkedIdbehavior == item.id ? behavior2 : ""} onChange={(e) => {dispatch(setBehavior([item.id, [...behavior, e.target.value]])) && setBehavior2([e.target.value]); setCheckedIdbehavior(item.id); setCheckedItem(item);}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddBehavior2(true); setCheckedIdbehavior(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellBehavior;