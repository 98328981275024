import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setPeople, setNewPeople } from "../../slices/riskSlice";

const TableCellPeopleNew = ({item, addNew}) => {

    const dispatch = useDispatch();
    const [people2, setStatePeople2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdpeople, setCheckedIdpeople] = useState("");

	const [addPeople2, setAddPeople2] = useState(false);
    const [people, setStatePeople] = useState([]);
    const [peopleArray, setStatePeopleArray] = useState([]);
    
    const [peopleArrayUl, setStatePeopleArrayUl] = useState([]);

    return (
        <>
            {peopleArray.map((element, index) => 
                <>
                    {element
                        &&
                        <div className={`hovers ${index}`}>
                            <ul style={{padding: '0', margin: '0'}}>
                                {
                                    // console.log(peopleArrayUl)
                                    peopleArrayUl.map((item, indx) =>
                                        (indx == index) && item.map((i, ind) =>
                                        i &&
                                        <li class="autofilss">
                                            <input type="text" className="autofilssInput" onChange={(e) => dispatch(setNewPeople([index.toString(), ind, e.target.value ]))}></input>
                                        </li>
                                    ))
                                }
                                <div className="autofilss">
                                    <button onClick={(e) => {e.preventDefault(); setStatePeopleArrayUl(peopleArrayUl.map((item, ind) => (ind == index) ? item = [...item, true] : item));}}>+ Add</button>
                                </div>  
                            </ul>
                        </div>   
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStatePeopleArray([...peopleArray, true]); setStatePeopleArrayUl([...peopleArrayUl, [true]])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellPeopleNew;