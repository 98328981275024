import React, {useState,  useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { auth, logout, setisLogin, registration, login } from '../slices/userSlise';
import Risk2Service from '../services/risk2-services';
import { setDataCheck } from '../slices/riskSlice';
import { Modal} from "antd";
import '../App.css';
const NavBar = () => {

	const storedAuthToken = localStorage.getItem("authToken");
	const { user } = useSelector((state) => state);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [data, setData] = useState({risks: []});
	const [whoAmI, setWhoAmI] = useState('user')
	const [isLoading, setIsLoading] = useState(false);
	const [open , setOpenModal] = useState(false)
	const [openLogin , setOpenLogin] = useState(false)
	const [openSignUp , setOpenSignUp] = useState(false)
    const [password, setPassword] = useState("");
	const [username, setName] = useState("");
	const error = useSelector(({risk}) => risk.error);
	const [rememberMe, setRememberMe] = useState(false);

	useEffect(() => {
		if(storedAuthToken && !user.isLogin){
			try {
				dispatch(auth({storedAuthToken}));
			} catch (error) {
				navigate('/')
			}
		}
	}, [])

	useEffect(() => {
		if(storedAuthToken && user.isLogin && (user.isAdmin || user.isReviewer || user.isApprover)){
			async function getRisks(){
				const risks2 = await Risk2Service.getAllRisks2()
				setData({risks: risks2});
				console.log(risks2)
			}
			getRisks()
		}

	}, [user, storedAuthToken])

	useEffect(() => {
		if(user.isAdmin){
			setWhoAmI('admin')
		}else if(user.isReviewer){
			setWhoAmI('reviewer')
		}else if(user.isApprover){
			setWhoAmI('approver')
		}
	}, [user])

	const LogOut = () => {
		dispatch(logout());
		navigate('/');
	}
	const closeModal = () => {
		setOpenModal(false)
	}
	const checkHandler = () => {
		setRememberMe(!rememberMe)
	} 
	const handleOnSubmitLogin = async (e) => {
        e.preventDefault();
		if(rememberMe){
			try {
				await dispatch(login({username, password, rememberMe})).unwrap();
				closeModalLogin();
				navigate('/lk');
			} catch (error) {
				console.log(error)
			}

		}else{
			try {
				await dispatch(login({username, password, rememberMe})).unwrap();
				closeModalLogin();
				navigate('/lk');
			} catch (error) {
				console.log(error)
			}
		}

    }
    const handleOnSubmitSignUp = async (e) => {
        e.preventDefault();
		dispatch(registration({username, password}))
		navigate('/lk');
    }
	const closeModalLogin = () => {
		setOpenLogin(false)
	}
	const closeModalSignUp = () => {
		setOpenSignUp(false)
	}

	const renderCurrentNotification = () => {
		switch (whoAmI) {
			case "user":
				return (
					<>
						<span class="iconnoty">
							<span class="iconnoty-count">{data.risks.filter(item => item.userid == user.currentUserId).length}</span>
						</span>
						{data.risks.filter(item => item.userid == user.currentUserId).length != 0 ?
							<span class="iconnoty-data" style={{borderRadius: '10px'}}>
								{data.risks.filter(item => item.userid == user.currentUserId).filter(item => !item.approve && !item.decline).map((item) => (
									<span class="iconnoty-data-item">
										<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
									</span>
								))}
								{data.risks.filter(item => item.userid == user.currentUserId).map((item) => (
									<span class="iconnoty-data-item">
										<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted at {item.createdAt} was {item.approve ? "approved" : "decline"}]</Link>
									</span>
								))}
							</span>
							:
							<></>
						}
					</>
				)
			case "admin":
				return (
					<>
						<span class="iconnoty"> 
							<span class="iconnoty-count">{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusApprove == 1).length}</span> 
						</span>
						<span class="iconnoty-data" style={{borderRadius: '10px'}}>
							{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusApprove == '1').map((item) => (
								<span class="iconnoty-data-item">
									<Link key={item.id} onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
								</span>
							))}
						</span>
					</> 
				)
			case "reviewer":
				return (
					<>
						<span class="iconnoty"> 
							<span class="iconnoty-count">{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '0').length}</span> 
						</span>
						<span class="iconnoty-data" style={{borderRadius: '10px'}}>
							{data.risks.filter(item => !item.approve && !item.decline && !item.saved  && item.statusReview == '0').map((item) => (
								<span class="iconnoty-data-item">
									<Link key={item.id} onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
								</span>
							))}
						</span>
					</> 
				)
			case "approver":
				return (
					<>
						<span class="iconnoty"> 
							<span class="iconnoty-count">{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '1' && item.statusApprove == '0').length}</span> 
						</span>
						{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '1' && item.statusReview == '0') &&
							<span class="iconnoty-data" style={{borderRadius: '10px'}}>
							{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '1' && item.statusReview == '0').map((item) => (
								<span class="iconnoty-data-item">
									<Link key={item.id} onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
								</span>
							))}
							</span>
						}
					</> 
				)
		}
	}
    return (
		<header>
			<div class="container">
				<div class="treehadsheader">
					<div class="treehadsheader1">
						<div class="logohead"><Link to="/"><img src="/img/Logo.svg"/></Link></div>
						{user.isLogin ? 
						<div class="thobutts_headers">
							<Link class="iconslk" to="/lk"></Link>
							{!isLoading ?
							<div class="notylk">
							{renderCurrentNotification()}
							{/* {
								user.isAdmin ? 
									<>
										<span class="iconnoty"> 
											<span class="iconnoty-count">{data.risks.filter(item => !item.approve && !item.decline && !item.saved).length}</span> 
										</span>
										<span class="iconnoty-data" style={{borderRadius: '10px'}}>
											{data.risks.filter(item => !item.approve && !item.decline && !item.saved).map((item) => (
												<span class="iconnoty-data-item">
													<Link key={item.id} onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
												</span>
											))}
										</span>
									</> 
								: 
									<>
										<span class="iconnoty">
											<span class="iconnoty-count">{data.risks.filter(item => item.userid == user.currentUserId).length}</span>
										</span>
										{data.risks.filter(item => item.userid == user.currentUserId).length != 0 ?
											<span class="iconnoty-data" style={{borderRadius: '10px'}}>
												{data.risks.filter(item => item.userid == user.currentUserId).filter(item => !item.approve && !item.decline).map((item) => (
													<span class="iconnoty-data-item">
														<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</span>
												))}
												{data.risks.filter(item => item.userid == user.currentUserId).map((item) => (
													<span class="iconnoty-data-item">
														<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted at {item.createdAt} was {item.approve ? "approved" : "decline"}]</Link>
													</span>
												))}
											</span>
											:
											<></>
										}
									</>
							} */}
							</div>
						: <div class="notylk">
							<span class="iconnoty"> <span class="iconnoty-count"></span> </span>
								<span class="iconnoty-data">
								
								<span class="iconnoty-data-item"></span>
								</span>
						</div>
						}
						</div>
						: <></>
						}
					</div>
					<div class="treehadsheader2">
						<nav class="hmenu">
							<ul>
								<li class="sublink"><Link to="/about">About Us</Link>
								<div class="hoverslinks">
									<ul>
										<li class="sublink"><Link to="/gettoknow">Who we are</Link></li>
										{user.isLogin ? 
										<li class="sublink"><Link to="/contractors">CONTRACTORS</Link></li>
										:
										<li class="sublink"><Link to="" onClick={() => setOpenModal(true)}>CONTRACTORS</Link></li>
										}
									</ul>
								</div>
								</li>
								<li><Link to="/solutions">Solutions</Link></li>
								{user.isLogin ? 
								<li><Link type='button' onClick={LogOut}>Log out</Link></li>
								:
								<li><Link class="jsgetlogin" onClick={() => setOpenLogin(true)} to="/">Login/Sign Up</Link></li>
								}
								<li><Link to="/contacts">Contact Us</Link></li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
			<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={open} onCancel={closeModal} id="getmylogin"  footer={null}>
				<div class="succespopup aboutforms">
					<div class="succtext1">You have to sign up</div>
					<div class="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close class="succtext3"  type="button" onClick={closeModal}>ok</div>
				</div>
			</Modal>
			<Modal style={{padding: 0}} open={openLogin} onCancel={closeModalLogin}  id="getmylogin" class="fancybox-content" footer={null}>
            <div class="pop-container aboutforms">
                <div class="pop-container-tabs">
                    <div type="button" onClick={() => {setOpenLogin(true); setOpenSignUp(false)}} className="spre1 pop-container-tabsd active" >log in</div>
                    <div type="button" onClick={() => {setOpenSignUp(true); setOpenLogin(false)}} className="spre2 pop-container-tabsd" >Sign up</div>
                </div>
                <div class="pop-container-tabs_bot">
                    <div class="tospre1 pop-container-tabsd_bot active">
                        <form class="authajax" name="system_auth_form" target="_top" action="">

                            <input type="text" placeholder="User name/email address" value={username} onChange={(e) => setName(e.target.value)} required/>
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                            {error ? <div style={{color: "red"}}>{error}</div> : <></>}
                            <div class="blockremembr">
                                <div class="blockremembr-l">
                                    <input type="checkbox" checked={rememberMe} onChange={checkHandler} id="USER_REMEMBER_frm" name="USER_REMEMBER" value="Y" />
                                    <label for="USER_REMEMBER_frm" >Remember me?</label>
                                </div>
                                <div class="blockremembr-r"><Link to="/forgot/">Forgot password?</Link></div>

                            </div>
                            <input name="Login" type="submit" onClick={(e) => handleOnSubmitLogin(e)}/>
                        </form>
                    </div>
                </div>
            </div>
            </Modal>
            <Modal style={{padding: 0}} open={openSignUp} onCancel={closeModalSignUp}  id="getmylogin" class="fancybox-content" footer={null}>
            <div class="pop-container aboutforms">
                <div class="pop-container-tabs">
                    <div type="button" onClick={() => {setOpenLogin(true); setOpenSignUp(false)}} class="spre1 pop-container-tabsd">log in</div>
                    <div type="button" onClick={() => {setOpenSignUp(true); setOpenLogin(false)}} class="spre2 pop-container-tabsd active">Sign up</div>
                </div>
                <div class="pop-container-tabs_bot">
                    <div class="tospre1 pop-container-tabsd_bot active">
                        <form class="authajax" name="system_auth_form" method="post" action="">
                            <input type="text" placeholder="User name/email address" name="USER_LOGIN" value={username} onChange={(e) => setName(e.target.value)} required/>
                            <input type="password" placeholder="Password" name="USER_PASSWORD" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                            <input type="password" placeholder="Confirm password" name="pass2" required/>
                            <input name="SignUp" type="submit" onClick={handleOnSubmitSignUp} placeholder="" value="continue"/>
                        </form>
                    </div>
                </div>
            </div>
            </Modal>
		</header>
    )
}
export default NavBar