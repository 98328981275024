import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {  Link, useNavigate } from 'react-router-dom'
import { Modal, Spin} from "antd";
import { useSelector, useDispatch } from 'react-redux';

import '../App.css';
import { setCurrentUserId, setToken, setisLogin, setisAdmin, setNameUser, recoveryPassword } from '../slices/userSlise';
const Forgot = () => {
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [username, setName] = useState("");
	const [error, setError] = useState('')
	const [statusError, setStatusError] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	console.log(error)

	useEffect(() => {
		(username == '' || password == '' || confirmPassword == '') ? setError('Fill in all the fields') : setError('Passwords mismatch');
	}, [password, username, confirmPassword])

	useEffect(() => {
		if(password != confirmPassword){
			setStatusError(true)
		}else{
			setStatusError(false)
		}
	}, [password, confirmPassword])

	const handleOnSubmit = async (e) => {
		e.preventDefault();
		if(!statusError && password != '' && confirmPassword != ''){
			dispatch(recoveryPassword({username, password}))
			navigate('/')
		}else{
			setStatusError(true)
		}
        // let result = await fetch(
        // `http://${host}:3001/login`, {
        //     method: "put",
        //     body: JSON.stringify({ username, password }),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
        // result = await result.json();
		// console.log("result", result)
		// 	if (result) {
		// 		dispatch(setToken(result));
		// 		dispatch(setisLogin(true));
		// 		dispatch(setisAdmin(result.user.isAdmin));
		// 		dispatch(setCurrentUserId(result.user.id));
		// 		dispatch(setNameUser(result.user.username));
		// 		setPassword("");
		// 		setName("");
		// 		navigate('/lk');
		// 	}
		
    }
	return (
		<div>
		<main>
			<div className="container">
				<div className="thohalfs">
				<div style={{margin: "auto"}} className="thohalfs-l">
					<div className="aboutforms">
						<div className="formstitle">Reset the password</div>
						
						<form className="preform1" method="post" action="">
								<input type="email" placeholder="User name/email address" name="Email" value={username} onChange={(e) => setName(e.target.value)} required/>
								<input type="password" placeholder="New Password" name="USER_PASSWORD" value={password} onChange={(e) => setPassword(e.target.value)} required/>
								<input type="password" placeholder="Confirm new password" name="pass2" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
								{statusError && <div style={{color:'red', marginTop: '10px'}}>{error}</div>}
								<input type="submit" placeholder="" value="continue" onClick={handleOnSubmit}/>
						</form>
					</div>
				</div>
				</div>
			</div>
		</main>
	</div>
		  )
}
export default Forgot;