import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {  useNavigate } from 'react-router-dom'
import { Modal} from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserId, setToken, setisLogin, setisAdmin, setLocationUser, setDateUser, setNameUser, setTimeUser, setTaskUser, registration } from '../slices/userSlise';
import TaskService from '../services/task-service';
import '../App.css';
const Risk = () => {
	const storedAuthToken = localStorage.getItem("authToken");
	const [open , setOpenModal] = useState(false)
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
    const [location, setLocation] = useState("");
	const [task, setTask] = useState("");
	const [locationDirty, setLocationDirty] = useState(false);
	const [locationError, setLocationError] = useState("Location can't be empty.");
	const [taskDirty, setTaskDirty] = useState(false);
	const [taskError, setTaskError] = useState("Task Assessment can't be empty.")
	const [suggestions, setSuggestions] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const currentDate = new Date();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state);
	const day = String(currentDate.getDate()).padStart(2, '0');
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const year = String(currentDate.getFullYear());
	const hours = String(currentDate.getHours()).padStart(2, '0');
	const minutes = String(currentDate.getMinutes()).padStart(2, '0');
	const seconds = String(currentDate.getSeconds()).padStart(2, '0');
	const formattedTime = `${hours}:${minutes}`;
	currentDate.setDate(currentDate.getDate());
	// const formattedDate = `${day}.${month}.${year}`;
	const formattedDate = `${year}-${month}-${day}`;
	const [date, setDate] = useState(formattedDate);
	// const [date, setDate] = useState(currentDate);
	const onSetDate = (event) => {
		setDate(new Date(event.target.value))
	}
	const [time, setTime] = useState(formattedTime);
	const [secondName, setSecondName] = useState(user.name);
	
	async function getCityByCoordinates(latitude, longitude) {
		const apiKey = "b5b1b009c0a3433092f5a76adfc59543"; // Ваш API-ключ OpenCage Geocoder
	  
		const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
	  
		try {
			const response = await axios.get(url);
			const city = response.data.results[0].components.county;
			setLocation(city)
			return city;
		} catch (error) {
			console.error('Ошибка при получении города:', error);
			return null;
		}
	}
	  // Получение текущих координат
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;
		
				// Получение города по координатам
				const city = await getCityByCoordinates(latitude, longitude);
				setLocation(city)
			},
			(error) => {
				console.error('Ошибка при получении геолокации:', error);
			}
		);
	} else {
		console.error('Геолокация не поддерживается в данном браузере.');
	}
	// useEffect(() => {
	// 	async function fetchData() {
	// 		try {
	// 			const response = await axios.get(`http://${host}:3001/task/`);
	// 			const data = response.data.category
	// 			setSuggestions(data);
	// 		} catch (err) {
	// 			console.log(err);
	// 		} 
	// 	}
	// 	async function fetchData1() {
	// 		try {
	// 		if (user.currentUserId) {
	// 		  const response = await axios.get(`http://${host}:3001/lk${user.currentUserId}`);
	// 		  const data = response.data
	// 		  console.log("DATA 1", data)
	// 		  //setSecondName(data.user.username);
	// 		}
	// 		} catch (err) {
	// 		  console.log(err);
	// 		} 
	// 	  }
	// 	fetchData1();
	// 	fetchData();
	// }, []);

	const checkIfTaskExists = (array, task) => {
		return array.some((item) => item.task === task);
	}

	const handleInputChange = (event) => {
		const value = event.target.value;
		setTask(value);
	
		const filteredSuggestions = suggestions.filter((suggestion) =>
		suggestion
		);
		setSuggestions(filteredSuggestions);
		setIsDropdownOpen(false);
	};

	const handleInputFocus = () => {
		// При фокусе на поле ввода открываем список
		setIsDropdownOpen(true);
	};
	const handleSuggestionClick = (suggestion) => {
		setTask(suggestion);
		//setSuggestions([]);
		setIsDropdownOpen(false);
	};
  	const dispatch = useDispatch();
	// const handleOnSubmitTask = async (e) => {
	// 	console.log(suggestions, suggestions.includes(task))
    //     e.preventDefault();
	// 	if(!checkIfTaskExists(suggestions, task)) {
			
	// 		let result = await fetch(
	// 		`http://${host}:3001/task`, {
	// 			method: "post",
	// 			body: JSON.stringify({ task }),
	// 			headers: {
	// 				'Content-Type': 'application/json'
	// 			}
	// 		})
	// 		result = await result.json();
	// 		console.log("Task result", result)
	// 		if (result) {
	// 			dispatch(setNameUser(secondName));
	// 		    dispatch(setDateUser(date));
	// 			dispatch(setTimeUser(time));
	// 		    dispatch(setLocationUser(location));
	// 		    dispatch(setTaskUser(task));
	// 			navigate('/risktable');
	// 		}
	// 	} else {
	// 		dispatch(setNameUser(secondName));
	// 		dispatch(setDateUser(date));
	// 		dispatch(setTimeUser(time));
	// 		dispatch(setLocationUser(location));
	// 		dispatch(setTaskUser(task));
	// 		navigate('/risktable');
	// 	}
    // }
	const closeModal = () => {
		setOpenModal(false)
	}
	const handleClick = () => {
		setOpenModal(true);
	};

	useEffect(() => {
		if(storedAuthToken && user.isLogin){
			async function getTasks(){
				try {
					const tasks = await TaskService.getAllTasks();
					setSuggestions(tasks)
				} catch (error) {
					console.log(error)
				}
			}
			getTasks()
		}
	}, [storedAuthToken, user])

	const handleOnSubmitTask = async (e) => {
        // e.preventDefault();
		if(!checkIfTaskExists(suggestions, task)) {
			try {
				await TaskService.createTask(task);
				dispatch(setTaskUser(task));
				navigate('/risktable');
			} catch (error) {
				console.log(error)
			}
		} else {
			dispatch(setTaskUser(task));
			navigate('/risktable');
		}
    }
	const click = async (e) => {
		e.preventDefault();
		if(!location){
			let elem = document.getElementById('location');
			elem.classList.add('notValidInput');
			elem.placeholder = "";
			setLocationDirty(true);
		}
		if(!task){
			let elem = document.getElementById('task');
			elem.classList.add('notValidInput');
			elem.placeholder = "";
			setTaskDirty(true);
		}
		if(location && task){
			handleOnSubmitTask();

		}
		return;
	}
	return (
		<div>
		<main>
			<div class="container">
				<div class="aboutforms">
					<div class="formstitle">Register Hazard & Risk Assesment </div>
					<form class="preform1" method="post" action="">
							<input type="text" placeholder="Name" name="Name" value={secondName} onChange={(e) => setSecondName(e.target.value)}/>
							<input type="date"  placeholder="Date" name="Date" value={date} onChange={(e) => setDate(e.target.value)}/>
							{/* <input type="date"  placeholder="Date" name="Date" value={date.toLocaleDateString('en-CA')} onChange={onSetDate}/> */}
							<input type="time"  placeholder="Time" name="Time" value={time} onChange={(e) => setTime(e.target.value)}/>
							{(locationDirty && locationError) && <label id='location' style={{color:'red', position: 'relative', top: '15px'}}>{locationError}</label> }
							<input type="text"  placeholder="Location" id='location' name="Location" value={location} onChange={(e) => setLocation(e.target.value)}/>
							{(taskDirty && taskError) && <label id='task' style={{color:'red', position: 'relative', top: '15px'}}>{taskError}</label> }
							<input type="text" placeholder="Task Assessment" id='task' name="Task" value={task} onChange={handleInputChange} onFocus={handleInputFocus}/>
							{/* <input type="text" placeholder="Location" name="Location" value={location} onChange={(e) => setLocation(e.target.value)}/>
							<input type="text" placeholder="Task Assessment" name="Task" value={task} onChange={handleInputChange} onFocus={handleInputFocus}/> */}
							{isDropdownOpen && ( 
							<ul>
							{suggestions.map((index) => (
								<li key={index.id} onClick={() => handleSuggestionClick(index.task)}>
								{index.task}
								</li>
							))}
							</ul>
						)}
						{storedAuthToken ? 
						<input type="submit" placeholder="" value="SUBMIT" onClick={click}/>
						: 
						<input type="button" placeholder="" value="SUBMIT" onClick={() => setOpenModal(true)}/>
						}
					</form>
				</div>
				<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={open} onCancel={closeModal} id="getmylogin"  footer={null}>
				<div class="succespopup aboutforms">
					<div class="succtext1">You have to sign up</div>
					<div class="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close class="succtext3"  type="button" onClick={closeModal}>ok</div>
				</div>
			</Modal>
			</div>
		</main>
	</div>
		  )
}
export default Risk;