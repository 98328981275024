import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route } from "react-router-dom";

const ProtectedRoute = () => {
    const isAuth = useSelector((state) => state.user.isLogin);

    return (
        isAuth ? <Outlet /> : <Navigate to='/' />
    );
};
export default ProtectedRoute;