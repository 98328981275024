import '../App.css';
const About = () => {
return (
<div className='background-size'>
<main>
	<div class="container">
		<div class="aboutpage">
			<div class="aboutpagel">
				<div class="header-title ">
					<h1>about us</h1>
				</div>
					<br/>
					Smart intelligence management system concept is rooted in the high importance of a system process orientation for continuous process improvement in organizational HSE management system design. <br/>
					<br/>
					The design of this model corroborates numerous proprietary rights of the owner for a predictable A.I &amp; M.L pattern of evolution and change for improvement in any organization or businesses, usually include sequence of levels that together form desired compliances, or precise path from an initial organizational level to an advance A.I system that will be commonly applied.<br/>
					<br/>
					Does assess the is-as situation.
					<br/>
					Prioritize measures and control progress on all departmental process. <br/>
					
			</div>
			<div class="aboutpager">
				<img src="img/bgabout.png"/>
			</div>
		</div>
	</div>
</main>
</div>
)
}
export default About;