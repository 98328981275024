import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/auth-service';
import jwt_decode from 'jwt-decode';
import ProfileSettingsService from '../services/profileSettings-service';
import RiskService from '../services/risk-services';
import Risk2Service from '../services/risk2-services';

export const handleSubmit = createAsyncThunk(
    'risk2/risktable2',
    async ({save}, thunkAPI) => {
        const state = thunkAPI.getState().risk;
        const hazardsList = state.hazardList;
        const hazardsIds = hazardsList.map(i => Object.keys(i).toString());
        const attention = state.attention;
        const history = state.history;
        const training = state.training;
        const threat = state.threat;
        const physically = state.physically;
        const mentally = state.mentally;
        const emotionally = state.emotionally;
        const people = state.people;
        const environment = state.environment;
        const asset = state.asset;
        const reputation = state.reputation;
        const likelihood = state.likelihood;
        const severity = state.severity;
        const danger = state.danger;
        const knowledge = state.knowledge;
        const action = state.action;
        const behavior = state.behavior;
        const reference = state.reference;
        const registrar = thunkAPI.getState().user.name;
        const userid = thunkAPI.getState().user.currentUserId;
        const category = thunkAPI.getState().user.task;
        const itemid = userid;
        console.log(attention)

        await Risk2Service.createRisk2(
            userid,
            category,
            JSON.stringify(hazardsList),
            JSON.stringify(attention),
            JSON.stringify(history),
            JSON.stringify(training),
            JSON.stringify(threat),
            JSON.stringify(physically),
            JSON.stringify(mentally),
            JSON.stringify(emotionally),
            JSON.stringify(people),
            JSON.stringify(environment),
            JSON.stringify(asset),
            JSON.stringify(reputation),
            JSON.stringify(likelihood),
            JSON.stringify(severity),
            JSON.stringify(danger),
            JSON.stringify(knowledge),
            JSON.stringify(action),
            JSON.stringify(behavior),
            JSON.stringify(reference),
            registrar,
            itemid,
            save,
        );
        thunkAPI.dispatch(resetRiskSlice())
    }
);

export const handleSave = createAsyncThunk(
    'risk2/risktable',
    async (id, thunkAPI) => {
        try {
            await Risk2Service.updateRisk2(id);
        } catch (error) {
            thunkAPI.rejectWithValue();
        }
    }
);

export const review = createAsyncThunk(
    'risk2/review/:id',
    async ({id, status, reason}, thunkAPI) => {
        try {
            let name = thunkAPI.getState().user.name;
            if(name == '') name = thunkAPI.getState().user.email;
            console.log(name)
            await Risk2Service.reviewRisk2(id, status, name, reason)
        } catch (error) {
            thunkAPI.rejectWithValue();
        }
    }
);

export const approve = createAsyncThunk(
    'risk2/approve/:id',
    async ({id, status, reason}, thunkAPI) => {
        try {
            let name = thunkAPI.getState().user.name;
            if(name == '') name = thunkAPI.getState().user.email;
            console.log(name)
            await Risk2Service.approveRisk2(id, status, name, reason)
        } catch (error) {
            thunkAPI.rejectWithValue();
        }
    }
);

export const complete = createAsyncThunk(
    'risk2/admin/:id',
    async ({id, status, selectedLabel, reason}, thunkAPI) => {
        try {
            await Risk2Service.adminRisk2(id, status, selectedLabel, reason)
        } catch (error) {
            thunkAPI.rejectWithValue();
        }
    }
);
const initialState =  {
    data: [],
    data2: [],
    dataCheck: [],
    dataSave: false,
    hazardList: [],
    attention: [],
    history: [],
    training: [],
    threat: [],
    physically: [],
    mentally: [],
    emotionally: [],
    people: [],
    environment: [],
    asset: [],
    reputation: [],
    likelihood: [],
    severity: [],
    danger: [],
    knowledge: [],
    action: [],
    behavior: [],
    reference: [],
    registrar: [],
    error: '',
}
export const riskSlice = createSlice({
	name: 'risk',
    initialState,
	reducers: {
        // resetRiskSlice: () => initialState,
        resetRiskSlice: (state) => {
            state.data = [];
            state.data2 = [];
            state.dataCheck = [];
            state.hazardList = [];
            state.attention = [];
            state.history = [];
            state.training = [];
            state.threat = [];
            state.physically = [];
            state.mentally = [];
            state.emotionally = [];
            state.people = [];
            state.environment = [];
            state.asset = [];
            state.reputation = [];
            state.likelihood = [];
            state.severity = [];
            state.danger = [];
            state.knowledge = [];
            state.action = [];
            state.behavior = [];
            state.reference = [];
            state.registrar = [];
            state.error = '';
        },
        setERROR: (state, {payload}) => {
            state.error = payload;
        },
        setDataRisks: (state, {payload}) => {
            state.data = payload;
        },
        setData2Risks: (state, {payload}) => {
            state.data2 = payload;
        },
        setDataCheck: (state, {payload}) => {
            state.dataCheck = payload;
        },
        setDataSave: (state, {payload}) => {
            state.dataSave = payload;
        },
        setHazardList: (state, {payload}) => {
            // state.hazardList = payload;
            state.hazardList = [...state.hazardList, {[payload.id]:`${payload.hazardsList}`}];
            state.attention = [...state.attention, {[payload.id]:`${payload.attention}`}]
            state.history = [...state.history, {[payload.id]:`${payload.history}`}]
            state.training = [...state.training, {[payload.id]:`${payload.training}`}]
        },
        unsetHazardList: (state, {payload}) => {
            console.log(payload)
            state.hazardList.splice(state.hazardList.indexOf(state.hazardList.find(obj => Object.keys(obj).toString() == payload)), 1)
            state.attention = [...state.attention.filter(obj => Object.keys(obj) != payload)]
            state.history = [...state.history.filter(obj => Object.keys(obj) != payload)]
            state.training = [...state.training.filter(obj => Object.keys(obj) != payload)]
        },
        setNewHazardList: (state, {payload}) => {
            const [id, hazard] = payload;
            (state.hazardList.some(obj => Object.keys(obj).includes(`new${id}`)))
            ? state.hazardList = [...state.hazardList.filter(obj => Object.keys(obj) != `new${id}`), {[`new${id}`]: hazard}]
            : state.hazardList = [...state.hazardList, {[`new${id}`]: hazard}]
            // state.hazardList = [...state.hazardList.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setSavedHazardList: (state, {payload}) => {
            if(payload){
                const saved = state.dataSave;
                console.log(saved);
                (state.dataSave.hazardsList) ? state.hazardList = [...state.hazardList, ...JSON.parse(saved.hazardsList)] : state.hazardList = [];
                (state.dataSave.attention) ? state.attention = [...state.attention, ...JSON.parse(saved.attention)] : state.attention = [];
                (state.dataSave.history) ? state.history = [...state.history, ...JSON.parse(saved.history)] : state.history = [];
                (state.dataSave.training) ? state.training = [...state.training, ...JSON.parse(saved.training)] : state.training = [];
                (state.dataSave.threat) ? state.threat = [...state.threat, ...JSON.parse(saved.threat)] : state.threat = [];
                (state.dataSave.physically) ? state.physically = [...state.physically, ...JSON.parse(saved.physically)] : state.physically = [];
                (state.dataSave.mentally) ? state.mentally = [...state.mentally, ...JSON.parse(saved.mentally)] : state.mentally = [];
                (state.dataSave.emotionally) ? state.emotionally = [...state.emotionally, ...JSON.parse(saved.emotionally)] : state.emotionally = [];
                (state.dataSave.people) ? state.people = [...state.people, ...JSON.parse(saved.people)] : state.people = [];
                (state.dataSave.environment) ? state.environment = [...state.environment, ...JSON.parse(saved.environment)] : state.environment = [];
                (state.dataSave.asset) ? state.asset = [...state.asset, ...JSON.parse(saved.asset)] : state.asset = [];
                (state.dataSave.reputation) ? state.reputation = [...state.reputation, ...JSON.parse(saved.reputation)] : state.reputation = [];
                // rating
                (state.dataSave.knowledge) ? state.knowledge = [...state.knowledge, ...JSON.parse(saved.knowledge)] : state.knowledge = [];
                (state.dataSave.action) ? state.action = [...state.action, ...JSON.parse(saved.action)] : state.action = [];
                (state.dataSave.behavior) ? state.behavior = [...state.behavior, ...JSON.parse(saved.behavior)] : state.behavior = [];
                (state.dataSave.reference) ? state.reference = [...state.reference, ...JSON.parse(saved.reference)] : state.reference = [];
            }
            // console.log(payload);
            // state.hazardList = [...state.hazardList, ...payload];
        },
        setAttention: (state, {payload}) => {
            const [id, attention] = payload;
            (state.attention.some(obj => Object.keys(obj).includes(`new${id}`)))
            ? state.attention = [...state.attention.filter(obj => Object.keys(obj) != `new${id}`), {[`new${id}`]: attention}]
            : state.attention = [...state.attention, {[`new${id}`]: attention}]
            // state.attention = [...state.attention.filter(obj => Object.keys(obj) != 'new'), ...payload];
            // const [id, attention] = payload;
            // state.attention = [...state.attention.filter(obj => Object.keys(obj) != id), {[id]: attention}];
        },
        setHistory: (state, {payload}) => {
            const [id, history] = payload;
            (state.history.some(obj => Object.keys(obj).includes(`new${id}`)))
            ? state.history = [...state.history.filter(obj => Object.keys(obj) != `new${id}`), {[`new${id}`]: history}]
            : state.history = [...state.history, {[`new${id}`]: history}]
            // state.history = payload;
            // const [id, history] = payload;
            // state.history = [...state.history.filter(obj => Object.keys(obj) != id), {[id]: history}];
        },
        setTraining: (state, {payload}) => {
            const [id, training] = payload;
            (state.training.some(obj => Object.keys(obj).includes(`new${id}`)))
            ? state.training = [...state.training.filter(obj => Object.keys(obj) != `new${id}`), {[`new${id}`]: training}]
            : state.training = [...state.training, {[`new${id}`]: training}]
            // state.training = payload;
            // const [id, traning] = payload;
            // state.traning = [...state.traning.filter(obj => Object.keys(obj) != id), {[id]: traning}];
        },
        setThreat: (state, {payload}) => {
            const [id, threat] = payload;
            state.threat = [...state.threat.filter(obj => Object.keys(obj) != id), {[id]: threat}];
        },
        setNewThreat: (state, {payload}) => {
            console.log(payload)
            const [idUl, idLi, threat] = payload;
            console.log(idUl);
            console.log(idLi);
            // (state.threat.some((object) => Object.keys(object).some(item => item == `new${idUl}`)))
            (state.threat.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.threat = 
                    [...state.threat.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.threat.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.threat.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = threat : elem)]
                                : 
                                // 2
                                [...Object.values(...state.threat.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), threat]
                        }
                    ]
                :
                // state.threat = [...state.threat, {[`new${idUl}`]: [threat]}]
                state.threat = [...state.threat, {[`new${idUl}`] : [threat]}]
            // state.threat = [...state.threat.filter(obj => Object.keys(obj) != `new${idUl}`), {[`new${idUl}`]: [threat]}]
        },
        setPhysically: (state, {payload}) => {
            console.log(payload)
            const [id, physically] = payload
            state.physically = [...state.physically.filter(obj => Object.keys(obj) != id), {[id]: physically}]
        },
        setNewPhysically: (state, {payload}) => {
            const [idUl, idLi, physically] = payload;
            (state.physically.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.physically = 
                    [...state.physically.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.physically.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.physically.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = physically : elem)]
                                : 
                                // 2
                                [...Object.values(...state.physically.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), physically]
                        }
                    ]
                :
                state.physically = [...state.physically, {[`new${idUl}`] : [physically]}]
            // state.physically = [...state.physically.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setMentally: (state, {payload}) => {
            const [id, mentally] = payload
            state.mentally = [...state.mentally.filter(obj => Object.keys(obj) != id), {[id]: mentally}]
        },
        setNewMentally: (state, {payload}) => {
            const [idUl, idLi, mentally] = payload;
            (state.mentally.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.mentally = 
                    [...state.mentally.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.mentally.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.mentally.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = mentally : elem)]
                                : 
                                // 2
                                [...Object.values(...state.mentally.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), mentally]
                        }
                    ]
                :
                state.mentally = [...state.mentally, {[`new${idUl}`] : [mentally]}]
            // state.mentally = [...state.mentally.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setEmotionally: (state, {payload}) => {
            const [id, emotionally] = payload
            state.emotionally = [...state.emotionally.filter(obj => Object.keys(obj) != id), {[id]: emotionally}]
        },
        setNewEmotionally: (state, {payload}) => {
            const [idUl, idLi, emotionally] = payload;
            (state.emotionally.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.emotionally = 
                    [...state.emotionally.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.emotionally.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.emotionally.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = emotionally : elem)]
                                : 
                                // 2
                                [...Object.values(...state.emotionally.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), emotionally]
                        }
                    ]
                :
                state.emotionally = [...state.emotionally, {[`new${idUl}`] : [emotionally]}]
            // state.emotionally = [...state.emotionally.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setPeople: (state, {payload}) => {
            const [id, people] = payload
            state.people = [...state.people.filter(obj => Object.keys(obj) != id), {[id]: people}]
        },
        setNewPeople: (state, {payload}) => {
            const [idUl, idLi, people] = payload;
            (state.people.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.people = 
                    [...state.people.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.people.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.people.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = people : elem)]
                                : 
                                // 2
                                [...Object.values(...state.people.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), people]
                        }
                    ]
                :
                state.people = [...state.people, {[`new${idUl}`] : [people]}]
            // state.people = [...state.people.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setEnvironment: (state, {payload}) => {
            const [id, environment] = payload
            state.environment = [...state.environment.filter(obj => Object.keys(obj) != id), {[id]: environment}]
        },
        setNewEnvironment: (state, {payload}) => {
            const [idUl, idLi, environment] = payload;
            (state.environment.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.environment = 
                    [...state.environment.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.environment.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.environment.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = environment : elem)]
                                : 
                                // 2
                                [...Object.values(...state.environment.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), environment]
                        }
                    ]
                :
                state.environment = [...state.environment, {[`new${idUl}`] : [environment]}]
            // state.environment = [...state.environment.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setAsset: (state, {payload}) => {
            const [id, asset] = payload
            state.asset = [...state.asset.filter(obj => Object.keys(obj) != id), {[id]: asset}]
        },
        setNewAsset: (state, {payload}) => {
            const [idUl, idLi, asset] = payload;
            (state.asset.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.asset = 
                    [...state.asset.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.asset.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.asset.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = asset : elem)]
                                : 
                                // 2
                                [...Object.values(...state.asset.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), asset]
                        }
                    ]
                :
                state.asset = [...state.asset, {[`new${idUl}`] : [asset]}]
            // state.asset = [...state.asset.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setReputation: (state, {payload}) => {
            const [id, reputation] = payload
            state.reputation = [...state.reputation.filter(obj => Object.keys(obj) != id), {[id]: reputation}]
        },
        setNewReputation: (state, {payload}) => {
            const [idUl, idLi, reputation] = payload;
            (state.reputation.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.reputation = 
                    [...state.reputation.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.reputation.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.reputation.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = reputation : elem)]
                                : 
                                // 2
                                [...Object.values(...state.reputation.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), reputation]
                        }
                    ]
                :
                state.reputation = [...state.reputation, {[`new${idUl}`] : [reputation]}]
            // state.reputation = [...state.reputation.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setLikelihood: (state, {payload}) => {
            state.likelihood = payload;
        },
        setSeverity: (state, {payload}) => {
            state.severity = payload;
        },
        setDanger: (state, {payload}) => {
            state.danger = payload;
        },
        setKnowledge: (state, {payload}) => {
            const [id, knowledge] = payload
            state.knowledge = [...state.knowledge.filter(obj => Object.keys(obj) != id), {[id]: knowledge}]
        },
        setNewKnowledge: (state, {payload}) => {
            const [idUl, idLi, knowledge] = payload;
            (state.knowledge.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.knowledge = 
                    [...state.knowledge.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.knowledge.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.knowledge.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = knowledge : elem)]
                                : 
                                // 2
                                [...Object.values(...state.knowledge.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), knowledge]
                        }
                    ]
                :
                state.knowledge = [...state.knowledge, {[`new${idUl}`] : [knowledge]}]
            // state.knowledge = [...state.knowledge.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setAction: (state, {payload}) => {
            const [id, action] = payload
            state.action = [...state.action.filter(obj => Object.keys(obj) != id), {[id]: action}]
        },
        setNewAction: (state, {payload}) => {
            const [idUl, idLi, action] = payload;
            (state.action.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.action = 
                    [...state.action.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.action.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.action.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = action : elem)]
                                : 
                                // 2
                                [...Object.values(...state.action.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), action]
                        }
                    ]
                :
                state.action = [...state.action, {[`new${idUl}`] : [action]}]
            // state.action = [...state.reputation.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setBehavior: (state, {payload}) => {
            const [id, behavior] = payload
            state.behavior = [...state.behavior.filter(obj => Object.keys(obj) != id), {[id]: behavior}]
        },
        setNewBehavior: (state, {payload}) => {
            const [idUl, idLi, behavior] = payload;
            (state.behavior.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.behavior = 
                    [...state.behavior.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.behavior.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.behavior.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = behavior : elem)]
                                : 
                                // 2
                                [...Object.values(...state.behavior.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), behavior]
                        }
                    ]
                :
                state.behavior = [...state.behavior, {[`new${idUl}`] : [behavior]}]
            // state.behavior = [...state.behavior.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setReference: (state, {payload}) => {
            const [id, reference] = payload
            state.reference = [...state.reference.filter(obj => Object.keys(obj) != id), {[id]: reference}]
        },
        setNewReference: (state, {payload}) => {
            const [idUl, idLi, reference] = payload;
            (state.reference.some(obj => Object.keys(obj).includes(`new${idUl}`)))
                ?
                state.reference = 
                    [...state.reference.filter(obj => Object.keys(obj) != `new${idUl}`), 
                        {[`new${idUl}`]: 
                            (Object.values(state.reference.find(obj => Object.keys(obj) == `new${idUl}`)).flat().some((elem, index) => index == idLi)) 
                                ? 
                                // 1
                                [...Object.values(state.reference.find(obj => Object.keys(obj) == `new${idUl}`)).flat().map((elem, index) => (index == idLi) ? elem = reference : elem)]
                                : 
                                // 2
                                [...Object.values(...state.reference.filter(obj => Object.keys(obj) == `new${idUl}`)).flat(), reference]
                        }
                    ]
                :
                state.reference = [...state.reference, {[`new${idUl}`] : [reference]}]
            // state.reference = [...state.reference.filter(obj => Object.keys(obj) != 'new'), {'new': payload}]
        },
        setRegistrar: (state, {payload}) => {
            state.registrar = payload;
        },
	},
});

export const {
    resetRiskSlice,
    setERROR,
    setDataRisks,
    setData2Risks,
    setDataCheck,
    setDataSave,
    setHazardList,
    setNewHazardList,
    setSavedHazardList,
    unsetHazardList,
    setAttention,
    setHistory,
    setTraining,
    setThreat,
    setNewThreat,
    setPhysically,
    setNewPhysically,
    setMentally,
    setNewMentally,
    setEmotionally,
    setNewEmotionally,
    setPeople,
    setNewPeople,
    setEnvironment,
    setNewEnvironment,
    setAsset,
    setNewAsset,
    setReputation,
    setNewReputation,
    setLikelihood,
    setSeverity,
    setDanger,
    setKnowledge,
    setNewKnowledge,
    setAction,
    setNewAction,
    setBehavior,
    setNewBehavior,
    setReference,
    setNewReference,
    setRegistrar,
} = riskSlice.actions;

export default riskSlice.reducer;