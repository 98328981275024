import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setBehavior, setNewBehavior } from "../../slices/riskSlice";

const TableCellBehaviorNew = ({item, addNew}) => {

    const dispatch = useDispatch();
    const [behavior2, setStateBehavior2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdbehavior, setCheckedIdbehavior] = useState("");

	const [addBehavior2, setAddBehavior2] = useState(false);
    const [behavior, setStateBehavior] = useState([]);
    const [behaviorArray, setStateBehaviorArray] = useState([]);
    
    const [behaviorArrayUl, setStateBehaviorArrayUl] = useState([]);

    return (
        <>
            {behaviorArray.map((element, index) => 
                <>
                    {element
                        &&
                        <div className={`hovers ${index}`}>
                            <ul style={{padding: '0', margin: '0'}}>
                                {
                                    // console.log(behaviorArrayUl)
                                    behaviorArrayUl.map((item, indx) =>
                                        (indx == index) && item.map((i, ind) =>
                                        i &&
                                        <li class="autofilss">
                                            <input type="text" className="autofilssInput" onChange={(e) => dispatch(setNewBehavior([index.toString(), ind, e.target.value ]))}></input>
                                        </li>
                                    ))
                                }
                                <div className="autofilss">
                                    <button onClick={(e) => {e.preventDefault(); setStateBehaviorArrayUl(behaviorArrayUl.map((item, ind) => (ind == index) ? item = [...item, true] : item));}}>+ Add</button>
                                </div>  
                            </ul>
                        </div>   
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStateBehaviorArray([...behaviorArray, true]); setStateBehaviorArrayUl([...behaviorArrayUl, [true]])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellBehaviorNew;