import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserId, setToken, setisLogin, setisAdmin } from '../slices/userSlise';
import { setDataRisks, setData2Risks, handleSubmit, handleSave} from "../slices/riskSlice";
import Main from './Main';
import { Modal, Spin, Button, Popover} from "antd";
import '../App.css';
import Risk2Service from '../services/risk2-services';
import RiskService from '../services/risk-services';

const Templates = () => {
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
	const dispatch = useDispatch();
	const storedAuthToken = localStorage.getItem("authToken");
	const [data, setData] = useState({risks: []});
	const [data2, setData2] = useState({risks: []});
	const { user } = useSelector((state) => state);
	const navigate = useNavigate();
	const [openModal , setOpenModal] = useState(false);
	const [openModalApprove , setOpenModalApprove] = useState(false);
	const [openModalDecline , setOpenModalDecline] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
  	const [error, setError] = useState(null);
	const [addHazardsList, setAddHazardsList] = useState(false);
	const [addImportant, setAddImportant] = useState(false);
	const [addHistory, setAddHistory] = useState(false);
	const [addTraining, setAddTraining] = useState(false);
	const [addThreat, setAddThreat] = useState(false);
	const [addPhysically, setAddPhysically] = useState(false);
	const [addMentally, setAddMentally] = useState(false);
	const [addEmotionally, setAddEmotionally] = useState(false);
	const [addPeople, setAddPeople] = useState(false);
	const [addEnvironment, setAddEnvironment] = useState(false);
	const [addAsset, setAddAsset] = useState(false);
	const [addReputation, setAddReputation] = useState(false);
	const [addKnowledge, setAddKnowledge] = useState(false);
	const [addAction, setAddAction] = useState(false);
	const [addBehavior, setAddBehavior] = useState(false);
	const [addReference, setAddReference] = useState(false);
	const [addImportant2, setAddImportant2] = useState(false);
	const [addHistory2, setAddHistory2] = useState(false);
	const [addTraining2, setAddTraining2] = useState(false);
	const [addThreat2, setAddThreat2] = useState(false);
	const [addPhysically2, setAddPhysically2] = useState(false);
	const [addMentally2, setAddMentally2] = useState(false);
	const [addEmotionally2, setAddEmotionally2] = useState(false);
	const [addPeople2, setAddPeople2] = useState(false);
	const [addEnvironment2, setAddEnvironment2] = useState(false);
	const [addAsset2, setAddAsset2] = useState(false);
	const [addReputation2, setAddReputation2] = useState(false);
	const [addKnowledge2, setAddKnowledge2] = useState(false);
	const [addAction2, setAddAction2] = useState(false);
	const [addBehavior2, setAddBehavior2] = useState(false);
	const [addReference2, setAddReference2] = useState(false);
	const [addRegistrar, setAddRegistrar] = useState(false);
	const [addRegistrar2, setAddRegistrar2] = useState(false);
	const [addReviewer, setAddReviewer] = useState(false);
	const [addApprover, setAddApprover] = useState(false);
	const [addStatusR, setAddStatusR] = useState(false);
	const [addLikelihood, setAddLikelihood] = useState(false);
	const [addseverity, setAddSeverity] = useState(false);
	const [addDangerLevel, setAddDangerLevel] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checkedItem, setCheckedItem] = useState(null);
	const [checkedItemId, setCheckedItemId] = useState(null);
	const [checkedId, setCheckedId] = useState("");
	const [checkedDate, setCheckedDate] = useState("");
	const [checkedRegistrar, setCheckedRegistrar] = useState("");
	const [checkedhazardsList, setCheckedhazardsList] = useState(false);
	const [checkedIdhazardsList, setCheckedIdhazardsList] = useState("");
	const [checkedthreat, setCheckedthreat] = useState(false);
	const [checkedIdthreat, setCheckedIdthreat] = useState("");
	const [checkedphysically, setCheckedphysically] = useState(false);
	const [checkedIdphysically, setCheckedIdphysically] = useState("");
	const [checkedmentally, setCheckedmentally] = useState(false);
	const [checkedIdmentally, setCheckedIdmentally] = useState("");
	const [checkedemotionally, setCheckedemotionally] = useState(false);
	const [checkedIdemotionally, setCheckedIdemotionally] = useState("");
	const [checkedpeople, setCheckedpeople] = useState(false);
	const [checkedIdpeople, setCheckedIdpeople] = useState("");
	const [checkedenvironment, setCheckedenvironment] = useState(false);
	const [checkedIdenvironment, setCheckedIdenvironment] = useState("");
	const [checkedasset, setCheckedasset] = useState(false);
	const [checkedIdasset, setCheckedIdasset] = useState("");
	const [checkedreputation, setCheckedreputation] = useState(false);
	const [checkedIdreputation, setCheckedIdreputation] = useState("");
	const [checkedknowledge, setCheckedknowledge] = useState(false);
	const [checkedIdknowledge, setCheckedIdknowledge] = useState("");
	const [checkedaction, setCheckedaction] = useState(false);
	const [checkedIdaction, setCheckedIdaction] = useState("");
	const [checkedbehavior, setCheckedbehavior] = useState(false);
	const [checkedIdbehavior, setCheckedIdbehavior] = useState("");
	const [checkedreference, setCheckedreference] = useState(false);
	const [checkedIdreference, setCheckedIdreference] = useState("");
	const [checkedIdregistrar, setCheckedIdregistrar] = useState("");
	const [hazardsList, setHazardsList] = useState("");
	const [attention, setAttention] = useState("");
	const [history, setHistory] = useState("");
	const [training, setTraining] = useState("");
	const [threat, setThreat] = useState("");
	const [physically, setPhysically] = useState("");
	const [mentally, setMentally] = useState("");
	const [emotionally, setEmotionally] = useState("");
	const [people, setPeople] = useState("");
	const [environment, setEnvironment] = useState("");
	const [asset, setAsset] = useState("");
	const [reputation, setReputation] = useState("");
	const [knowledge, setKnowledge] = useState("");
	const [action, setAction] = useState("");
	const [behavior, setBehavior] = useState("");
	const [reference, setReference] = useState("");
	const [attention2, setAttention2] = useState("");
	const [history2, setHistory2] = useState("");
	const [training2, setTraining2] = useState("");
	const [threat2, setThreat2] = useState("");
	const [physically2, setPhysically2] = useState("");
	const [mentally2, setMentally2] = useState("");
	const [emotionally2, setEmotionally2] = useState("");
	const [people2, setPeople2] = useState("");
	const [environment2, setEnvironment2] = useState("");
	const [asset2, setAsset2] = useState("");
	const [reputation2, setReputation2] = useState("");
	const [knowledge2, setKnowledge2] = useState("");
	const [action2, setAction2] = useState("");
	const [behavior2, setBehavior2] = useState("");
	const [reference2, setReference2] = useState("");
	const [registrar, setRegistrar] = useState("");
	const [registrar2, setRegistrar2] = useState("");
	const [reason, setReason] = useState("");
	const [reviewer, setReviewer] = useState("");
	const [approver, setApprover] = useState("");
	const [statusR, setStatusR] = useState("");
	const [likelihood, setLikelihood] = useState("");
	const [severity, setSeverity] = useState("");
	const [dangerLevel, setDangerLevel] = useState("");
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedLabel, setSelectedLabel] = useState("0");
	const [selectedLabels, setSelectedLabels] = useState([]);
	const [selectedLabelsStatus, setSelectedLabelsStatus] = useState([]);
	const [selectedItemSeverity, setSelectedItemSeverity] = useState(null);
	const [selectedLabelSeverity, setSelectedLabelSeverity] = useState("0");
	const [selectedLabelsSeverity, setSelectedLabelsSeverity] = useState([]);
	const [selectedLabelResult, setSelectedLabelResult] = useState("0");
	const [selectedLabelsResult, setSelectedLabelsResult] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [openModalSave , setOpenModalSave] = useState(false);

	const dataCheck = useSelector(({risk}) => risk.dataCheck)
	console.log(dataCheck)

	const handleResult = (selectedLabel, selectedLabelSeverity) => {
		let updatedSelectedLabels = [];
		let label = 0;
		let result = 0;
		if (selectedItemSeverity === selectedItem) {
			result = Number(selectedLabel) * Number(selectedLabelSeverity);
			if(result >= 0 && result < 6) {
				label = 1;
			} else if (result >= 6 && result < 11) {
				label = 2;
			} else if (result >= 11 && result < 16) {
				label = 3;
			} else if (result >= 16 && result < 26) {
				label = 4;
			}
		  } else {
			return;
		  }
	
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  default:
			break;
		}
		setSelectedLabelResult(label);
		setSelectedLabelsResult(updatedSelectedLabels);
	};
	  const handleLabelClickSeverity = (item, label) => {
		let updatedSelectedLabels = [];
		if (item === selectedItemSeverity) {
			//setSelectedLabel(null); // Сбрасываем выбранный элемент, если он уже выбран
		  } else {
			setSelectedItemSeverity(item); // Устанавливаем выбранный элемент
		  }
	
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
		setSelectedLabelSeverity(label);
		setSelectedLabelsSeverity(updatedSelectedLabels);
		handleResult(selectedLabel, selectedLabelSeverity);
	};

	const handleLabelClick = (item, label) => {
	let updatedSelectedLabels = [];
	if (item === selectedItem) {
		//setSelectedLabel(null); // Сбрасываем выбранный элемент, если он уже выбран
		} else {
		setSelectedItem(item); // Устанавливаем выбранный элемент
		}

	switch (label) {
		case 1:
		updatedSelectedLabels = [1];
		break;
		case 2:
		updatedSelectedLabels = [1, 2];
		break;
		case 3:
		updatedSelectedLabels = [1, 2, 3];
		break;
		case 4:
		updatedSelectedLabels = [1, 2, 3, 4];
		break;
		case 5:
		updatedSelectedLabels = [1, 2, 3, 4, 5];
		break;
		default:
		break;
	}
	setSelectedLabel(label);
	setSelectedLabels(updatedSelectedLabels);
	handleResult(selectedLabel, selectedLabelSeverity);
	};
	const handleLabelClickStatus = (item, label) => {
	let updatedSelectedLabels = [];
	if (item === selectedItem) {
		//setSelectedLabel(null); // Сбрасываем выбранный элемент, если он уже выбран
		} else {
		setSelectedItem(item); // Устанавливаем выбранный элемент
		}

	switch (label) {
		case 1:
		updatedSelectedLabels = [1];
		break;
		case 2:
		updatedSelectedLabels = [1, 2];
		break;
		case 3:
		updatedSelectedLabels = [1, 2, 3];
		break;
		case 4:
		updatedSelectedLabels = [1, 2, 3, 4];
		break;
		case 5:
		updatedSelectedLabels = [1, 2, 3, 4, 5];
		break;
		default:
		break;
	}
	setSelectedLabel(label);
	setSelectedLabelsStatus(updatedSelectedLabels);
	
	};
	
	const closeModal = () => {
		setOpenModal(false);
	}
	const closeModalApprove = () => {
		setOpenModalApprove(false);
	}
	const closeModalDecline = () => {
		setOpenModalDecline(false);
	}
	const closeModalSave = () => {
		setOpenModalSave(false);
		navigate('/myrisk');
	}
	// useEffect(() => {
	// 	if(storedAuthToken && user.isLogin){
	// 		async function getRisks(){
	// 			const risks = await RiskService.getAllRisks()
	// 			setData({risks: risks});
	// 			dispatch(setDataRisks({risks: risks}));

	// 		}
	// 		getRisks()
	// 	}

	// }, [user.isLogin, storedAuthToken])

	// useEffect(() => {
	// 	if(storedAuthToken && user.isLogin){
	// 		async function getRisks2(){
	// 			const risks2 = await Risk2Service.getAllRisks2()
	// 			setData2({risks: risks2});
	// 			dispatch(setData2Risks({risks: risks2}));
	// 		}
	// 		getRisks2()
	// 	}

	// }, [user.isLogin, storedAuthToken])

	// useEffect(() => {
	// 	async function fetchData() {
	// 		try {
	// 			const response = await axios.get(`http://${host}:3001/risktable2/`);
	// 			const data = response.data
	// 			console.log(data)
	// 			setData(data);
	// 		} catch (err) {
	// 			setError(err);
	// 		} finally {
	// 			setIsLoading(false);
	// 		}
	// 	}
	// 	async function fetchData2() {
	// 		try {
	// 		const response = await axios.get(`http://${host}:3001/risktable/`);
	// 		const data2 = response.data
	// 		console.log(data2)
	// 		setData2(data2);
	// 		} catch (err) {
	// 		setError(err);
	// 		} finally {
	// 		setIsLoading(false);
	// 		}
	//   	}
	// 	fetchData();
	// 	fetchData2();
  	// }, [checked, !checked]);
	const handleOnSubmit = async (e) => {
		e.preventDefault();
		let result = {};
		if (checkedItem){
			console.log("2", checkedItem)
		result = await fetch(
			`http://${host}:3001/risktable2/`, {
			method: "post",
			body: JSON.stringify({ 
			userid: user.currentUserId,
			itemid: checkedItem.id,
			category: user.task,
			hazardsList: checkedItem.hazardsList,
			attention: [...checkedItem.attention, attention2], 
			history: [...checkedItem.history, history2], 
			training: [...checkedItem.training, training2], 
			threat: [...checkedItem.threat, threat2], 
			physically: [...checkedItem.physically, physically2], 
			mentally: [...checkedItem.mentally, mentally2], 
			emotionally: [...checkedItem.emotionally, emotionally2], 
			people: [...checkedItem.people, people2], 
			environment: [...checkedItem.environment, environment2], 
			asset: [...checkedItem.asset, asset2], 
			reputation: [...checkedItem.reputation, reputation2], 
			knowledge: [...checkedItem.knowledge, knowledge2], 
			action: [...checkedItem.action, action2], 
			behavior: [...checkedItem.behavior, behavior2],
			reference: [...checkedItem.reference, reference2],
			registrar: user.name,
			reviewer: checkedItem.reviewer,
			approver: checkedItem.approver,
			statusR: checkedItem.statusR,
			likelihood: checkedItem.likelihood,
			severity: checkedItem.severity,
			dangerLevel: checkedItem.dangerLevel,
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
	} else {
		console.log("1")
		result = await fetch(
			`http://${host}:3001/risktable2/`, {
			method: "post",
			body: JSON.stringify({ 
			userid: user.currentUserId,
			itemid: null,
			category: user.task,
			hazardsList,
			attention: [attention], 
			history: [history], 
			training: [training], 
			threat: [threat], 
			physically: [physically], 
			mentally: [mentally], 
			emotionally: [emotionally], 
			people: [people], 
			environment: [environment], 
			asset: [asset], 
			reputation: [reputation], 
			knowledge: [knowledge], 
			action: [action], 
			behavior: [behavior],
			reference: [reference],
			registrar: user.name,
			reviewer,
			approver,
			statusR,
			likelihood,
			severity,
			dangerLevel,
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})};
		result = await result.json();
		console.log("result", result)
		if (result) {
			setOpenModal(true);
			
		}
	}
	const handleOnSave = async (e) => {
		e.preventDefault();
		const save = true;
		dispatch(handleSave(dataCheck.id));
		setOpenModalSave(true);
	// 	let result = {};
	// 	if (checkedItem){
	// 		console.log("2", checkedItem)
	// 	result = await fetch(
	// 		`http://${host}:3001/risktable2/`, {
	// 		method: "post",
	// 		body: JSON.stringify({ 
	// 		userid: userCurrentUserId,
	// 		itemid: checkedItem.id,
	// 		category: userTask,
	// 		hazardsList: checkedItem.hazardsList,
	// 		attention: [...checkedItem.attention, attention2].map((item) => (attention.includes(item) || item == attention2)  && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		history: [...checkedItem.history, history2].map((item) => (history.includes(item) || item == history2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		training: [...checkedItem.training, training2].map((item) => (training.includes(item) || item == training2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		threat: [...checkedItem.threat, threat2].map((item) => (threat.includes(item) || item == threat2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		physically: [...checkedItem.physically, physically2].map((item) => (physically.includes(item) || item == physically2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		mentally: [...checkedItem.mentally, mentally2].map((item) => (mentally.includes(item) || item == mentally2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		emotionally: [...checkedItem.emotionally, emotionally2].map((item) => (emotionally.includes(item) || item == emotionally2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		people: [...checkedItem.people, people2].map((item) => (people.includes(item) || item == people2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		environment: [...checkedItem.environment, environment2].map((item) => (environment.includes(item) || item == environment2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		asset: [...checkedItem.asset, asset2].map((item) => (asset.includes(item) || item == asset2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		reputation: [...checkedItem.reputation, reputation2].map((item) => (reputation.includes(item) || item == reputation2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		knowledge: [...checkedItem.knowledge, knowledge2].map((item) => (knowledge.includes(item) || item == knowledge2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		action: [...checkedItem.action, action2].map((item) => (action.includes(item) || item == action2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		behavior: [...checkedItem.behavior, behavior2].map((item) => (behavior.includes(item) || item == behavior2) && item != "" ? {item, checked: true} : {item, checked: false}),
	// 		reference: [...checkedItem.reference, reference2].map((item) => (reference.includes(item) || item == reference2) && item != "" ? {item, checked: true} : {item, checked: false}),
	// 		registrar: userName,
	// 		reviewer: checkedItem.reviewer,
	// 		approver: checkedItem.approver,
	// 		statusR: checkedItem.statusR,
	// 		likelihood: likelihood,
	// 		severity: severity,
	// 		dangerLevel: dangerLevel,
	// 		saved: true,
	// 		 }),
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		}
	// 	})
	// } else {
	// 	console.log("1")
	// 	result = await fetch(
	// 		`http://${host}:3001/risktable2/`, {
	// 		method: "post",
	// 		body: JSON.stringify({ 
	// 		userid: userCurrentUserId,
	// 		itemid: null,
	// 		category: userTask,
	// 		hazardsList,
	// 		attention: [{item: attention, checked: true}], 
	// 		history: [{item: history, checked: true}], 
	// 		training: [{item: training, checked: true}], 
	// 		threat: [{item: threat, checked: true}], 
	// 		physically: [{item: physically, checked: true}], 
	// 		mentally: [{item: mentally, checked: true}], 
	// 		emotionally: [{item: emotionally, checked: true}], 
	// 		people: [{item: people, checked: true}], 
	// 		environment: [{item: environment, checked: true}], 
	// 		asset: [{item: asset, checked: true}], 
	// 		reputation: [{item: reputation, checked: true}], 
	// 		knowledge: [{item: knowledge, checked: true}], 
	// 		action: [{item: action, checked: true}], 
	// 		behavior: [{item: behavior, checked: true}],
	// 		reference: [{item: reference, checked: true}],
	// 		registrar: userName,
	// 		reviewer,
	// 		approver,
	// 		statusR,
	// 		likelihood,
	// 		severity,
	// 		dangerLevel,
	// 		saved: true,
	// 		 }),
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		}
	// 	})};
	// 	result = await result.json();
	// 	console.log("result", result)
	// 	if (result) {
	// 		setOpenModalSave(true);
			
	// 	}
	}
	const handleOnSubmitApprove = async (e, id) => {

		e.preventDefault();
		let result = await fetch(
		`http://${host}:3001/risktable/`, {
			method: "post",
			body: JSON.stringify({ 
			userid: checkedItem.userid,
			category: user.task,
			hazardsList: checkedItem.hazardsList,
			attention: checkedItem.attention, 
			history: checkedItem.history, 
			training: checkedItem.training, 
			threat: checkedItem.threat, 
			physically: checkedItem.physically, 
			mentally: checkedItem.mentally, 
			emotionally: checkedItem.emotionally, 
			people: checkedItem.people, 
			environment: checkedItem.environment, 
			asset: checkedItem.asset, 
			reputation: checkedItem.reputation, 
			knowledge: checkedItem.knowledge, 
			action: checkedItem.action, 
			behavior: checkedItem.behavior,
			reference: checkedItem.reference,
			registrar: checkedItem.registrar,
			reviewer,
			approver,
			statusR,
			likelihood,
			severity,
			dangerLevel,
			approve: true,
			decline: false,
			reason: reason,
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		let result2 = await fetch(
			`http://${host}:3001/risktable2/${id}`, {
				method: "put",
				body: JSON.stringify({ 
				userid: checkedItem.userid,
				itemid: checkedItem.id,
				category: user.task,
				hazardsList: checkedItem.hazardsList,
				attention: checkedItem.attention, 
				history: checkedItem.history, 
				training: checkedItem.training, 
				threat: checkedItem.threat, 
				physically: checkedItem.physically, 
				mentally: checkedItem.mentally, 
				emotionally: checkedItem.emotionally, 
				people: checkedItem.people, 
				environment: checkedItem.environment, 
				asset: checkedItem.asset, 
				reputation: checkedItem.reputation, 
				knowledge: checkedItem.knowledge, 
				action: checkedItem.action, 
				behavior: checkedItem.behavior,
				reference: checkedItem.reference,
				registrar: checkedItem.registrar,
				reviewer,
				approver,
				statusR,
				likelihood,
				severity,
				dangerLevel,
				approve: true,
				decline: false,
				reason: reason,
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			})
		result = await result.json();
		console.log("result", result)
		if (result) {
			setOpenModalApprove(true);
			setChecked(false);	
		}
		result2 = await result2.json();
		console.log("result2", result2)
		if (result2) {
			setOpenModalApprove(true);
			setChecked(false);	
		}
	}
	const handleOnSubmitDecline = async (e, id) => {

		e.preventDefault();
		let result = await fetch(
		`http://${host}:3001/risktable2/${id}`, {
			method: "put",
			body: JSON.stringify({ 
			userid: checkedItem.userid,
			itemid: checkedItem.id,
			category: user.task,
			hazardsList: checkedItem.hazardsList,
			attention: checkedItem.attention, 
			history: checkedItem.history, 
			training: checkedItem.training, 
			threat: checkedItem.threat, 
			physically: checkedItem.physically, 
			mentally: checkedItem.mentally, 
			emotionally: checkedItem.emotionally, 
			people: checkedItem.people, 
			environment: checkedItem.environment, 
			asset: checkedItem.asset, 
			reputation: checkedItem.reputation, 
			knowledge: checkedItem.knowledge, 
			action: checkedItem.action, 
			behavior: checkedItem.behavior,
			reference: checkedItem.reference,
			registrar: checkedItem.registrar,
			reviewer,
			approver,
			statusR,
			likelihood,
			severity,
			dangerLevel,
			approve: false,
			decline: true,
			reason: reason,
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		result = await result.json();
		console.log("result", result)
		if (result) {
			setOpenModalDecline(true);
			setChecked(false);		
		}
	}
	
	const dateTask = new Date(dataCheck.createdAt);
	console.log(dateTask.getFullYear())
	console.log(dateTask.getMonth() + 1)
	console.log(dateTask.getDate())
	console.log(dateTask.getHours())
	console.log(dateTask.getMinutes())
	console.log(dateTask)
	return (
		<>{data ? 
		<div>
		{storedAuthToken ? 
		<main>
			<div class="container">
				<div class="hazards">
					<div class="hazards-head">
						<div class="hazards-head-l">
							<div class="hazards-head-title">HAZARD & RISK ASSESMENT TABLE </div>
							<p>{user.name}</p>
							<p>{dataCheck.category}</p>
						</div>
						<div class="hazards-head-r">
							<p>{String(dateTask.getFullYear())}-{String(dateTask.getMonth()+1).padStart(2, '0')}-{String(dateTask.getDate()).padStart(2, '0')}</p>
							<p>{String(dateTask.getHours()).padStart(2, '0')}:{String(dateTask.getMinutes()).padStart(2, '0')}</p>
							<p>{user.location}</p>
						</div>
					</div>
					<div class="hazardsbody">
					
					<form className="preform1" method="put">
						<div className="footsubm-head scrollbar-dynamic">
							<div className="footsubm-headin">
								<div className="footsubmitem">
									<div className="footsubmitem-title nonetitle"></div>
									<div className="footsubmitem-bottitle">
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/HAZARDLIST.svg"/><span>HAZARD LIST</span></div>
											<div className="listbu">
												{JSON.parse(dataCheck.hazardsList).map(obj => Object.values(obj)).map((item, index) => (
														<>
															<div className="autofilss" key={item.id}>
																<input type="checkbox" defaultChecked={true}/><span onClick={(e) => {e.preventDefault(); setChecked(checked ? false : true); setCheckedId(!checked ? item.id : ""); setCheckedItem(!checked ? item : {}); setCheckedDate(!checked ? item.createdAt.slice(0, 10) : ""); setCheckedRegistrar(!checked ? item.registrar : "")}}></span>
																<Popover content={item}><div className="truncate" style={{width: "300px"}}>{item}</div></Popover>
																<div>
																	<Popover content={JSON.parse(dataCheck.attention)[index] != "" ? JSON.parse(dataCheck.attention).map(obj => Object.values(obj)).filter((item, i) => (i == index)) : <div className="autofilss">
																		{/*addImportant ? 
																		<input type="text" className="autofilssInput" style={{color: "#000"}} value={attention} onChange={(e) => setAttention(e.target.value)}></input>
																		:
																			<button onClick={(e) => {e.preventDefault(); setAddImportant(true)}}>+ Add</button>
																		*/}
																		</div>} title="Important">
																		<Button className='autofilssButton'>I</Button>
																	</Popover>
																	<Popover content={JSON.parse(dataCheck.history)[index] != "" ? JSON.parse(dataCheck.history).map(obj => Object.values(obj)).filter((item, i) => (i == index)) : <div className="autofilss">
																		{/*addHistory ? 
																		<input type="text" className="autofilssInput" style={{color: "#000"}} value={history} onChange={(e) => setHistory(e.target.value)}></input>
																		:
																			<button onClick={(e) => {e.preventDefault(); setAddHistory(true)}}>+ Add</button>
																		*/}
																		</div>} title="History">
																		<Button className='autofilssButton'>H</Button>
																	</Popover>
																	<Popover content={JSON.parse(dataCheck.training)[index] != "" ? JSON.parse(dataCheck.training).map(obj => Object.values(obj)).filter((item, i) => (i == index)) : <div className="autofilss">
																		{/*addTraining ? 
																		<input type="text" className="autofilssInput" style={{color: "#000"}} value={training} onChange={(e) => setTraining(e.target.value)}></input>
																		:
																			<button onClick={(e) => {e.preventDefault(); setAddTraining(true)}}>+ Add</button>
																		*/}
																		</div>
																	} title="Training">
																		<Button className='autofilssButton'>T</Button>
																	</Popover>
																</div>
															</div>
														</>
												))}
											</div>
										</div>
									</div>
								</div>
								<div className="footsubmitem">
									<div className="footsubmitem-title nonetitle"></div>
									<div className="footsubmitem-bottitle">
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/threatrisk.svg"/><span>THREAT (RISK)</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.threat).map(obj => Object.values(obj)).map((item, index) => 
																(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.threat) || JSON.parse(dataCheck.threat) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.threat) || JSON.parse(dataCheck.threat) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setThreat(threat.includes(index) ? threat.filter((i) =>  i !== index) : [...threat, index]); setCheckedIdthreat(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
									</div>
								</div>
								<div className="footsubmitem">
									<div className="footsubmitem-title">Event (Risk Effect)</div>
									<div className="footsubmitem-bottitle">
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/PHYSICALLY.svg"/><span>PHYSICALLY</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.physically).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.physically) || JSON.parse(dataCheck.physically) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.physically) || JSON.parse(dataCheck.physically) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setPhysically(physically.includes(index) ? threat.filter((i) =>  i !== index) : [...threat, index]); setCheckedIdphysically(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/MENTALLY.svg"/><span>MENTALLY</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.mentally).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.mentally) || JSON.parse(dataCheck.mentally) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.mentally) || JSON.parse(dataCheck.mentally) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setMentally(mentally.includes(index) ? mentally.filter((i) =>  i !== index) : [...threat, index]); setCheckedIdmentally(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/EMOTIONALLY.svg"/><span>EMOTIONALLY</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.emotionally).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.emotionally) || JSON.parse(dataCheck.emotionally) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.emotionally) || JSON.parse(dataCheck.emotionally) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setEmotionally(emotionally.includes(index) ? emotionally.filter((i) =>  i !== index) : [...threat, index]); setCheckedIdemotionally(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
									</div>
								</div>
								<div className="footsubmitem">
									<div className="footsubmitem-title">Who is at Risk</div>
									<div className="footsubmitem-bottitle">
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/PEOPLE.svg"/><span>PEOPLE</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.people).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.people) || JSON.parse(dataCheck.people) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.people) || JSON.parse(dataCheck.people) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setPeople(threat.includes(index) ? people.filter((i) =>  i !== index) : [...people, index]); setCheckedIdpeople(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/ENVIRONMENT.svg"/><span>ENVIRONMENT</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.environment).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.environment) || JSON.parse(dataCheck.environment) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.environment) || JSON.parse(dataCheck.environment) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setEnvironment(threat.includes(index) ? environment.filter((i) =>  i !== index) : [...environment, index]); setCheckedIdenvironment(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/ASSET.svg"/><span>ASSET</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.asset).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.asset) || JSON.parse(dataCheck.asset) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.asset) || JSON.parse(dataCheck.asset) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setAsset(asset.includes(index) ? asset.filter((i) =>  i !== index) : [...asset, index]); setCheckedIdasset(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/REPUTATION.svg"/><span>REPUTATION</span></div>
											<div className="listbu">

															{JSON.parse(dataCheck.reputation).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.reputation) || JSON.parse(dataCheck.reputation) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.reputation) || JSON.parse(dataCheck.reputation) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setReputation(reputation.includes(index) ? reputation.filter((i) =>  i !== index) : [...reputation, index]); setCheckedIdreputation(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
									</div>
								</div>
								<div className="footsubmitem">
									<div className="footsubmitem-title">risk rating</div>
									<div className="footsubmitem-bottitle">
										<div className="footsubmitem-one oflism1">
											<div className="footsubmitem-one-bottitle noimages"><span>Likelihood</span></div>
											<div className="listbu ">
											{Object.values(JSON.parse(dataCheck.likelihood)).map((value, index) => 
												<div className="autofilss" key={index}>
													<div className="listonehood">
														<label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className={`listonehood-one ${/*
															selectedItem === item.id && selectedLabels.includes(1) ? `selected${selectedLabel}` : 'selected0'*/ value.includes(1) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClick(index, 1)}>1</label>
														<label style={Math.max(...value.match(/\d+/g)) == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
															selectedItem === item.id && selectedLabels.includes(2) ? `selected${selectedLabel}` : ''*/ value.includes(2) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClick(index, 2)}>2</label>
														<label style={Math.max(...value.match(/\d+/g)) == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
																selectedItem === item.id && selectedLabels.includes(3) ? `selected${selectedLabel}` : ''*/ value.includes(3) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
																}`}
																onClick={() => handleLabelClick(index, 3)}>3</label>
														<label style={Math.max(...value.match(/\d+/g)) == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
															selectedItem === item.id && selectedLabels.includes(4) ? `selected${selectedLabel}` : ''*/ value.includes(4) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClick(index, 4)}>4</label>
														<label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} className={`listonehood-one ${/*
															selectedItem === item.id && selectedLabels.includes(5) ? `selected${selectedLabel}` : ''*/ value.includes(5) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClick(index, 5)}>5</label>
													</div>
												</div>
											)}
											</div>
										</div>
										<div className="footsubmitem-one oflism1">
											<div className="footsubmitem-one-bottitle noimages"><span>Severity</span></div>
											<div className="listbu ">
											{Object.values(JSON.parse(dataCheck.severity)).map((value, index) => 
												<div className="autofilss" key={index}>
													<div className="listonehood">
													<label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className={`listonehood-one ${/*
															selectedItemSeverity === index && selectedLabelsSeverity.includes(1) ? `selected${selectedLabelSeverity}` : 'selected0'*/ value.includes(1) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClickSeverity(index, 1)}>1</label>
														<label style={ Math.max(...value.match(/\d+/g))== "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/* 
															selectedItemSeverity === index && selectedLabelsSeverity.includes(2) ? `selected${selectedLabelSeverity}` : ''*/ value.includes(2) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClickSeverity(index, 2)}>2</label>
														<label style={Math.max(...value.match(/\d+/g)) == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
																selectedItemSeverity === index && selectedLabelsSeverity.includes(3) ? `selected${selectedLabelSeverity}` : ''*/ value.includes(3) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
																}`}
																onClick={() => handleLabelClickSeverity(index, 3)}>3</label>
														<label style={Math.max(...value.match(/\d+/g)) == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
															selectedItemSeverity === index && selectedLabelsSeverity.includes(4) ? `selected${selectedLabelSeverity}` : ''*/ value.includes(4) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClickSeverity(index, 4)}>4</label>
														<label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} className={`listonehood-one ${/*
															selectedItemSeverity === index && selectedLabelsSeverity.includes(5) ? `selected${selectedLabelSeverity}` : ''*/ value.includes(5) ? `selected${Math.max(...value.match(/\d+/g))}` : ''
															}`}
															onClick={() => handleLabelClickSeverity(index, 5)}>5</label>
													</div>
												</div>
											)}
											</div>
										</div>
										<div className="footsubmitem-one oflism1">
											<div className="footsubmitem-one-bottitle noimages"><span>Danger Level</span></div>
											<div className="listbu ">
											{Object.values(JSON.parse(dataCheck.dangerLevel)).map((value, index) => 
												<div className="autofilss" key={index}>
													<div className="listonehood">
													<label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className={`listonehood-one ${/*
															(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(1) ? `selectedResult${selectedLabelResult}` : 'selected0'*/ 
															Math.max(...value.match(/\d+/g)) >= "1" ? `selectedResult${Math.max(...value.match(/\d+/g))}` : ''}`}>1</label>
														<label style={Math.max(...value.match(/\d+/g)) == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
															(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(2) ? `selectedResult${selectedLabelResult}` : ''*/ 
															Math.max(...value.match(/\d+/g)) >= "2" ? `selectedResult${Math.max(...value.match(/\d+/g))}` : ''}`}>2</label>
														<label style={Math.max(...value.match(/\d+/g)) == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${/*
																(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(3) ? `selectedResult${selectedLabelResult}` : ''*/ 
																Math.max(...value.match(/\d+/g)) >= "3" ? `selectedResult${Math.max(...value.match(/\d+/g))}` : ''}`}>3</label>
														<label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} className={`listonehood-one ${/*
															(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(4) ? `selectedResult${selectedLabelResult}` : ''*/ 
															Math.max(...value.match(/\d+/g)) >= "4" ? `selectedResult${Math.max(...value.match(/\d+/g))}` : ''}`}>4</label>
													</div>
												</div>
											)}
											</div>
										</div>
									</div>
								</div>
								<div className="footsubmitem">
									<div className="footsubmitem-title">COntrol measure</div>
									<div className="footsubmitem-bottitle">
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/KNOWLEDGE.svg"/><span>KNOWLEDGE</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.knowledge).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.knowledge) || JSON.parse(dataCheck.knowledge) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.knowledge) || JSON.parse(dataCheck.knowledge) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setKnowledge(knowledge.includes(index) ? knowledge.filter((i) =>  i !== index) : [...knowledge, index]); setCheckedIdknowledge(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/ACTION.svg"/><span>ACTION</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.action).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.action) || JSON.parse(dataCheck.action) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.action) || JSON.parse(dataCheck.action) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setAction(action.includes(index) ? action.filter((i) =>  i !== index) : [...action, index]); setCheckedIdaction(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/BEHAVIOR.svg"/><span>BEHAVIOR</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.behavior).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.behavior) || JSON.parse(dataCheck.behavior) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.behavior) || JSON.parse(dataCheck.behavior) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setBehavior(behavior.includes(index) ? behavior.filter((i) =>  i !== index) : [...behavior, index]); setCheckedIdbehavior(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/REFERENCE.svg"/><span>REFERENCE</span></div>
											<div className="listbu">
															{JSON.parse(dataCheck.reference).map(obj => Object.values(obj)).map((item, index) => 
															(item != '') &&
																<div className="hovers" style={(!JSON.parse(dataCheck.reference) || JSON.parse(dataCheck.reference) == '') ? {overflow: "hidden", overflowY: "hidden"} : {}}>
																	<ul style={(!JSON.parse(dataCheck.reference) || JSON.parse(dataCheck.reference) == '')  ? {overflow: "hidden",  padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		{item.map(it => it.map(i => 
																			<li className="autofilss">
																				<input type="checkbox" defaultChecked={true}/>
																				<span onClick={(e) => {e.preventDefault(); setReference(reference.includes(index) ? reference.filter((i) =>  i !== index) : [...reference, index]); setCheckedIdreference(item.id)}}></span>
																				<Popover content={i}>
																					<div className="truncate" style={{width: "300px"}}>{i}</div>
																				</Popover>
																			</li>
																		))}
																	</ul>
																</div>
															)}	
											</div>
										</div>
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/REGISTRAR.svg"/><span>REGISTRAR</span></div>
											<div className="listbu">
												<div className="autofilss">
													<input className='autofilssInput' type="text" placeholder={dataCheck.registrar} defaultValue={dataCheck.registrar}/>
												</div>
											</div>
										</div>
										{(dataCheck.reviewer != '' && !!dataCheck.reviewer) &&
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/REVIEWER.svg"/><span>REVIEWER</span></div>
											<div className="listbu">
												<div className="autofilss">
													<div className="truncate">{dataCheck.reviewer}</div>
												</div>
											</div>
										</div>
										}
										{(dataCheck.approver != '' && !!dataCheck.approver) &&
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/APPROVER.svg"/><span>APPROVER</span></div>
											<div className="listbu">
												<div className="autofilss">
													<div className="truncate">{dataCheck.approver}</div>
												</div>
											</div>
										</div>
										}
										{(dataCheck.statusR != '' && !!dataCheck.statusR) &&
										<div className="footsubmitem-one">
											<div className="footsubmitem-one-bottitle"><img src="/img/STATUS.svg"/><span>STATUS</span></div>
											<div className="listbu">
												<div className="autofilss">
													<div className="listonehood starts">
													<label  className={`listonehood-one ${
														selectedLabelsStatus.includes(1) ? `active` : ''
														}`}
														onClick={() => handleLabelClickStatus(dataCheck.id, 1)}></label>
													<label  className={`listonehood-one ${
														selectedLabelsStatus.includes(2) ? `active` : ''
														}`}
														onClick={() => handleLabelClickStatus(dataCheck.id, 2)}></label>
													<label  className={`listonehood-one ${
															selectedLabelsStatus.includes(3) ? `active` : ''
															}`}
															onClick={() => handleLabelClickStatus(dataCheck.id, 3)}></label>
													<label  className={`listonehood-one ${
														selectedLabelsStatus.includes(4) ? `active` : ''
														}`}
														onClick={() => handleLabelClickStatus(dataCheck.id, 4)}></label>
													<label className={`listonehood-one ${
														selectedLabelsStatus.includes(5) ? `active` : ''
														}`}
														onClick={() => handleLabelClickStatus(dataCheck.id, 5)}></label>
													</div>
												</div>
											</div>
										</div>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="footsubm" style={{display: "flex"}}>
							<input type="submit" placeholder="" onClick={handleOnSave} value="SUBMIT"/>
						</div>
					</form>
					</div>
					<div class="footsubm-head" style={{overflowX: "hidden", width: "1080px", marginTop: "40px"}}>
						<div class="footsubm-headin">
							<div class="footsubmitem">
								<div class="footsubmitem-title" style={{width: "100%", marginBottom: "3px"}}>legend</div>
								<div class="footsubmitem-bottitle">
									<div class="footsubmitem-one">
										<div class="footsubmitem-title">
											<Popover content={"Likelihood: the probability of a hazard causing harm"}>
											<span>Likelihood</span>
											</Popover>
											
										</div>
										<div class="listbu">
											<Popover content={"Good controls in place. Controls do not rely on people using them. Very unlikely to breakdown. People rarely engage in this activity. Score (1)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
												<div class="listonehood" style={{padding: "0px", background: "#FCE5E5", minWidth: "50px", maxWidth: "50px", margin: "0px 10px 0px 0px"}}>
													<div class="listonehood-one"></div>	
												</div>
												<div>Remote</div>			
											</div>
											</>
											</Popover>
											<Popover content={"Reasonable controls in place but they do rely on people using them. Controls unlikely to breakdown. This situation is unlikely. Score(2)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
												<div class="listonehood" style={{padding: "0px", background: "#F6BFBF", minWidth: "90px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>	
												</div>
												<div>Improbable</div>		
											</div>
											</>
											</Popover>
											<Popover content={"Inadequate controls in place or are likely to breakdown. Controls rely on personal compliance. This situation sometimes arises. Score(3)"}>	
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
												<div class="listonehood" style={{padding: "0px", background: "#ED7F7F", minWidth: "130px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Occasional</div>		
											</div>
											</>
											</Popover>
											<Popover content={"Poor controls in place. Heavy reliance on personal compliance (lots of room for human error). The situation often arises. Score(4)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
												<div class="listonehood" style={{padding: "0px", background: "#E44040", minWidth: "170px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Very Likely</div>		
											</div>
											</>
											</Popover>	
											<Popover content={"No controls in place, exposure to the hazard are expected to occur in most circumstances. Score(5)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
												<div class="listonehood" style={{padding: "0px", background: "#DB0000", minWidth: "210px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Almost Certain</div>		
											</div>
											</>
											</Popover>					
										</div>
									</div>
								
									<div class="footsubmitem-one">
										<div class="footsubmitem-title" style={{width: "98%", marginLeft: "1px"}}>
											<Popover content={"Severity: the consequences of any resulting injury or ill-health"}>
											<span>Severity</span>
											</Popover>
										</div>
										<div class="listbu">
											<Popover content={"Insignificant injury, health effects, damage or disruption to work. Score(1)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#FCE5E5", minWidth: "50px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>	
												</div>
												<div>Minor</div>			
											</div>
											</>
											</Popover>
											<Popover content={"Minor loss of process / slight damage to property & can cause injuries. Score(2)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#F6BFBF", minWidth: "90px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>	
												</div>
												<div>Serious</div>		
											</div>
											</>
											</Popover>
											<Popover content={"Moderate loss of process / damage to property & reportable injuries causing disability. Score(3)"}>	
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#ED7F7F", minWidth: "130px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Reportable</div>		
											</div>
											</>
											</Popover>
											<Popover content={"Critical loss of process / damage to property / Major injuries & fatality. Score(4)"}>	
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#E44040", minWidth: "170px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Major</div>		
											</div>
											</>
											</Popover>
											<Popover content={"Lost of business & multiple fatalities. Score(5)"}>	
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#DB0000", minWidth: "210px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Catastrophic</div>		
											</div>
											</>
											</Popover>										
										</div>
									</div>
								
									<div class="footsubmitem-one">
										<div class="footsubmitem-title">
										<Popover content={"Result: Results of danger level"}>
											<span>Result</span>
										</Popover>
										</div>
										<div class="listbu">
											<Popover content={"low risk -> (0-5)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#2BE564", minWidth: "50px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>	
												</div>
												<div>Low Risk</div>			
											</div>
											</>
											</Popover>
											<Popover content={"moderate risk -> (6-10)"}>
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#E5DE2B", minWidth: "90px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>	
												</div>
												<div>Moderate Risk</div>		
											</div>
											</>
											</Popover>
											<Popover content={"high risk -> (11-15)"}>	
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#DB0000", minWidth: "130px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>High Risk</div>		
											</div>
											</>
											</Popover>
											<Popover content={"extremely high -> (16-25)"}>	
											<>
											<div class="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
												<div class="listonehood" style={{padding: "0px", background: "#0C0C0C", minWidth: "170px", margin: "0px 10px 0px 0px"}}>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>
													<label class="listonehood-one"></label>		
												</div>
												<div>Extremely High Risk</div>		
											</div>
											</>	
											</Popover>					
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

		: <Main />
		}
		<div>
			<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModalSave} onCancel={closeModalSave} id="getmylogin"  footer={null}>
				<div className="succespopup aboutforms">
					<div className="succtext1">Your Hazard & Risk Assessment form has been saved</div>
					<div className="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close className="succtext3"  type="button" onClick={closeModalSave}>ok</div>
				</div>
			</Modal>
			<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModal} onCancel={closeModal} id="getmylogin"  footer={null}>
				<div class="succespopup aboutforms">
					<div class="succtext1">Your Hazard & Risk Assessment form has been submitted</div>
					<div class="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close class="succtext3"  type="button" onClick={closeModal}>enter</div>
				</div>
			</Modal>
			<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModalApprove} onCancel={closeModalApprove} id="getmylogin"  footer={null}>
				<div class="succespopup aboutforms">
					<div class="succtext1">Hazard & Risk Assessment has been Approve</div>
					<div class="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close class="succtext3"  type="button" onClick={closeModalApprove}>enter</div>
				</div>
			</Modal>
			<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModalDecline} onCancel={closeModalDecline} id="getmylogin"  footer={null}>
				<div class="succespopup aboutforms">
					<div class="succtext1">Hazard & Risk Assessment has been Decline</div>
					<div class="succtext1"><img src="/img/task_alt.svg"/></div>
					<div data-fancybox-close class="succtext3"  type="button" onClick={closeModalDecline}>enter</div>
				</div>
			</Modal>
		</div>
	</div>
	: <Spin style={{margin: "auto"}}></Spin> }
	</>
		  )
}
export default Templates;