import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setLikelihood, setSeverity, setDanger } from "../../slices/riskSlice";

const TableRiskRating = ({data, userTask}) => {

    const dispatch = useDispatch();
	const [likelihood, setStateLikelihood] = useState({});
    const [likelihoodNew, setStateLikelihoodNew] = useState([]);
    // const [likelihood, setLikelihood] = useState('');
	// const [severity, setSeverity] = useState("");
    const [severity, setStateSeverity] = useState({});
    const [severityNew, setStateSeverityNew] = useState([]);
	const [dangerLevel, setDangerLevel] = useState("");
	const [selectedItem, setSelectedItem] = useState(null);
	// const [selectedLabel, setSelectedLabel] = useState("0");
    const [selectedLabel, setSelectedLabel] = useState({});
    // const [selectedLabelNew, setSelectedLabelNew] = useState({});
    const [selectedLabelNew, setSelectedLabelNew] = useState([]);
	// const [selectedLabels, setSelectedLabels] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
	const [selectedLabelsStatus, setSelectedLabelsStatus] = useState([]);
	const [selectedItemSeverity, setSelectedItemSeverity] = useState(null);
	// const [selectedLabelSeverity, setSelectedLabelSeverity] = useState("0");
    const [selectedLabelSeverity, setSelectedLabelSeverity] = useState({});
    // const [selectedLabelSeverityNew, setSelectedLabelSeverityNew] = useState({});
    const [selectedLabelSeverityNew, setSelectedLabelSeverityNew] = useState([]);
	const [selectedLabelsSeverity, setSelectedLabelsSeverity] = useState([]);
	// const [selectedLabelResult, setSelectedLabelResult] = useState("0");
    const [selectedLabelResult, setSelectedLabelResult] = useState({});
    const [selectedLabelResultNew, setSelectedLabelResultNew] = useState([]);
	// const [selectedLabelsResult, setSelectedLabelsResult] = useState([]);
    const [selectedLabelsResult, setSelectedLabelsResult] = useState({});
    const [selectedLabelsResultNew, setSelectedLabelsResultNew] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [addRisk ,setAddRisk] = useState([]);
    const [risk, setRisk] = useState([]);
    const [dangerNew, setDangerNew] = useState({});

    useEffect(() => {
		let updatedSelectedLabels = [];
        let newResultLabel = {};
        let newResultLabels = {};
		let label = 0;
		let result = 0;
		
        const likelihoodKeys = Object.keys(likelihood)
        const severityKeys = Object.keys(severity)
        let indexes = likelihoodKeys.filter(i => severityKeys.includes(i))
        indexes.forEach(index => {

            result = Number(selectedLabel[index]) * Number(selectedLabelSeverity[index]);
            if(result >= 0 && result < 6) {
                label = 1;
            } else if (result >= 6 && result < 11) {
                label = 2;
            } else if (result >= 11 && result < 16) {
                label = 3;
            } else if (result >= 16 && result < 26) {
                label = 4;
            }

            switch (label) {
            case 1:
                updatedSelectedLabels = [1];
                break;
            case 2:
                updatedSelectedLabels = [1, 2];
                break;
            case 3:
                updatedSelectedLabels = [1, 2, 3];
                break;
            case 4:
                updatedSelectedLabels = [1, 2, 3, 4];
                break;
            default:
                break;
            }
            newResultLabel = {...newResultLabel, [index]:`${label}`}
            newResultLabels = {...newResultLabels, [index]:`${updatedSelectedLabels}`}
        })
        setSelectedLabelResult(state => ({...state, ...newResultLabel}));
        setSelectedLabelsResult(state => ({...state, ...newResultLabels}));
        dispatch((Object.keys(dangerNew).length == 0) ? setDanger({...selectedLabelsResult, ...newResultLabels}) : setDanger({...selectedLabelsResult, ...newResultLabels, ...dangerNew}))
		
    },[likelihood, severity])
    useEffect(() => {

        let updatedSelectedLabels = [];
        let newResultLabel = {};
        let newResultLabels = {};
		let label = [];
		let result = 0;

        for(let i=0; i<selectedLabelNew.length; i++){
            result = Number(selectedLabelNew[i]) * Number(selectedLabelSeverityNew[i]);
            if(result <= 0) {
                label[i] = 0;
            }else if(result >= 0 && result < 6) {
                label[i] = 1;
            } else if (result >= 6 && result < 11) {
                label[i] = 2;
            } else if (result >= 11 && result < 16) {
                label[i] = 3;
            } else if (result >= 16 && result < 26) {
                label[i] = 4;
            }
    
            switch (label[i]) {
            case 1:
                updatedSelectedLabels[i] = [1];
                break;
            case 2:
                updatedSelectedLabels[i] = [1, 2];
                break;
            case 3:
                updatedSelectedLabels[i] = [1, 2, 3];
                break;
            case 4:
                updatedSelectedLabels[i] = [1, 2, 3, 4];
                break;
            default:
                break;
            }
        }
        setSelectedLabelResultNew(label);
        setSelectedLabelsResultNew(updatedSelectedLabels);
        const newDanger = {}
        for (let i = 0; i< updatedSelectedLabels.length; i++){
            newDanger[`new${i}`] = updatedSelectedLabels[i].toString();
        }
        dispatch(setDanger({...selectedLabelsResult, ...newDanger}));
        setDangerNew({...dangerNew, ...newDanger});
    }, [likelihoodNew, severityNew])

    const handleLabelClickSeverity = (item, label) => {
		// console.log("Severity test ", item, label)
		let updatedSelectedLabels = [];
		
		setSelectedItemSeverity(item); // Устанавливаем выбранный элемент
		if (selectedItem == item) {
		    setSelectedItem(selectedItem);
	  	}
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
        // console.log(updatedSelectedLabels)
		// setSelectedLabelSeverity(label);
        setSelectedLabelSeverity({...selectedLabelSeverity, [item]:`${label}`});
		setStateSeverity({...severity, [item]:`${updatedSelectedLabels}`})
        dispatch(setSeverity({...severity, [item]:`${updatedSelectedLabels}`}))
		// setSelectedLabelsSeverity(updatedSelectedLabels);
        // console.log(severity)
		// handleResult(selectedLabel, label);
        // handleResult();
		// console.log("handleLabelClickSeverity 2", selectedItemSeverity)
    };
    // const handleLabelClickSeverityNew = (label) => {
	// 	// console.log("Severity test ", item, label)
	// 	let updatedSelectedLabels = [];
		
	// 	// setSelectedItemSeverity(item); // Устанавливаем выбранный элемент
	// 	// if (selectedItem == item) {
	// 	//     setSelectedItem(selectedItem);
	//   	// }
	// 	switch (label) {
	// 	  case 1:
	// 		updatedSelectedLabels = [1];
	// 		break;
	// 	  case 2:
	// 		updatedSelectedLabels = [1, 2];
	// 		break;
	// 	  case 3:
	// 		updatedSelectedLabels = [1, 2, 3];
	// 		break;
	// 	  case 4:
	// 		updatedSelectedLabels = [1, 2, 3, 4];
	// 		break;
	// 	  case 5:
	// 		updatedSelectedLabels = [1, 2, 3, 4, 5];
	// 		break;
	// 	  default:
	// 		break;
	// 	}
    //     setSelectedLabelSeverityNew(label);
	// 	setStateSeverityNew(updatedSelectedLabels)
    //     dispatch(setSeverity({...severity, 'new':`${updatedSelectedLabels}`}))
    // };
    const handleLabelClick = (item, label) => {
		// console.log("Likelihood", item, label)
		let updatedSelectedLabels = [];
		
        setSelectedItem(item); // Устанавливаем выбранный элемент
        // console.log("handleLabelClick 1", setSelectedItem(item))
        if (selectedItemSeverity == item) {
            setSelectedItemSeverity(selectedItemSeverity);
        }
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
		// setSelectedLabel(label);
        setSelectedLabel({...selectedLabel, [item]:`${label}`});
		setStateLikelihood({...likelihood, [item]:`${updatedSelectedLabels}`})
        dispatch(setLikelihood({...likelihood, [item]:`${updatedSelectedLabels}`}))
        // setLikelihood(label)
		// setSelectedLabels(updatedSelectedLabels);
		// handleResult(label, selectedLabelSeverity);
        // console.log(likelihood)
        // handleResult();
		// console.log("handleLabelClick 2", selectedItem)
    };
    // const handleLabelClickNew = (label) => {
	// 	// console.log("Likelihood", item, label)
	// 	let updatedSelectedLabels = [];
		
    //     // setSelectedItem(item); // Устанавливаем выбранный элемент
    //     // // console.log("handleLabelClick 1", setSelectedItem(item))
    //     // if (selectedItemSeverity == item) {
    //     //     setSelectedItemSeverity(selectedItemSeverity);
    //     // }
	// 	switch (label) {
	// 	  case 1:
	// 		updatedSelectedLabels = [1];
	// 		break;
	// 	  case 2:
	// 		updatedSelectedLabels = [1, 2];
	// 		break;
	// 	  case 3:
	// 		updatedSelectedLabels = [1, 2, 3];
	// 		break;
	// 	  case 4:
	// 		updatedSelectedLabels = [1, 2, 3, 4];
	// 		break;
	// 	  case 5:
	// 		updatedSelectedLabels = [1, 2, 3, 4, 5];
	// 		break;
	// 	  default:
	// 		break;
	// 	}
    //     setSelectedLabelNew(label);
	// 	setStateLikelihoodNew(updatedSelectedLabels)
    //     dispatch(setLikelihood({...likelihood, 'new':`${updatedSelectedLabels}`}))
    // };
    const handleLabelClickStatus = (item, label) => {
		let updatedSelectedLabels = [];
		
			setSelectedItem(item); // Устанавливаем выбранный элемент
		  
	
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
		setSelectedLabel(label);
		setSelectedLabelsStatus(updatedSelectedLabels);
		
    };
    const initRisk = () => {
        setAddRisk([...addRisk, true]);
        setStateLikelihoodNew([...likelihoodNew, []]);
        setSelectedLabelNew([...selectedLabelNew, 0]);
        setStateSeverityNew([...severityNew, []]);
        setSelectedLabelSeverityNew([...selectedLabelSeverityNew, 0]);
    }
    const handleLabelClickSeverityNew = (index, label) => {
		let updatedSelectedLabels = [];
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
        (severityNew.some((elem, ind) => ind == index))
        ? setStateSeverityNew(severityNew.map((elem, ind) => (ind == index) ? elem = updatedSelectedLabels : elem))
        : setStateSeverityNew([...severityNew, updatedSelectedLabels]);
        (selectedLabelSeverityNew.some((elem, ind) => ind == index))
        ? setSelectedLabelSeverityNew(selectedLabelSeverityNew.map((elem, ind) => (ind == index) ? elem = label : elem))
        : setSelectedLabelSeverityNew([...selectedLabelSeverityNew, label]);
        // setSelectedLabelSeverityNew(label);
		// setStateSeverityNew(updatedSelectedLabels)
        // dispatch(setSeverity({...severity, ...{[`new${index}`]:updatedSelectedLabels.toString()}}))
    };
    const handleLabelClickNew = (index, label) => {
		let updatedSelectedLabels = [];
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
        // console.log(likelihoodNew.reduce((acc, it, ind) => {return {...acc, [`new${ind}`]: it.toString()}}, {}));
        (likelihoodNew.some((elem, ind) => ind == index))
        ? setStateLikelihoodNew(likelihoodNew.map((elem, ind) => (ind == index) ? elem = updatedSelectedLabels : elem))
        : setStateLikelihoodNew([...likelihoodNew, updatedSelectedLabels]);
        (selectedLabelNew.some((elem, ind) => ind == index))
        ? setSelectedLabelNew(selectedLabelNew.map((elem, ind) => (ind == index) ? elem = label : elem))
        : setSelectedLabelNew([...selectedLabelNew, label]);        
    };

    useEffect(()=> {
        dispatch(setLikelihood({...likelihood, ...likelihoodNew.reduce((acc, it, ind) => {return {...acc, [`new${ind}`]: it.toString()}}, {})}))
    }, [likelihoodNew])
    useEffect(()=> {
        dispatch(setSeverity({...severity, ...severityNew.reduce((acc, it, ind) => {return {...acc, [`new${ind}`]: it.toString()}}, {})}))
    }, [severityNew])
    // console.log(likelihoodNew.reduce((acc, it, ind) => {return {...acc, [`new${ind}`]: it.toString()}}, {}))

    return (
        <div className="footsubmitem-bottitle" style={{flexDirection: 'column'}}>
            <div className="footsubmitem-one-bottitle noimages">
                <div class="listbu ">
                    <span>Likelihood</span>
                </div>
                <div class="listbu ">
                    <span>Severity</span>
                </div>
                <div class="listbu ">
                    <span>Danger Level</span>
                </div>
            </div>
            <div className="footsubmitem-one oflism1">
                {data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
                    <div class="largelistbu" key={item.id} style={{display: "flex"}}>
                        <div class="listbu " style={{width: '100%'}}>
                            <div class="autofilss" key={item.id}>
                                <div class="listonehood">
                                    <label type="button" style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                        (typeof likelihood[item.id] != 'undefined') ? (likelihood[item.id].includes(1)) ? `selected${selectedLabel[item.id]}` : 'selected0' : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(1) ? `selected${selectedLabel}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClick(item.id, 1); }}>
                                        1
                                    </label>
                                    <label type="button" style={selectedLabel[item.id] == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (typeof likelihood[item.id] != 'undefined') ? (likelihood[item.id].includes(2)) ? `selected${selectedLabel[item.id]}` : 'selected0' : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(2) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClick(item.id, 2);}}>
                                        2
                                    </label>
                                    <label type="button" style={selectedLabel[item.id] == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                            (typeof likelihood[item.id] != 'undefined') ? (likelihood[item.id].includes(3)) ? `selected${selectedLabel[item.id]}` : 'selected0' : 'selected0'
                                            // selectedItem === item.id && selectedLabels.includes(3) ? `selected${selectedLabel}` : ''
                                            }`}
                                            onClick={(e) => {e.preventDefault();handleLabelClick(item.id, 3); }}>
                                        3
                                    </label>
                                    <label type="button" style={selectedLabel[item.id] == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (typeof likelihood[item.id] != 'undefined') ? (likelihood[item.id].includes(4)) ? `selected${selectedLabel[item.id]}` : 'selected0' : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(4) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClick(item.id, 4);}}>
                                        4
                                    </label>
                                    <label type="button" style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                        (typeof likelihood[item.id] != 'undefined') ? (likelihood[item.id].includes(5)) ? `selected${selectedLabel[item.id]}` : 'selected0' : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(5) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClick(item.id, 5);}}>
                                        5
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="listbu " style={{width: '100%'}}>
                            <div class="autofilss" key={item.id}>
                                <div class="listonehood">
                                <label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(1) ? `selected${selectedLabelSeverity}` : 'selected0'
                                        (typeof severity[item.id] != 'undefined') ? (severity[item.id].includes(1)) ? `selected${selectedLabelSeverity[item.id]}` : 'selected0' : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverity(item.id, 1); }}>1</label>
                                    <label style={selectedLabelSeverity[item.id] == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(2) ? `selected${selectedLabelSeverity}` : ''
                                        (typeof severity[item.id] != 'undefined') ? (severity[item.id].includes(2)) ? `selected${selectedLabelSeverity[item.id]}` : 'selected0' : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverity(item.id, 2); }}>2</label>
                                    <label style={selectedLabelSeverity[item.id] == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(3) ? `selected${selectedLabelSeverity}` : ''
                                        (typeof severity[item.id] != 'undefined') ? (severity[item.id].includes(3)) ? `selected${selectedLabelSeverity[item.id]}` : 'selected0' : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault(); handleLabelClickSeverity(item.id, 3);}}>3</label>
                                    <label style={selectedLabelSeverity[item.id] == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(4) ? `selected${selectedLabelSeverity}` : ''
                                        (typeof severity[item.id] != 'undefined') ? (severity[item.id].includes(4)) ? `selected${selectedLabelSeverity[item.id]}` : 'selected0' : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverity(item.id, 4); }}>4</label>
                                    <label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(5) ? `selected${selectedLabelSeverity}` : ''
                                        (typeof severity[item.id] != 'undefined') ? (severity[item.id].includes(5)) ? `selected${selectedLabelSeverity[item.id]}` : 'selected0' : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverity(item.id, 5); }}>5</label>
                                </div>
                            </div>
                        </div>
                        <div class="listbu " style={{width: '100%'}}>
                            <div class="autofilss" key={item.id}>
                                <div class="listonehood">
                                    <label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                            (typeof likelihood[item.id] != 'undefined' && typeof severity[item.id] != 'undefined' && typeof selectedLabelsResult[item.id] != 'undefined') ? selectedLabelsResult[item.id].includes(1) ? `selectedResult${selectedLabelResult[item.id]}` : '' : 'selected0'
                                            // (selectedItemSeverity === item.id && selectedItem === item.id) &&  selectedLabelsResult.includes(1) ? `selectedResult${selectedLabelResult}` : 'selected0'
                                            }`}>1</label>
                                        <label style={selectedLabelResult[item.id] == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                            (typeof likelihood[item.id] != 'undefined' && typeof severity[item.id] != 'undefined' && typeof selectedLabelsResult[item.id] != 'undefined') ? selectedLabelsResult[item.id].includes(2) ? `selectedResult${selectedLabelResult[item.id]}` : '' : ''
                                            // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(2) ? `selectedResult${selectedLabelResult}` : ''
                                            }`}>2</label>
                                        <label style={selectedLabelResult[item.id] == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                            (typeof likelihood[item.id] != 'undefined' && typeof severity[item.id] != 'undefined' && typeof selectedLabelsResult[item.id] != 'undefined') ? selectedLabelsResult[item.id].includes(3) ? `selectedResult${selectedLabelResult[item.id]}` : '' : ''
                                            // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(3) ? `selectedResult${selectedLabelResult}` : ''
                                            }`}>3</label>
                                        <label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                            (typeof likelihood[item.id] != 'undefined' && typeof severity[item.id] != 'undefined' && typeof selectedLabelsResult[item.id] != 'undefined') ? selectedLabelsResult[item.id].includes(4) ? `selectedResult${selectedLabelResult[item.id]}` : '' : ''
                                            // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(4) ? `selectedResult${selectedLabelResult}` : ''
                                            }`}>4</label>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {addRisk.map((element, index) => 
                    element &&
                    <div class="largelistbu" style={{display: "flex"}}>
                        <div class="listbu "style={{width: '100%'}}>
                            <div class="autofilss">
                                <div class="listonehood">
                                    <label type="button" style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                        (likelihoodNew[index].includes(1)) ? `selected${selectedLabelNew[index]}` : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(1) ? `selected${selectedLabel}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickNew(index, 1); }}>
                                        1
                                    </label>
                                    <label type="button" style={selectedLabelNew[index] == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (likelihoodNew[index].length != 0) ? (likelihoodNew[index].includes(2)) ? `selected${selectedLabelNew[index]}` : 'selected0' : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(2) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickNew(index, 2);}}>
                                        2
                                    </label>
                                    <label type="button" style={selectedLabelNew[index] == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                            (likelihoodNew[index].includes(3)) ? `selected${selectedLabelNew[index]}` : 'selected0'
                                            // selectedItem === item.id && selectedLabels.includes(3) ? `selected${selectedLabel}` : ''
                                            }`}
                                            onClick={(e) => {e.preventDefault();handleLabelClickNew(index, 3); }}>
                                        3
                                    </label>
                                    <label type="button" style={selectedLabelNew[index] == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (likelihoodNew[index].includes(4)) ? `selected${selectedLabelNew[index]}` : 'selected0' 
                                        // selectedItem === item.id && selectedLabels.includes(4) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickNew(index, 4);}}>
                                        4
                                    </label>
                                    <label type="button" style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                        (likelihoodNew[index].includes(5)) ? `selected${selectedLabelNew[index]}` : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(5) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickNew(index, 5);}}>
                                        5
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="listbu " style={{width: '100%'}}>
                            <div class="autofilss">
                                <div class="listonehood">
                                <label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(1) ? `selected${selectedLabelSeverity}` : 'selected0'
                                        (severityNew[index].includes(1)) ? `selected${selectedLabelSeverityNew[index]}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(index, 1); }}>1</label>
                                    <label style={selectedLabelSeverityNew[index] == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(2) ? `selected${selectedLabelSeverity}` : ''
                                        (severityNew[index].includes(2)) ? `selected${selectedLabelSeverityNew[index]}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(index, 2); }}>2</label>
                                    <label style={selectedLabelSeverityNew[index] == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(3) ? `selected${selectedLabelSeverity}` : ''
                                        (severityNew[index].includes(3)) ? `selected${selectedLabelSeverityNew[index]}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault(); handleLabelClickSeverityNew(index, 3);}}>3</label>
                                    <label style={selectedLabelSeverityNew[index] == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(4) ? `selected${selectedLabelSeverity}` : ''
                                        (severityNew[index].includes(4)) ? `selected${selectedLabelSeverityNew[index]}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(index, 4); }}>4</label>
                                    <label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                        // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(5) ? `selected${selectedLabelSeverity}` : ''
                                        (severityNew[index].includes(5)) ? `selected${selectedLabelSeverityNew[index]}` : 'selected0'
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(index, 5); }}>5</label>
                                </div>
                            </div>
                        </div>
                        <div class="listbu " style={{width: '100%'}}>
                            <div class="autofilss">
                                <div class="listonehood">
                                    <label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                            (typeof selectedLabelsResultNew[index] != 'undefined') && selectedLabelsResultNew[index].includes(1) ? `selectedResult${selectedLabelResultNew[index]}` : 'selected0'
                                            // (selectedItemSeverity === item.id && selectedItem === item.id) &&  selectedLabelsResult.includes(1) ? `selectedResult${selectedLabelResult}` : 'selected0'
                                            }`}>1
                                        </label>
                                    <label style={selectedLabelResultNew[index] == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (typeof selectedLabelsResultNew[index] != 'undefined') && selectedLabelsResultNew[index].includes(2) ? `selectedResult${selectedLabelResultNew[index]}` : ''
                                        // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(2) ? `selectedResult${selectedLabelResult}` : ''
                                        }`}>2
                                        </label>
                                    <label style={selectedLabelResultNew[index] == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (typeof selectedLabelsResultNew[index] != 'undefined') && selectedLabelsResultNew[index].includes(3) ? `selectedResult${selectedLabelResultNew[index]}` : ''
                                        // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(3) ? `selectedResult${selectedLabelResult}` : ''
                                        }`}>3
                                        </label>
                                    <label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                        (typeof selectedLabelsResultNew[index] != 'undefined') && selectedLabelsResultNew[index].includes(4) ? `selectedResult${selectedLabelResultNew[index]}` : ''
                                        // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(4) ? `selectedResult${selectedLabelResult}` : ''
                                        }`}>4
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="largelistbu">
                    <div className="autofilss" style={{justifyContent: 'center'}}>
                        <button style={{width: 'auto'}} onClick={e => {e.preventDefault(); initRisk()}}>+ Add</button>
                    </div>
                </div>
                {/* <div class="largelistbu" style={{display: "flex"}}>
                    <div class="listbu ">
                        <div class="autofilss">
                            <div class="listonehood">
                                <label type="button" style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                    (likelihoodNew.includes(1)) ? `selected${selectedLabelNew}` : 'selected0'
                                    // selectedItem === item.id && selectedLabels.includes(1) ? `selected${selectedLabel}` : 'selected0'
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickNew(1); }}>
                                    1
                                </label>
                                <label type="button" style={selectedLabelNew == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    (likelihoodNew.length != 0) ? (likelihoodNew.includes(2)) ? `selected${selectedLabelNew}` : 'selected0' : 'selected0'
                                    // selectedItem === item.id && selectedLabels.includes(2) ? `selected${selectedLabel}` : ''
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickNew(2);}}>
                                    2
                                </label>
                                <label type="button" style={selectedLabelNew == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                        (likelihoodNew.includes(3)) ? `selected${selectedLabelNew}` : 'selected0'
                                        // selectedItem === item.id && selectedLabels.includes(3) ? `selected${selectedLabel}` : ''
                                        }`}
                                        onClick={(e) => {e.preventDefault();handleLabelClickNew(3); }}>
                                    3
                                </label>
                                <label type="button" style={selectedLabelNew == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    (likelihoodNew.includes(4)) ? `selected${selectedLabelNew}` : 'selected0' 
                                    // selectedItem === item.id && selectedLabels.includes(4) ? `selected${selectedLabel}` : ''
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickNew(4);}}>
                                    4
                                </label>
                                <label type="button" style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                    (likelihoodNew.includes(5)) ? `selected${selectedLabelNew}` : 'selected0'
                                    // selectedItem === item.id && selectedLabels.includes(5) ? `selected${selectedLabel}` : ''
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickNew(5);}}>
                                    5
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="listbu ">
                        <div class="autofilss">
                            <div class="listonehood">
                            <label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                    // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(1) ? `selected${selectedLabelSeverity}` : 'selected0'
                                    (severityNew.includes(1)) ? `selected${selectedLabelSeverityNew}` : 'selected0'
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(1); }}>1</label>
                                <label style={selectedLabelSeverityNew == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(2) ? `selected${selectedLabelSeverity}` : ''
                                    (severityNew.includes(2)) ? `selected${selectedLabelSeverityNew}` : 'selected0'
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(2); }}>2</label>
                                <label style={selectedLabelSeverityNew == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(3) ? `selected${selectedLabelSeverity}` : ''
                                    (severityNew.includes(3)) ? `selected${selectedLabelSeverityNew}` : 'selected0'
                                    }`}
                                    onClick={(e) => {e.preventDefault(); handleLabelClickSeverityNew(3);}}>3</label>
                                <label style={selectedLabelSeverityNew == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(4) ? `selected${selectedLabelSeverity}` : ''
                                    (severityNew.includes(4)) ? `selected${selectedLabelSeverityNew}` : 'selected0'
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(4); }}>4</label>
                                <label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                    // selectedItemSeverity === item.id && selectedLabelsSeverity.includes(5) ? `selected${selectedLabelSeverity}` : ''
                                    (severityNew.includes(5)) ? `selected${selectedLabelSeverityNew}` : 'selected0'
                                    }`}
                                    onClick={(e) => {e.preventDefault();handleLabelClickSeverityNew(5); }}>5</label>
                            </div>
                        </div>
                    </div>
                    <div class="listbu ">
                        <div class="autofilss">
                            <div class="listonehood">
                                <label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} class={`listonehood-one ${
                                        selectedLabelsResultNew.includes(1) ? `selectedResult${selectedLabelResultNew}` : 'selected0'
                                        // (selectedItemSeverity === item.id && selectedItem === item.id) &&  selectedLabelsResult.includes(1) ? `selectedResult${selectedLabelResult}` : 'selected0'
                                        }`}>1
                                    </label>
                                <label style={selectedLabelResultNew == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    selectedLabelsResultNew.includes(2) ? `selectedResult${selectedLabelResultNew}` : ''
                                    // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(2) ? `selectedResult${selectedLabelResult}` : ''
                                    }`}>2
                                    </label>
                                <label style={selectedLabelResultNew == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} class={`listonehood-one ${
                                    selectedLabelsResultNew.includes(3) ? `selectedResult${selectedLabelResultNew}` : ''
                                    // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(3) ? `selectedResult${selectedLabelResult}` : ''
                                    }`}>3
                                    </label>
                                <label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} class={`listonehood-one ${
                                    selectedLabelsResultNew.includes(4) ? `selectedResult${selectedLabelResultNew}` : ''
                                    // (selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(4) ? `selectedResult${selectedLabelResult}` : ''
                                    }`}>4
                                    </label>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
};

export default TableRiskRating;