import $api from '../http';

export default class TaskService {

    static async getAllTasks() {
        return $api.get('/task/task')
        .then((response) => {
            return response.data.allTasks;
        });
    }

    static async createTask(task) {
        return $api.post('/task/task', {task})
        .then((response) => {
            return response.data;
        });
    }

    static async logout() {
        return $api.post('/logout');
    }

    static async auth(token) {
        return $api.post('/auth');
    }

}