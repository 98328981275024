import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setAsset, setNewAsset } from "../../slices/riskSlice";

const TableCellAsset = ({item, addNew}) => {

	const [asset2, setAsset2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdasset, setCheckedIdasset] = useState("");
    const [addAsset, setAddAsset] = useState([]);
    const [addAsset2, setAddAsset2] = useState([]);
    const [asset, setStateAsset] = useState([]);
    const dispatch = useDispatch();
    

    return (
        <>		
            {/* {(!item && addNew) &&
                <>
                    {addAsset ? 
                        <input type="text" className="autofilssInput" value={asset} onChange={(e) => dispatch(setNewAsset(e.target.value)) && setStateAsset(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddAsset(true)}}>+ Add</button>
                    }
                </>
            }  */}
            {item &&
                <div class="hovers" style={item.asset[0] === "" ? {overflow: "hidden"} : {}}>      
                    <ul style={item.asset[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.asset.map((index) => (
                        <>	
                            {index === "" 
                                ? 
                                <div class="hovers" >
                                    <ul style={{padding: "0px", margin: "0px"}}>
                                        {addAsset.map((elem,indx) => 
                                            elem &&
                                            <li class="autofilss" key={index.id}>
                                                <input type="text" class="autofilssInput" onChange={(e) => 
                                                    {
                                                        e.preventDefault(); 
                                                        // console.log(e.target.value)
                                                        setAsset2(
                                                            (asset2.some((el, ind) => ind == indx)) 
                                                            ? asset2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                            : [...asset2, e.target.value]
                                                        );
                                                        dispatch(
                                                            setAsset(
                                                                [   
                                                                    item.id, 
                                                                    (asset2.some((el, ind) => ind == indx)) 
                                                                    ? [...asset2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                    : [...asset2, e.target.value]
                                                                ]
                                                            )
                                                        );
                                                    }
                                                }></input>
                                            </li>
                                        )}
                                        <div class="autofilss">
                                            <button onClick={(e) => {e.preventDefault(); setAddAsset([...addAsset, true]); setCheckedIdasset(item.id)}}>+ Add</button>
                                        </div>                                        
                                    </ul>
                                </div>
                                // <div class="autofilss" key={index.id} >
                                //     {addAsset2 && checkedIdasset == item.id 
                                //         ? 
                                //         <input type="text" class="autofilssInput" value={checkedIdasset == item.id ? asset2 : ""} onChange={(e) => {dispatch(setAsset([item.id, [e.target.value]])) && setAsset2(e.target.value); setCheckedItem(item); setCheckedIdasset(item.id)}}></input>
                                //         :
                                //         <button onClick={(e) => {e.preventDefault(); setAddAsset2(true); setCheckedIdasset(item.id)}}>+ Add</button>
                                //     }
                                // </div> 
                                : 
                                <> 
                                    <li class="autofilss" key={index.id} >
                                        <input type="checkbox" checked={(asset.includes(index) && checkedIdasset == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                        <span onClick={(e) => {e.preventDefault(); 
                                            asset.some(i => i == index)
                                                ? dispatch(setAsset([item.id, (asset2.length != 0) ? [...asset.filter((i) =>  i !== index), asset2] : asset.filter((i) =>  i !== index)])) && setStateAsset(asset.filter((i) =>  i !== index))
                                                : dispatch(setAsset([item.id, (asset2.length != 0) ? [...[...asset, index], asset2] : [...asset, index]])) && setStateAsset([...asset, index])
                                            setCheckedIdasset(item.id)}
                                        }></span>
                                        {/* <span onClick={(e) => {e.preventDefault(); setAsset(asset.includes(index) ? asset.filter((i) =>  i !== index) : [...asset, index]); setCheckedIdasset(item.id)}}></span> */}
                                        <Popover content={index}>
                                            <div class="truncate" style={{width: "300px"}}>{index}</div>
                                        </Popover>
                                    </li>
                                </>
                            }
                        </>
                        ))}
                        {addAsset2 && checkedIdasset == item.id 
                            && 
                            addAsset2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setAsset2(
                                            (asset2.some((el, ind) => ind == index)) 
                                            ? asset2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...asset2, e.target.value]
                                        ); 
                                        dispatch(
                                            setAsset([item.id,
                                                (asset2.some((el, ind) => ind == index)) 
                                                ? [...asset, ...[...asset2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...asset, ...[...asset2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.asset[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddAsset2([...asset2, true]); setCheckedIdasset(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addAsset2 && checkedIdasset == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={checkedIdasset == item.id ? asset2 : ""} onChange={(e) => {dispatch(setAsset([item.id, [...asset, e.target.value]])) && setAsset2([e.target.value]); setCheckedItem(item); setCheckedIdasset(item.id)}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddAsset2(true); setCheckedIdasset(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellAsset;