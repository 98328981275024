import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setNewPeople, setPeople } from "../../slices/riskSlice";

const TableCellPeople = ({item, addNew}) => {

    const [people, setStatePeople] = useState([]);
    const [people2, setPeople2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdpeople, setCheckedIdpeople] = useState("");
    const [addPeople, setAddPeople] = useState([]);
	const [addPeople2, setAddPeople2] = useState([]);
    const dispatch = useDispatch();

    return (
        <>	
            {/* {(!item && addNew) &&
                <>
                    {addPeople ? 
                        <input type="text" className="autofilssInput" value={people} onChange={(e) => dispatch(setNewPeople(e.target.value)) && setStatePeople(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddPeople(true)}}>+ Add</button>
                    }
                </>
            }  */}
            {item &&
                <div class="hovers" style={item.people[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.people[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.people.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addPeople.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setPeople2(
                                                                (people2.some((el, ind) => ind == indx)) 
                                                                ? people2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...people2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setPeople(
                                                                    [   
                                                                        item.id, 
                                                                        (people2.some((el, ind) => ind == indx)) 
                                                                        ? [...people2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...people2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddPeople([...addPeople, true]); setCheckedIdpeople(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addPeople2 && checkedIdpeople == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdpeople == item.id ? people2 : ""} onChange={(e) => {dispatch(setPeople([item.id,[e.target.value]])) && setPeople2(e.target.value); setCheckedItem(item); setCheckedIdpeople(item.id)}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddPeople2(true); setCheckedIdpeople(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss" key={index.id} >
                                        <input type="checkbox" checked={(people.includes(index) && checkedIdpeople == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                        <span onClick={(e) => {e.preventDefault();
                                            people.some(i => i == index)
                                                ? dispatch(setPeople([item.id, (people2.length != 0) ? [...people.filter((i) =>  i !== index), ...people2] : people.filter((i) =>  i !== index)])) && setStatePeople(people.filter((i) =>  i !== index))
                                                : dispatch(setPeople([item.id, (people2.length != 0) ? [...[...people, index], ...people2] : [...people, index]])) && setStatePeople([...people, index])
                                        setCheckedIdpeople(item.id)}
                                        }></span>
                                        {/* <span onClick={(e) => {e.preventDefault(); setPeople(people.includes(index) ? people.filter((i) =>  i !== index) : [...people, index]); setCheckedIdpeople(item.id)}}></span> */}
                                        <Popover content={index}>
                                            <div class="truncate" style={{width: "300px"}}>{index}</div>
                                        </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addPeople2 && checkedIdpeople == item.id 
                            && 
                            addPeople2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setPeople2(
                                            (people2.some((el, ind) => ind == index)) 
                                            ? people2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...people2, e.target.value]
                                        ); 
                                        dispatch(
                                            setPeople([item.id,
                                                (people2.some((el, ind) => ind == index)) 
                                                ? [...people, ...[...people2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...people, ...[...people2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.people[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddPeople2([...people2, true]); setCheckedIdpeople(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addPeople2 && checkedIdpeople == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={checkedIdpeople == item.id ? people2 : ""} onChange={(e) => {dispatch(setPeople([item.id,[...people, e.target.value]])) && setPeople2([e.target.value]); setCheckedItem(item); setCheckedIdpeople(item.id)}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddPeople2(true); setCheckedIdpeople(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellPeople;