import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Space} from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import Main from './Main';
import axios from 'axios';
import '../App.css';
import { setDataCheck, setDataSave, setHazardList, setSavedHazardList } from '../slices/riskSlice';
import { setTaskUser } from '../slices/userSlise';
const MyRisk = () => {
	const storedAuthToken = localStorage.getItem("authToken");
	const host = '92.205.162.68';
	// const host = '212.3.155.91'
	const { user } = useSelector((state) => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [data, setData] = useState({risks: []});
	const [whoAmI, setWhoAmI] = useState('user');
	const [isLoading, setIsLoading] = useState(true);
  	const [error, setError] = useState(null);

	useEffect(() => {
		async function fetchData() {
		  try {
			const response = await axios.get(`http://${host}:3005/risk2/risktable2/`, {headers: {Authorization: `Bearer ${storedAuthToken}`}});
			const data = response.data
			console.log(data)
			setData({risks: data.response});
		  } catch (err) {
			setError(err);
		  } finally {
			setIsLoading(false);
		  }
		}
	
		fetchData();
	}, []);

	useEffect(() => {
		if(user.isAdmin){
			setWhoAmI('admin')
		}else if(user.isReviewer){
			setWhoAmI('reviewer')
		}else if(user.isApprover){
			setWhoAmI('approver')
		}
	}, [user])
	console.log(data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '0'))
	console.log(data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.saved).map((item) =>  item))

	// const saveState = (item) => {
	// 	console.log(item);
	// 	const hz = JSON.parse(item.hazardsList);
	// 	console.log(hz);
	// 	dispatch(setSavedHazardList(hz));
	// }

	const renderCurrentNotification = () => {
		switch (whoAmI) {
			case "user":
				return (
					<>
						<div class="lk-lefttoright-l">
							<div class="maxforms">
								<div class="lkblockedits">
									<div class="listoff-aprouvs">
										<div class="heads-form newhh-des">
											<div class="heads-form-apr">History</div>
											<div class="listing-satisf">
											{data.risks.filter(item => item.userid == user.currentUserId).map((item) => (
												<>
													<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
												</>
											))}
											</div>
										</div>										
										<div class="heads-form newhh-des">
										<div class="heads-form-apr">completed <span class="textfr">[ approved ]</span> {<Space><Badge count={<CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/>}/></Space>}</div>

											{/* <div class="heads-form-apr">completed <span class="textfr">[ approved ]</span> {<Space><Badge count={<CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/>}/></Space>}</div> */}
											<div class="listing-satisf">
											{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.approve || item.decline).map((item) => (
												<>
												<Link key={item.id} onClick={e => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable');}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}] <span class="textfr">[{item.approve == '1' ? "approved" : "decline"}]</span>
												{<Space><Badge count={item.statusAdmin == '1' ? <CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/> : <CloseCircleFilled style={{ color: '#f5222d'}}/>}/></Space>}
												</Link>
												</>
											))}
											</div>
										</div>
										<div class="heads-form newhh-des">
											<div class="heads-form-apr">Saved templates</div>
											<div class="listing-satisf">
											{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.saved).map((item) => (
												<>
												{/* <Link key={item.id} to="/templates/" onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/templates/')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link> */}
												<Link key={item.id} to="/templates/" onClick={(e) => {e.preventDefault(); dispatch(setDataSave(item)); dispatch(setTaskUser(item.category)); navigate('/risktable/')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
												</>
											))}
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</>
				)
			case "admin":
				return (
					<>
						<div class="lk-lefttoright-l">
							<div class="maxforms">
								<div class="lkblockedits">
									<div class="listoff-aprouvs">
										<div class="heads-form">
											<div class="heads-form1">APPROVALS</div>
										</div>
										<div class="heads-form newhh-des">
											<div class="heads-form-apr">Review</div>
											<div class="listing-satisf">
											{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusApprove == '1').map((item) => (
												<>
													<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
												</>
											))}
											</div>
										</div>
										<div class="heads-form newhh-des">
											<div class="heads-form-apr">completed</div>
											<div class="listing-satisf">
											{data.risks.filter(item => (item.approve || item.decline) && item.statusReview == '1' && item.statusApprove == '1').map((item) => (
												<>
												<Link key={item.id} to="/risktable/" onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/templates/')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}] <span class="textfr">[{item.approve ? "approved" : "decline"}]</span>
												{<Space><Badge count={item.approve ? <CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/> : <CloseCircleFilled style={{ color: '#f5222d'}}/>}/></Space>}
												</Link>
												</>
											))}
											</div>
										</div>
									</div>
								</div>
			
							</div>
						</div>
					</>
				)
			case "reviewer":
				return (
						<>
							<div class="lk-lefttoright-l">
								<div class="maxforms">
									<div class="lkblockedits">
										<div class="listoff-aprouvs">
											<div class="heads-form">
												<div class="heads-form1">Reviewer Functions</div>
											</div>
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">Review</div>
												<div class="listing-satisf">
												{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '0').map((item) => (
													<>
														<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</>
												))}
												</div>
											</div>	
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">completed</div>
												<div class="listing-satisf">
												{data.risks.filter(item => (item.statusReview == '1' || item.statusReview == '2') && !item.approve && !item.decline && !item.saved).map((item) => (
													<>
													<Link key={item.id} onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}] <span class="textfr">[{item.statusReview == '1' ? "approved" : "decline"}]</span>
													{<Space><Badge count={item.statusReview == '1' ? <CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/> : <CloseCircleFilled style={{ color: '#f5222d'}}/>}/></Space>}
													</Link>
													</>
												))}
												</div>
											</div>
										</div>
										<div class="listoff-aprouvs">
											<div class="heads-form">
												<div class="heads-form1">User Functions</div>
											</div>
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">History</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.userid == user.currentUserId).map((item) => (
													<>
														<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</>
												))}
												</div>
											</div>										
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">completed <span class="textfr">[ approved ]</span> {<Space><Badge count={<CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/>}/></Space>}</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.approve).map((item) => (
													<>
													<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]
													</Link>
													</>
												))}
												</div>
											</div>
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">Saved templates</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.saved).map((item) => (
													<>
													<Link key={item.id} to="/templates/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</>
												))}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
				)
			case "approver":
				return (
						<>
							<div class="lk-lefttoright-l">
								<div class="maxforms">
									<div class="lkblockedits">
										<div class="listoff-aprouvs">
											<div class="heads-form">
												<div class="heads-form1">Approver Functions</div>
											</div>
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">Review</div>
												<div class="listing-satisf">
												{data.risks.filter(item => !item.approve && !item.decline && !item.saved && item.statusReview == '1' && item.statusApprove == '0').map((item) => (
													<>
														<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</>
												))}
												</div>
											</div>	
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">completed</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.statusApprove == '1' || item.statusApprove == '2').map((item) => (
													<>
													<Link key={item.id} onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}] <span class="textfr">[{item.statusApprove == '1' ? "approved" : "decline"}]</span>
													{<Space><Badge count={item.statusApprove == '1' ? <CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/> : <CloseCircleFilled style={{ color: '#f5222d'}}/>}/></Space>}
													</Link>
													</>
												))}
												</div>
											</div>
										</div>
										<div class="listoff-aprouvs">
											<div class="heads-form">
												<div class="heads-form1">User Functions</div>
											</div>
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">History</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.userid == user.currentUserId).map((item) => (
													<>
														<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</>
												))}
												</div>
											</div>										
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">completed <span class="textfr">[ approved ]</span> {<Space><Badge count={<CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/>}/></Space>}</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.approve).map((item) => (
													<>
													<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]
													</Link>
													</>
												))}
												</div>
											</div>
											<div class="heads-form newhh-des">
												<div class="heads-form-apr">Saved templates</div>
												<div class="listing-satisf">
												{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.saved).map((item) => (
													<>
													<Link key={item.id} to="/templates/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
													</>
												))}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</>
				)
			default:
				return null;
		}
	}

	return (
		<div>
		{storedAuthToken ? 
		<main>
			<div class="container">
				<div class="lk-lefttoright revers">
					<div class="lk-lefttoright-r">
						<ul class="lk-menu">
							<li><Link to="/risk/" >Create & Register H&R Assessment</Link></li>
							<li><Link to="/myrisk/" class="active">Review H&R Assessment</Link></li>
							<li><Link to="/dashboard/">View Dashboard</Link></li>
						</ul>
					</div>
					{renderCurrentNotification()}
					{/* {user.isAdmin 
						? 
						<>
			<div class="lk-lefttoright-l">
				<div class="maxforms">
					<div class="lkblockedits">
						<div class="listoff-aprouvs">
							<div class="heads-form">
								<div class="heads-form1">APPROVALS</div>
							</div>
							<div class="heads-form newhh-des">
								<div class="heads-form-apr">Review</div>
								<div class="listing-satisf">
								{data.risks.filter(item => !item.approve && !item.decline && !item.saved).map((item) => (
									<>
										<Link key={item.id}  onClick={(e) => {e.preventDefault(); dispatch(setDataCheck(item)); navigate('/reviewrisktable')}} class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
									</>
								))}
								</div>
							</div>
							<div class="heads-form newhh-des">
								<div class="heads-form-apr">completed</div>
								<div class="listing-satisf">
								{data.risks.filter(item => item.approve || item.decline).map((item) => (
									<>
									<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}] <span class="textfr">[{item.approve ? "approved" : "decline"}]</span>
									{<Space><Badge count={item.approve ? <CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/> : <CloseCircleFilled style={{ color: '#f5222d'}}/>}/></Space>}
									</Link>
									</>
								))}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
						</>
						:
						<>
						<div class="lk-lefttoright-l">
							<div class="maxforms">
								<div class="lkblockedits">
									<div class="listoff-aprouvs">
										<div class="heads-form">
											<div class="heads-form1">History</div>
										</div>
										
										<div class="heads-form newhh-des">
											<div class="heads-form-apr">completed <span class="textfr">[ approved ]</span> {<Space><Badge count={<CheckCircleFilled style={{ color: 'rgb(62, 178, 17)'}}/>}/></Space>}</div>
											<div class="listing-satisf">
											{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.approve).map((item) => (
												<>
												<Link key={item.id} to="/risktable/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]
												</Link>
												</>
											))}
											</div>
										</div>
										<div class="heads-form newhh-des">
											<div class="heads-form-apr">Saved templates</div>
											<div class="listing-satisf">
											{data.risks.filter(item => item.userid == user.currentUserId).filter(item => item.saved).map((item) => (
												<>
												<Link key={item.id} to="/templates/" class="listing-satisf-item">[risk assessment {item.id} submitted by {item.registrar} at {item.createdAt}]</Link>
												</>
											))}
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						</>
					} */}
				</div>
				<div class="overpopsups">
					<div class="overpopsups-icon"></div>
					<div class="overpopsups-data">
						<div class="overpopsups-profile">
							<div class="overpopsups-profile-l"><img src="/img/account_circle_head.svg" /></div>
							<div class="overpopsups-profile-r"></div>
						</div>
						<div class="menunospace">
							<Link class="menupops" to="/">Home/News Feed</Link>
							<Link class="menupops" to="">Connections</Link>
							<Link class="menupops" to="">Email</Link>
							<Link class="menupops" to="/lk/">Settings</Link>
						</div>
						<Link class="menupops" to="/risk/">Create & Register H&R Assessment</Link>
						<Link class="menupops" to="/myrisk/">Review H&R Assessment</Link>
						<Link class="menupops" to="/dashboard/">Dashboard</Link>
					</div>
				</div>
			</div>
		</main>
		: <Main />
	}
	</div>
		  )
}
export default MyRisk;
