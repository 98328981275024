import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAttention, setHistory, setTraining, setHazardList, setNewHazardList, unsetHazardList } from "../../slices/riskSlice";

const TableCellIHT = ({item, data}) => {

    const [checkedhazardsList, setCheckedhazardsList] = useState(false);
    const [checkedIdhazardsList, setCheckedIdhazardsList] = useState((typeof data != 'undefined') ? [parseInt(Object.keys(data).toString())] : []);
    const [checkedItem, setCheckedItem] = useState({});
    const [attention2, setAttention2] = useState("");
    const [addImportant2, setAddImportant2] = useState(false);
    const [history2, setHistory2] = useState("");
    const [addHistory2, setAddHistory2] = useState(false);
    const [training2, setTraining2] = useState("");
    const [addTraining2, setAddTraining2] = useState(false);
    const [hazardsList, setStateHazardsList] = useState("");
    const [addHazardsList, setAddHazardsList] = useState(false);
    const [attention, setStateAttention] = useState("");
    const [addImportant, setAddImportant] = useState(false);
    const [history, setStateHistory] = useState("");
    const [addTraining, setAddTraining] = useState(false);
    const [training, setStateTraining] = useState("");
    const [addHistory, setAddHistory] = useState(false);

    const dispatch = useDispatch();

    return (
        <>
        {/* {!item && addNew
            ? 
            <>
                {addHazardsList
                    ?
                    <input type="text" className="autofilssInput" value={hazardsList} onChange={(e) => dispatch(setNewHazardList(e.target.value)) && setStateHazardsList(e.target.value)}></input>
                    :
                    <button onClick={(e) => {e.preventDefault(); setAddHazardsList(true)}}>+ Add</button>
                }
                <div>
                    <Popover content={ <div className="autofilss">
                        {addImportant ? 
                        <input type="text" className="autofilssInput" style={{color: "#000"}} value={attention} onChange={(e) => dispatch(setAttention([{'new':e.target.value}])) && setStateAttention(e.target.value)}></input>
                        :
                            <button onClick={(e) => {e.preventDefault(); setAddImportant(true)}}>+ Add</button>
                        }
                        </div>} title="Important">
                        <Button className='autofilssButton'>I</Button>
                    </Popover>
                    <Popover content={ <div className="autofilss">
                        {addHistory ? 
                        <input type="text" className="autofilssInput" style={{color: "#000"}} value={history} onChange={(e) => dispatch(setHistory([{'new':e.target.value}])) && setStateHistory(e.target.value)}></input>
                        :
                            <button onClick={(e) => {e.preventDefault(); setAddHistory(true)}}>+ Add</button>
                        }
                        </div>} title="History">
                        <Button className='autofilssButton'>H</Button>
                    </Popover>
                    <Popover content={ <div className="autofilss">
                        {addTraining ? 
                        <input type="text" className="autofilssInput" style={{color: "#000"}} value={training} onChange={(e) => dispatch(setTraining([{'new':e.target.value}])) && setStateTraining(e.target.value)}></input>
                        :
                            <button onClick={(e) => {e.preventDefault(); setAddTraining(true)}}>+ Add</button>
                        }
                        </div>
                    } title="Training">
                        <Button className='autofilssButton'>T</Button>
                    </Popover>
                </div>
            </>
            :
            <></>
        } */}
        { item 
            ?
            item.hazardsList === "" 
                ? 
                <div className="autofilss" key={item.id}></div> 
                : 
                <div className="autofilss" key={item.id}>
                    
                    <input type="checkbox" checked={checkedIdhazardsList.some((index) => (index == item.id)) ? true : false} onChange={e => setCheckedhazardsList(e.target.checked)} />
                    {/* <input type="checkbox" checked={item.id == checkedIdhazardsList ? true : false}  /> */}
                    <span onClick={(e) => {e.preventDefault(); checkedIdhazardsList.some((index) => (index == item.id)) 
                        ? 
                        // checkedIdhazardsList.splice(checkedIdhazardsList.indexOf(item.id), 1) && dispatch(unsetHazardList(item.id.toString()))
                        // checkedIdhazardsList.splice(checkedIdhazardsList.indexOf(item.id), 1)
                        dispatch(unsetHazardList(item.id.toString())) &&
                        setCheckedIdhazardsList(checkedIdhazardsList.filter(el => el != item.id))
                        : 
                        // dispatch(setHazardList([...hazardList, {[item.id]:`${item.hazardsList}`}])) &&
                        dispatch(setHazardList(item)) && 
                        setCheckedIdhazardsList([...checkedIdhazardsList, item.id]); 
                        setCheckedhazardsList(checkedhazardsList ? false : true);
                        setCheckedItem(state => ({...state, [item.id]:item}));
                        
                        }}>
                    </span>
                    {/* <span onClick={(e) => {e.preventDefault(); setCheckedIdhazardsList(item.id); setCheckedhazardsList(checkedhazardsList ? false : true);  setCheckedItem(item);}}></span> */}

                    <Popover content={item.hazardsList}><div >{item.hazardsList}</div></Popover>
                
                    <div>
                        <Popover content=
                            {item.attention !== "" 
                                ? 
                                item.attention 
                                : 
                                <div className="autofilss">
                                    {addImportant2 
                                        ? 
                                        <input type="text" className="autofilssInput" style={{color: "#000"}} value={attention2} onChange={(e) => setAttention2(e.target.value)}></input>
                                        :
                                        <button onClick={(e) => {e.preventDefault(); setAddImportant2(true)}}>+ Add</button>
                                    }
                                </div>
                            } title="Important">
                            <Button className='autofilssButton'>I</Button>
                        </Popover>
                        <Popover content={item.history !== "" ? item.history : <div className="autofilss">
                            {addHistory2 ? 
                            <input type="text" class="autofilssInput" style={{color: "#000"}} value={history2} onChange={(e) => setHistory2(e.target.value)}></input>
                            :
                                <button onClick={(e) => {e.preventDefault(); setAddHistory2(true)}}>+ Add</button>
                            }
                            </div>} title="History">
                            <Button className='autofilssButton'>H</Button>
                        </Popover>
                        <Popover content={item.training !== "" ? item.training : <div className="autofilss">
                            {addTraining2 ? 
                            <input type="text" className="autofilssInput" style={{color: "#000"}} value={training2} onChange={(e) => setTraining2(e.target.value)}></input>
                            :
                                <button onClick={(e) => {e.preventDefault(); setAddTraining2(true)}}>+ Add</button>
                            }
                            </div>
                        } title="Training">
                            <Button className='autofilssButton'>T</Button>
                        </Popover>
                    </div>
                </div>
            :
            <></>
        }
        </>
    )
};

export default TableCellIHT;