import $api from '../http';

export default class Risk2Service {

    static async getAllRisks2() {
        return $api.get('/risk2/risktable2')
        .then((response) => {
            return response.data.response;
        });
    }

    static async createRisk2(
            userid,
            category,
            hazardsList,
            attention,
            history,
            training,
            threat,
            physically,
            mentally,
            emotionally,
            people,
            environment,
            asset,
            reputation,
            likelihood,
            severity,
            danger,
            knowledge,
            action,
            behavior,
            reference,
            registrar,
            itemid,
            save,
        ) {
        return $api.post('/risk2/risktable2', 
            JSON.stringify({
                userid,
                category,
                hazardsList,
                attention,
                history,
                training,
                threat,
                physically,
                mentally,
                emotionally,
                people,
                environment,
                asset,
                reputation,
                likelihood,
                severity,
                danger,
                knowledge,
                action,
                behavior,
                reference,
                registrar,
                itemid,
                save,
            }),
            {
                headers: {
                    'Accept': 'application/json',
                }
            }).
            then((response) => {
                return response.data;
            });
    }

    static async reviewRisk2(id, statusReview, reviewer, reason) {
        return $api.put(`/risk2/review/${id}`, {statusReview, reviewer, reason}).
        then((response) => {
            return response.data;
        })
    }

    static async approveRisk2(id, statusApprove, approver, reason) {
        return $api.put(`/risk2/approve/${id}`, {statusApprove, approver, reason}).
        then((response) => {
            return response.data;
        })
    }

    static async adminRisk2(id, status, statusR, reason) {
        return $api.put(`/risk2/admin/${id}`, {status, statusR, reason}).
        then((response) => {
            return response.data;
        })
    }

    static async updateRisk2(id) {
        return $api.put(`/risk2/risktable2/${id}`).
        then((response) => {
            return response.data;
        })
    }
}