import React, { useState, useEffect, memo} from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserId, setToken, setisLogin, setisAdmin } from '../slices/userSlise';
import Main from './Main';
import { Modal, Spin, Button, Popover} from "antd";
import '../App.css';
import { setDataRisks, setData2Risks, handleSubmit, resetRiskSlice, setHazardList, setSavedHazardList} from "../slices/riskSlice";

import Risk2Service from '../services/risk2-services';
import RiskService from '../services/risk-services';
// import Autofilss from '../components/Autofilss';
// import Threats from '../components/Threats';
// import Physically from '../components/Physically';
// import Mentally from '../components/Mentally';
// import Emotionally from '../components/Emotionally';
// import People from '../components/People';
// import Enviroment from '../components/Enviroment';
// import Asset from '../components/Asset';
// import Likelihood from '../components/Likelihood';
// import Severity from '../components/Severity';
// import Danger from '../components/Danger';
// import Reputation from '../components/Reputation';
import TableCellIHT from '../components/table/TableCellIHT';
import TableCellThreat from '../components/table/TableCellThreat';
import TableCellPhysically from '../components/table/TableCellPhysically';
import TableCellMentally from '../components/table/TableCellMentally';
import TableCellEmotionally from '../components/table/TableCellEmotionally';
import TableCellPeople from '../components/table/TableCellPeople';
import TableCellEnvironment from '../components/table/TableCellEnvironment';
import TableCellAsset from '../components/table/TableCellAsset';
// import TableCellLikehood from '../components/table/TableCellLikehood';
// import TableCellSeverity from '../components/table/TableCellSeverity';
// import TableCellDanger from '../components/table/TableCellDanger';
import TableCellReputation from '../components/table/TableCellReputation';
import TableCellKnowledge from '../components/table/TableCellKnowledge';
import TableCellAction from '../components/table/TableCellAction';
import TableCellBehavior from '../components/table/TableCellBehavior';
import TableCellReference from '../components/table/TableCellReference';
import TableCellRegistrar from '../components/table/TableCellRegistrar';
import TableRiskRating from '../components/table/TableRiskRating';
import TableCellThreatNew from '../components/table/TableCellThreatNew';
import TableCellIHTNew from '../components/table/TableCellIHTNew';
import TableCellPhysicallyNew from '../components/table/TableCellPhysicallyNew';
import TableCellMentallyNew from '../components/table/TableCellMentallyNew';
import TableCellEmotionallyNew from '../components/table/TableCellEmotionallyNew';
import TableCellPeopleNew from '../components/table/TableCellPeopleNew';
import TableCellEnvironmentNew from '../components/table/TableCellEnvironmentNew';
import TableCellAssetNew from '../components/table/TableCellAssetNew';
import TableCellReputationNew from '../components/table/TableCellReputationNew';
import TableCellKnowledgeNew from '../components/table/TableCellKnowledgeNew';
import TableCellActionNew from '../components/table/TableCellActionNew';
import TableCellBehaviorNew from '../components/table/TableCellBehaviorNew';
import TableCellReferenceNew from '../components/table/TableCellReferenceNew';

const RiskTable = () => {
	const dispatch = useDispatch();
	// const host = '92.205.162.68';
	const host = '212.3.155.91'
	const storedAuthToken = localStorage.getItem("authToken");
	const [data, setData] = useState({risks: []});
	const [data2, setData2] = useState({risks: []});
	// const { user } = useSelector((state) => state.user);
	const userIsLogin = useSelector(({user}) => user.isLogin);
	const userName = useSelector(({user}) => user.name);
	const userEmail = useSelector(({user}) => user.email);
	const userTask = useSelector(({user}) => user.task);
	const userDate = useSelector(({user}) => user.date);
	const userTime = useSelector(({user}) => user.time);
	const userLocation = useSelector(({user}) => user.location);
	const userIsAdmin = useSelector(({user}) => user.isAdmin);
	const userCurrentUserId = useSelector(({user}) => user.currentUserId);
	const navigate = useNavigate();
	const [openModal , setOpenModal] = useState(false);
	const [openModalSave , setOpenModalSave] = useState(false);
	const [openModalApprove , setOpenModalApprove] = useState(false);
	const [openModalDecline , setOpenModalDecline] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
  	const [error, setError] = useState(null);
	const [addHazardsList, setAddHazardsList] = useState(false);
	const [addImportant, setAddImportant] = useState(false);
	const [addHistory, setAddHistory] = useState(false);
	const [addTraining, setAddTraining] = useState(false);
	const [addThreat, setAddThreat] = useState(false);
	const [addPhysically, setAddPhysically] = useState(false);
	const [addMentally, setAddMentally] = useState(false);
	const [addEmotionally, setAddEmotionally] = useState(false);
	const [addPeople, setAddPeople] = useState(false);
	const [addEnvironment, setAddEnvironment] = useState(false);
	const [addAsset, setAddAsset] = useState(false);
	const [addReputation, setAddReputation] = useState(false);
	const [addKnowledge, setAddKnowledge] = useState(false);
	const [addAction, setAddAction] = useState(false);
	const [addBehavior, setAddBehavior] = useState(false);
	const [addReference, setAddReference] = useState(false);
	const [addImportant2, setAddImportant2] = useState(false);
	const [addHistory2, setAddHistory2] = useState(false);
	const [addTraining2, setAddTraining2] = useState(false);
	const [addThreat2, setAddThreat2] = useState(false);
	const [addPhysically2, setAddPhysically2] = useState(false);
	const [addMentally2, setAddMentally2] = useState(false);
	const [addEmotionally2, setAddEmotionally2] = useState(false);
	const [addPeople2, setAddPeople2] = useState(false);
	const [addEnvironment2, setAddEnvironment2] = useState(false);
	const [addAsset2, setAddAsset2] = useState(false);
	const [addReputation2, setAddReputation2] = useState(false);
	const [addKnowledge2, setAddKnowledge2] = useState(false);
	const [addAction2, setAddAction2] = useState(false);
	const [addBehavior2, setAddBehavior2] = useState(false);
	const [addReference2, setAddReference2] = useState(false);
	const [addRegistrar, setAddRegistrar] = useState(false);
	const [addRegistrar2, setAddRegistrar2] = useState(false);
	const [addReviewer, setAddReviewer] = useState(false);
	const [addApprover, setAddApprover] = useState(false);
	const [addStatusR, setAddStatusR] = useState(false);
	const [addLikelihood, setAddLikelihood] = useState(false);
	const [addseverity, setAddSeverity] = useState(false);
	const [addDangerLevel, setAddDangerLevel] = useState(false);
	const [checked, setChecked] = useState(false);
	const [checkedItem, setCheckedItem] = useState(null);
	const [checkedItemId, setCheckedItemId] = useState(null);
	const [checkedId, setCheckedId] = useState("");
	const [checkedDate, setCheckedDate] = useState("");
	const [checkedRegistrar, setCheckedRegistrar] = useState("");
	const [checkedhazardsList, setCheckedhazardsList] = useState(false);
	const [checkedIdhazardsList, setCheckedIdhazardsList] = useState([]);
	const [checkedthreat, setCheckedthreat] = useState(false);
	const [checkedIdthreat, setCheckedIdthreat] = useState("");
	const [checkedphysically, setCheckedphysically] = useState(false);
	const [checkedIdphysically, setCheckedIdphysically] = useState("");
	const [checkedmentally, setCheckedmentally] = useState(false);
	const [checkedIdmentally, setCheckedIdmentally] = useState("");
	const [checkedemotionally, setCheckedemotionally] = useState(false);
	const [checkedIdemotionally, setCheckedIdemotionally] = useState("");
	const [checkedpeople, setCheckedpeople] = useState(false);
	const [checkedIdpeople, setCheckedIdpeople] = useState("");
	const [checkedenvironment, setCheckedenvironment] = useState(false);
	const [checkedIdenvironment, setCheckedIdenvironment] = useState("");
	const [checkedasset, setCheckedasset] = useState(false);
	const [checkedIdasset, setCheckedIdasset] = useState("");
	const [checkedreputation, setCheckedreputation] = useState(false);
	const [checkedIdreputation, setCheckedIdreputation] = useState("");
	const [checkedknowledge, setCheckedknowledge] = useState(false);
	const [checkedIdknowledge, setCheckedIdknowledge] = useState("");
	const [checkedaction, setCheckedaction] = useState(false);
	const [checkedIdaction, setCheckedIdaction] = useState("");
	const [checkedbehavior, setCheckedbehavior] = useState(false);
	const [checkedIdbehavior, setCheckedIdbehavior] = useState("");
	const [checkedreference, setCheckedreference] = useState(false);
	const [checkedIdreference, setCheckedIdreference] = useState("");
	const [checkedIdregistrar, setCheckedIdregistrar] = useState("");
	const [hazardsList, setHazardsList] = useState("");
	const [attention, setAttention] = useState("");
	const [history, setHistory] = useState("");
	const [training, setTraining] = useState("");
	const [threat, setThreat] = useState("");
	const [physically, setPhysically] = useState("");
	const [mentally, setMentally] = useState("");
	const [emotionally, setEmotionally] = useState("");
	const [people, setPeople] = useState("");
	const [environment, setEnvironment] = useState("");
	const [asset, setAsset] = useState("");
	const [reputation, setReputation] = useState("");
	const [knowledge, setKnowledge] = useState("");
	const [action, setAction] = useState("");
	const [behavior, setBehavior] = useState("");
	const [reference, setReference] = useState("");
	const [attention2, setAttention2] = useState("");
	const [history2, setHistory2] = useState("");
	const [training2, setTraining2] = useState("");
	const [threat2, setThreat2] = useState("");
	const [physically2, setPhysically2] = useState("");
	const [mentally2, setMentally2] = useState("");
	const [emotionally2, setEmotionally2] = useState("");
	const [people2, setPeople2] = useState("");
	const [environment2, setEnvironment2] = useState("");
	const [asset2, setAsset2] = useState("");
	const [reputation2, setReputation2] = useState("");
	const [knowledge2, setKnowledge2] = useState("");
	const [action2, setAction2] = useState("");
	const [behavior2, setBehavior2] = useState("");
	const [reference2, setReference2] = useState("");
	const [registrar, setRegistrar] = useState("");
	const [registrar2, setRegistrar2] = useState("");
	const [reason, setReason] = useState("");
	const [reviewer, setReviewer] = useState("");
	const [approver, setApprover] = useState("");
	const [statusR, setStatusR] = useState("");
	const [likelihood, setLikelihood] = useState("");
	const [severity, setSeverity] = useState("");
	const [dangerLevel, setDangerLevel] = useState("");
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedLabel, setSelectedLabel] = useState("0");
	const [selectedLabels, setSelectedLabels] = useState([]);
	const [selectedLabelsStatus, setSelectedLabelsStatus] = useState([]);
	const [selectedItemSeverity, setSelectedItemSeverity] = useState(null);
	const [selectedLabelSeverity, setSelectedLabelSeverity] = useState("0");
	const [selectedLabelsSeverity, setSelectedLabelsSeverity] = useState([]);
	const [selectedLabelResult, setSelectedLabelResult] = useState("0");
	const [selectedLabelsResult, setSelectedLabelsResult] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleResult = (selectedLabel, selectedLabelSeverity) => {
		let updatedSelectedLabels = [];
		let label = 0;
		let result = 0;
		
		
			result = Number(selectedLabel) * Number(selectedLabelSeverity);
			if(result >= 0 && result < 6) {
				label = 1;
			} else if (result >= 6 && result < 11) {
				label = 2;
			} else if (result >= 11 && result < 16) {
				label = 3;
			} else if (result >= 16 && result < 26) {
				label = 4;
			}
		
	
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  default:
			break;
		}
		
		setSelectedLabelResult(label);
		setDangerLevel(label);
		setSelectedLabelsResult(updatedSelectedLabels);
		console.log("updatedSelectedLabels", updatedSelectedLabels, label)
		
	  };
	  const handleLabelClickSeverity = (item, label) => {
		console.log("handleLabelClickSeverity", item, label)
		let updatedSelectedLabels = [];
		
		setSelectedItemSeverity(item); // Устанавливаем выбранный элемент
		if (selectedItem == item) {
		setSelectedItem(selectedItem);
	  	}
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
		setSelectedLabelSeverity(label);
		setSeverity(label)
		setSelectedLabelsSeverity(updatedSelectedLabels);
		handleResult(selectedLabel, label);
		console.log("handleLabelClickSeverity 2", selectedItemSeverity)
	  };

	  const handleLabelClick = (item, label) => {
		console.log("handleLabelClick", item, label)
		let updatedSelectedLabels = [];
		
			setSelectedItem(item); // Устанавливаем выбранный элемент
			console.log("handleLabelClick 1", setSelectedItem(item))
			if (selectedItemSeverity == item) {
				setSelectedItemSeverity(selectedItemSeverity);
				  }
	
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
		setSelectedLabel(label);
		setLikelihood(label)
		setSelectedLabels(updatedSelectedLabels);
		handleResult(label, selectedLabelSeverity);
		console.log("handleLabelClick 2", selectedItem)
	  };
	  const handleLabelClickStatus = (item, label) => {
		let updatedSelectedLabels = [];
		
			setSelectedItem(item); // Устанавливаем выбранный элемент
		  
	
		switch (label) {
		  case 1:
			updatedSelectedLabels = [1];
			break;
		  case 2:
			updatedSelectedLabels = [1, 2];
			break;
		  case 3:
			updatedSelectedLabels = [1, 2, 3];
			break;
		  case 4:
			updatedSelectedLabels = [1, 2, 3, 4];
			break;
		  case 5:
			updatedSelectedLabels = [1, 2, 3, 4, 5];
			break;
		  default:
			break;
		}
		setSelectedLabel(label);
		setSelectedLabelsStatus(updatedSelectedLabels);
		
	  };
	
	const closeModal = () => {
		setOpenModal(false);
		navigate('/myrisk');
	}
	const closeModalSave = () => {
		setOpenModalSave(false);
		navigate('/myrisk');
	}
	const closeModalApprove = () => {
		setOpenModalApprove(false);
	}
	const closeModalDecline = () => {
		setOpenModalDecline(false);
	}

	// useEffect(() => {
	// 	if(storedAuthToken && userIsLogin){
	// 		async function getRisks(){
	// 			const risks = await RiskService.getAllRisks()
	// 			setData({risks: risks});
	// 			dispatch(setDataRisks({risks: risks}));

	// 		}
	// 		getRisks()
	// 	}

	// }, [userIsLogin, storedAuthToken])
	const saveData = useSelector(({risk}) => risk.dataSave);
	console.log(saveData)
	console.log(typeof saveData)
	const hazardData = (typeof saveData != 'undefined') ? saveData.hazardsList: '';
	const attentionData = (typeof saveData != 'undefined') ? saveData.attention: '';
	const historyData = (typeof saveData != 'undefined') ? saveData.history: '';
	const trainingData = (typeof saveData != 'undefined') ? saveData.training: '';
	const hazardDataNew = (typeof saveData != 'undefined' && typeof hazardData != 'undefined')  ? JSON.parse(hazardData).filter(obj => (Object.keys(obj).toString().match(/^new[0-9]+/g)) && Object.keys(obj).map(item => Array.from(item.matchAll(/^new[0-9]+/g)))).flat(Infinity) : '';
	const attentionDataNew = (typeof saveData != 'undefined' && typeof attentionData != 'undefined')  ? JSON.parse(attentionData).filter(obj => (Object.keys(obj).toString().match(/^new[0-9]+/g)) && Object.keys(obj).map(item => Array.from(item.matchAll(/^new[0-9]+/g)))).flat(Infinity) : '';
	const historyDataNew = (typeof saveData != 'undefined' && typeof historyData != 'undefined')  ? JSON.parse(historyData).filter(obj => (Object.keys(obj).toString().match(/^new[0-9]+/g)) && Object.keys(obj).map(item => Array.from(item.matchAll(/^new[0-9]+/g)))).flat(Infinity) : '';
	const trainingDataNew = (typeof saveData != 'undefined' && typeof trainingData != 'undefined')  ? JSON.parse(trainingData).filter(obj => (Object.keys(obj).toString().match(/^new[0-9]+/g)) && Object.keys(obj).map(item => Array.from(item.matchAll(/^new[0-9]+/g)))).flat(Infinity) : '';
	const threatData = (typeof saveData != 'undefined') ? saveData.threat: '';
	const threatDataNew = (typeof saveData != 'undefined' && typeof threatData != 'undefined')  ? JSON.parse(threatData).filter(obj => (Object.keys(obj).toString().match(/^new[0-9]+/g)) && Object.keys(obj).map(item => Array.from(item.matchAll(/^new[0-9]+/g)))).flat(Infinity) : '';
	const physicallyData = (typeof saveData != 'undefined') ? saveData.threat: '';
	const physicallyDataNew = (typeof saveData != 'undefined' && typeof physicallyData != 'undefined')  ? JSON.parse(physicallyData).filter(obj => (Object.keys(obj).toString().match(/^new[0-9]+/g)) && Object.keys(obj).map(item => Array.from(item.matchAll(/^new[0-9]+/g)))).flat(Infinity) : '';


	useEffect(() => {
		if(storedAuthToken && userIsLogin){
			dispatch(resetRiskSlice());
			async function getRisks2(){
				const risks2 = await Risk2Service.getAllRisks2()
				setData2({risks: risks2});
				dispatch(setData2Risks({risks: risks2}));
			}
			async function getRisks(){
				const risks = await RiskService.getAllRisks()
				setData({risks: risks});
				dispatch(setDataRisks({risks: risks}));

			}
			getRisks2();
			getRisks();
			(typeof saveData == 'object') && dispatch(setSavedHazardList(true));
		}

	}, [userIsLogin, storedAuthToken])
	const datastate = useSelector(({risk}) => risk.data);
	// if(!datastate){
	// 	datastate.risks.filter(item => item.category == user.task).filter(item => item.approve).map((item) => (
	// 		console.log(item)
	// 	))
	// }
//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await axios.get(`http://${host}:3001/risktable/`);
// 		const data = response.data
// 		console.log(data)
//         setData(data);
//       } catch (err) {
//         setError(err);
//       } finally {
//         setIsLoading(false);
//       }
//     }
// 	async function fetchData2() {
// 		try {
// 		  const response = await axios.get(`http://${host}:3001/risktable2/`);
// 		  const data2 = response.data
// 		  console.log(data2)
// 		  setData2(data2);
// 		} catch (err) {
// 		  setError(err);
// 		} finally {
// 		  setIsLoading(false);
// 		}
// 	  }
//     fetchData();
// 	fetchData2();
//   }, []);
	// 	console.log(checkedItem)
	const handleOnSubmit = async (e) => {
		e.preventDefault();
		const save = false;
		dispatch(handleSubmit({save}));
		setOpenModal(true);
		// console.log(data)
		// const t = [
		// 	{3: 'Caught in or between objects'},
		// 	{2: 'Struck by falling objects'},
		// 	{1: 'Falls from heights'}]
		// const te = t.map(i => Object.keys(i).toString())
		// console.log(te)
		// let result = {};
		// if (checkedItem){
		// 	console.log("2", checkedItem)
		// result = await fetch(
		// 	`http://${host}:3001/risktable2/`, {
		// 	method: "post",
		// 	body: JSON.stringify({ 
		// 		userid: userCurrentUserId,
		// 		itemid: checkedItem.id,
		// 		category: checkedItem.category,
		// 		hazardsList: checkedItem.hazardsList,
		// 		attention: [...checkedItem.attention, attention2].map((item) => (attention.includes(item) || item == attention2)  && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		history: [...checkedItem.history, history2].map((item) => (history.includes(item) || item == history2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		training: [...checkedItem.training, training2].map((item) => (training.includes(item) || item == training2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		threat: [...checkedItem.threat, threat2].map((item) => (threat.includes(item) || item == threat2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		physically: [...checkedItem.physically, physically2].map((item) => (physically.includes(item) || item == physically2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		mentally: [...checkedItem.mentally, mentally2].map((item) => (mentally.includes(item) || item == mentally2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		emotionally: [...checkedItem.emotionally, emotionally2].map((item) => (emotionally.includes(item) || item == emotionally2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		people: [...checkedItem.people, people2].map((item) => (people.includes(item) || item == people2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		environment: [...checkedItem.environment, environment2].map((item) => (environment.includes(item) || item == environment2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		asset: [...checkedItem.asset, asset2].map((item) => (asset.includes(item) || item == asset2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		reputation: [...checkedItem.reputation, reputation2].map((item) => (reputation.includes(item) || item == reputation2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		knowledge: [...checkedItem.knowledge, knowledge2].map((item) => (knowledge.includes(item) || item == knowledge2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		action: [...checkedItem.action, action2].map((item) => (action.includes(item) || item == action2) && item != "" ? {item, checked: true} : {item, checked: false}), 
		// 		behavior: [...checkedItem.behavior, behavior2].map((item) => (behavior.includes(item) || item == behavior2) && item != "" ? {item, checked: true} : {item, checked: false}),
		// 		reference: [...checkedItem.reference, reference2].map((item) => (reference.includes(item) || item == reference2) && item != "" ? {item, checked: true} : {item, checked: false}),
		// 		registrar: userName,
		// 		reviewer: checkedItem.reviewer,
		// 		approver: checkedItem.approver,
		// 		statusR: checkedItem.statusR,
		// 		likelihood: likelihood,
		// 		severity: severity,
		// 		dangerLevel: dangerLevel,
		// 		saved: false,
		// 	 }),
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	}
		// })
		// } else {
		// console.log("1")
		// result = await fetch(
		// 	`http://${host}:3001/risktable2/`, {
		// 	method: "post",
		// 	body: JSON.stringify({ 
		// 	userid: userCurrentUserId,
		// 	itemid: null,
		// 	category: userTask,
		// 	hazardsList,
		// 	attention: [{item: attention, checked: true}], 
		// 	history: [{item: history, checked: true}], 
		// 	training: [{item: training, checked: true}], 
		// 	threat: [{item: threat, checked: true}], 
		// 	physically: [{item: physically, checked: true}], 
		// 	mentally: [{item: mentally, checked: true}], 
		// 	emotionally: [{item: emotionally, checked: true}], 
		// 	people: [{item: people, checked: true}], 
		// 	environment: [{item: environment, checked: true}], 
		// 	asset: [{item: asset, checked: true}], 
		// 	reputation: [{item: reputation, checked: true}], 
		// 	knowledge: [{item: knowledge, checked: true}], 
		// 	action: [{item: action, checked: true}], 
		// 	behavior: [{item: behavior, checked: true}],
		// 	reference: [{item: reference, checked: true}],
		// 	registrar: userName,
		// 	reviewer,
		// 	approver,
		// 	statusR,
		// 	likelihood,
		// 	severity,
		// 	dangerLevel,
		// 	saved: false,
		// 	 }),
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	}
		// })};
		// result = await result.json();
		// console.log("result", result)
		// if (result) {
		// 	setOpenModal(true);
		// }
	}
	const handleOnSave = async (e) => {
		e.preventDefault();
		const save = true;
		dispatch(handleSubmit({save}));
		setOpenModalSave(true);
	// 	let result = {};
	// 	if (checkedItem){
	// 		console.log("2", checkedItem)
	// 	result = await fetch(
	// 		`http://${host}:3001/risktable2/`, {
	// 		method: "post",
	// 		body: JSON.stringify({ 
	// 		userid: userCurrentUserId,
	// 		itemid: checkedItem.id,
	// 		category: userTask,
	// 		hazardsList: checkedItem.hazardsList,
	// 		attention: [...checkedItem.attention, attention2].map((item) => (attention.includes(item) || item == attention2)  && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		history: [...checkedItem.history, history2].map((item) => (history.includes(item) || item == history2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		training: [...checkedItem.training, training2].map((item) => (training.includes(item) || item == training2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		threat: [...checkedItem.threat, threat2].map((item) => (threat.includes(item) || item == threat2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		physically: [...checkedItem.physically, physically2].map((item) => (physically.includes(item) || item == physically2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		mentally: [...checkedItem.mentally, mentally2].map((item) => (mentally.includes(item) || item == mentally2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		emotionally: [...checkedItem.emotionally, emotionally2].map((item) => (emotionally.includes(item) || item == emotionally2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		people: [...checkedItem.people, people2].map((item) => (people.includes(item) || item == people2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		environment: [...checkedItem.environment, environment2].map((item) => (environment.includes(item) || item == environment2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		asset: [...checkedItem.asset, asset2].map((item) => (asset.includes(item) || item == asset2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		reputation: [...checkedItem.reputation, reputation2].map((item) => (reputation.includes(item) || item == reputation2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		knowledge: [...checkedItem.knowledge, knowledge2].map((item) => (knowledge.includes(item) || item == knowledge2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		action: [...checkedItem.action, action2].map((item) => (action.includes(item) || item == action2) && item != "" ? {item, checked: true} : {item, checked: false}), 
	// 		behavior: [...checkedItem.behavior, behavior2].map((item) => (behavior.includes(item) || item == behavior2) && item != "" ? {item, checked: true} : {item, checked: false}),
	// 		reference: [...checkedItem.reference, reference2].map((item) => (reference.includes(item) || item == reference2) && item != "" ? {item, checked: true} : {item, checked: false}),
	// 		registrar: userName,
	// 		reviewer: checkedItem.reviewer,
	// 		approver: checkedItem.approver,
	// 		statusR: checkedItem.statusR,
	// 		likelihood: likelihood,
	// 		severity: severity,
	// 		dangerLevel: dangerLevel,
	// 		saved: true,
	// 		 }),
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		}
	// 	})
	// } else {
	// 	console.log("1")
	// 	result = await fetch(
	// 		`http://${host}:3001/risktable2/`, {
	// 		method: "post",
	// 		body: JSON.stringify({ 
	// 		userid: userCurrentUserId,
	// 		itemid: null,
	// 		category: userTask,
	// 		hazardsList,
	// 		attention: [{item: attention, checked: true}], 
	// 		history: [{item: history, checked: true}], 
	// 		training: [{item: training, checked: true}], 
	// 		threat: [{item: threat, checked: true}], 
	// 		physically: [{item: physically, checked: true}], 
	// 		mentally: [{item: mentally, checked: true}], 
	// 		emotionally: [{item: emotionally, checked: true}], 
	// 		people: [{item: people, checked: true}], 
	// 		environment: [{item: environment, checked: true}], 
	// 		asset: [{item: asset, checked: true}], 
	// 		reputation: [{item: reputation, checked: true}], 
	// 		knowledge: [{item: knowledge, checked: true}], 
	// 		action: [{item: action, checked: true}], 
	// 		behavior: [{item: behavior, checked: true}],
	// 		reference: [{item: reference, checked: true}],
	// 		registrar: userName,
	// 		reviewer,
	// 		approver,
	// 		statusR,
	// 		likelihood,
	// 		severity,
	// 		dangerLevel,
	// 		saved: true,
	// 		 }),
	// 		headers: {
	// 			'Content-Type': 'application/json'
	// 		}
	// 	})};
	// 	result = await result.json();
	// 	console.log("result", result)
	// 	if (result) {
	// 		setOpenModalSave(true);
			
	// 	}
	}
	const handleOnSubmitApprove = async (e, id) => {

		e.preventDefault();
		let result = await fetch(
		`http://${host}:3001/risktable/`, {
			method: "post",
			body: JSON.stringify({ 
			userid: checkedItem.userid,
			category: userTask,
			hazardsList: checkedItem.hazardsList,
			attention: checkedItem.attention.map((item) => item.item), 
			history: checkedItem.history.map((item) => item.item), 
			training: checkedItem.training.map((item) => item.item), 
			threat: checkedItem.threat.map((item) => item.item), 
			physically: checkedItem.physically.map((item) => item.item), 
			mentally: checkedItem.mentally.map((item) => item.item), 
			emotionally: checkedItem.emotionally.map((item) => item.item), 
			people: checkedItem.people.map((item) => item.item), 
			environment: checkedItem.environment.map((item) => item.item), 
			asset: checkedItem.asset.map((item) => item.item), 
			reputation: checkedItem.reputation.map((item) => item.item), 
			knowledge: checkedItem.knowledge.map((item) => item.item), 
			action: checkedItem.action.map((item) => item.item), 
			behavior: checkedItem.behavior.map((item) => item.item),
			reference: checkedItem.reference.map((item) => item.item),
			registrar: checkedItem.registrar,
			reviewer,
			approver,
			statusR,
			likelihood,
			severity,
			dangerLevel,
			approve: true,
			decline: false,
			reason: reason,
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		let result2 = await fetch(
			`http://${host}:3001/risktable2/${id}`, {
				method: "put",
				body: JSON.stringify({ 
				userid: checkedItem.userid,
				itemid: checkedItem.id,
				category: userTask,
				hazardsList: checkedItem.hazardsList,
				attention: checkedItem.attention, 
				history: checkedItem.history, 
				training: checkedItem.training, 
				threat: checkedItem.threat, 
				physically: checkedItem.physically, 
				mentally: checkedItem.mentally, 
				emotionally: checkedItem.emotionally, 
				people: checkedItem.people, 
				environment: checkedItem.environment, 
				asset: checkedItem.asset, 
				reputation: checkedItem.reputation, 
				knowledge: checkedItem.knowledge, 
				action: checkedItem.action, 
				behavior: checkedItem.behavior,
				reference: checkedItem.reference,
				registrar: checkedItem.registrar,
				reviewer,
				approver,
				statusR,
				likelihood,
				severity,
				dangerLevel,
				approve: true,
				decline: false,
				reason: reason,
				saved: false,
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			})
		result = await result.json();
		console.log("result", result)
		if (result) {
			setOpenModalApprove(true);
			setChecked(false);	
		}
		result2 = await result2.json();
		console.log("result2", result2)
		if (result2) {
			setOpenModalApprove(true);
			setChecked(false);	
		}
	}
	const handleOnSubmitDecline = async (e, id) => {

		e.preventDefault();
		let result = await fetch(
		`http://${host}:3001/risktable2/${id}`, {
			method: "put",
			body: JSON.stringify({ 
			userid: checkedItem.userid,
			itemid: checkedItem.id,
			category: userTask,
			hazardsList: checkedItem.hazardsList,
			attention: checkedItem.attention, 
			history: checkedItem.history, 
			training: checkedItem.training, 
			threat: checkedItem.threat, 
			physically: checkedItem.physically, 
			mentally: checkedItem.mentally, 
			emotionally: checkedItem.emotionally, 
			people: checkedItem.people, 
			environment: checkedItem.environment, 
			asset: checkedItem.asset, 
			reputation: checkedItem.reputation, 
			knowledge: checkedItem.knowledge, 
			action: checkedItem.action, 
			behavior: checkedItem.behavior,
			reference: checkedItem.reference,
			registrar: checkedItem.registrar,
			reviewer,
			approver,
			statusR,
			likelihood,
			severity,
			dangerLevel,
			approve: false,
			decline: true,
			reason: reason,
			saved: false,
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
		result = await result.json();
		console.log("result", result)
		if (result) {
			setOpenModalDecline(true);
			setChecked(false);		
		}
	}

	return (
		<>
			{data 

				? 
				<div>
					{storedAuthToken 
						? 
						<main>
							<div className="container">
								<div className="hazards">
									<div className="hazards-head">
										<div className="hazards-head-l">
											<div className="hazards-head-title">HAZARD & RISK ASSESMENT TABLE </div>
											<p>{userName}</p>
											<p>{userTask}</p>
										</div>
										<div className="hazards-head-r">
											<p>{userDate}</p>
											<p>{userTime}</p>
											<p>{userLocation}</p>
										</div>
									</div>
									<div className="hazardsbody">
										<form className="preform1" method="post" action="">
											
												<div className="footsubm-head scrollbar-dynamic">
												<div className="footsubm-headin">
													<div className="footsubmitem">
														<div className="footsubmitem-title nonetitle"></div>
														<div className="footsubmitem-bottitle">
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/HAZARDLIST.svg"/><span>HAZARD LIST</span></div>
																<div className="listbu" style={{width: '375px'}}>
																	{!!datastate.risks && datastate.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item, index) => (
																		// <>
																		// {item.hazardsList === "" 
																		// 	? 
																		// 	<div className="autofilss" key={item.id}></div> 
																		// 	: 
																		// 	<div className="autofilss" key={item.id}>
																		// 		{/* {console.log(item.id, checkedIdhazardsList)}
																		// 		{console.log(checkedIdhazardsList.indexOf(item.id))}
																		// 		{console.log(checkedhazardsList)} */}
																		// 		<input type="checkbox" checked={checkedIdhazardsList.some((index) => (index === item.id)) ? true : false} onChange={e => setCheckedhazardsList(e.target.checked)} />
																		// 		{/* <input type="checkbox" checked={item.id == checkedIdhazardsList ? true : false}  /> */}
																		// 		<span onClick={(e) => {e.preventDefault(); checkedIdhazardsList.some((index) => (index === item.id)) ? checkedIdhazardsList.splice(checkedIdhazardsList.indexOf(item.id), 1) : setCheckedIdhazardsList([...checkedIdhazardsList, item.id]); setCheckedhazardsList(checkedhazardsList ? false : true);  setCheckedItem(item);}}></span>
																		// 		{/* <span onClick={(e) => {e.preventDefault(); setCheckedIdhazardsList(item.id); setCheckedhazardsList(checkedhazardsList ? false : true);  setCheckedItem(item);}}></span> */}

																		// 		<Popover content={item.hazardsList}><div >{item.hazardsList}</div></Popover>
																			
																		// 		<div>
																		// 			<Popover content={item.attention != "" ? item.attention : <div className="autofilss">
																		// 				{addImportant2 ? 
																		// 				<input type="text" className="autofilssInput" style={{color: "#000"}} value={attention2} onChange={(e) => setAttention2(e.target.value)}></input>
																		// 				:
																		// 					<button onClick={(e) => {e.preventDefault(); setAddImportant2(true)}}>+ Add</button>
																		// 				}
																		// 				</div>} title="Important">
																		// 				<Button className='autofilssButton'>I</Button>
																		// 			</Popover>
																		// 			<Popover content={item.history != "" ? item.history : <div className="autofilss">
																		// 				{addHistory2 ? 
																		// 				<input type="text" className="autofilssInput" style={{color: "#000"}} value={history2} onChange={(e) => setHistory2(e.target.value)}></input>
																		// 				:
																		// 					<button onClick={(e) => {e.preventDefault(); setAddHistory2(true)}}>+ Add</button>
																		// 				}
																		// 				</div>} title="History">
																		// 				<Button className='autofilssButton'>H</Button>
																		// 			</Popover>
																		// 			<Popover content={item.training != "" ? item.training : <div className="autofilss">
																		// 				{addTraining2 ? 
																		// 				<input type="text" className="autofilssInput" style={{color: "#000"}} value={training2} onChange={(e) => setTraining2(e.target.value)}></input>
																		// 				:
																		// 					<button onClick={(e) => {e.preventDefault(); setAddTraining2(true)}}>+ Add</button>
																		// 				}
																		// 				</div>
																		// 			} title="Training">
																		// 				<Button className='autofilssButton'>T</Button>
																		// 			</Popover>
																		// 		</div>
																		// 	</div>
																		// }
																		// </>
																		(typeof saveData == 'object')
																		? <TableCellIHT item={item} data={JSON.parse(hazardData).find(obj => Object.keys(obj) == item.id)}/>
																		: <TableCellIHT item={item} />
																		
																	))}
																	{	(typeof saveData == 'object')
																		? <TableCellIHTNew hazardNew={hazardDataNew} attentionNew={attentionDataNew} historyNew={historyDataNew} trainingNew={trainingDataNew}/>
																		: <TableCellIHTNew />}
																	{/* <div className="autofilss">
																		{<TableCellIHT addNew={true} />}
																	</div> */}
																</div>
															</div>
														</div>
													</div>
													<div className="footsubmitem">
														<div className="footsubmitem-title nonetitle"></div>
														<div className="footsubmitem-bottitle">
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/threatrisk.svg"/><span>THREAT (RISK)</span></div>
																<div className="listbu">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																		// <>
																		// 	<div className="hovers" style={item.threat[0] === "" ? {overflow: "hidden"} : {}}>    
																		// 		<ul style={(item.threat[0] === "") ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																		// 			{item.threat.map((index) => (
																		// 				<>	
																		// 					{index === "" 
																		// 						?
																		// 						<div className="autofilss" key={index.id} >
																		// 							{addThreat2 && checkedIdthreat == item.id 
																		// 								? 
																		// 								<input type="text" className="autofilssInput" value={checkedIdthreat == item.id ? threat2 : ""} onChange={(e) => {setThreat2(e.target.value); setCheckedItem(item); setCheckedIdthreat(item.id)}}></input>
																		// 								:
																		// 								<button onClick={(e) => {e.preventDefault(); setAddThreat2(true); setCheckedIdthreat(item.id)}}>+ Add</button>
																		// 							}
																		// 						</div> 
																		// 						: 
																		// 						<> 
																		// 							<li className="autofilss" key={index.id} >
																		// 								<input type="checkbox" checked={(threat.includes(index) && checkedIdthreat == item.id) ? true : false}/>
																		// 								<span onClick={(e) => {e.preventDefault(); setThreat(threat.includes(index) ? threat.filter((i) =>  i !== index) : [...threat, index]); setCheckedIdthreat(item.id); console.log("!!!!!!", threat.includes(index),threat, index, checkedIdthreat == item.id, checkedIdthreat, item.id)}}></span>
																		// 								<Popover content={index}>
																		// 									<div className="truncate" style={{width: "300px"}}>{index}</div>
																		// 								</Popover>
																									
																		// 							</li>
																		// 						</>
																		// 					}
																		// 				</>
																		// 			))}
																		// 			<div className="autofilss">
																		// 				{addThreat2 && checkedIdthreat == item.id 
																		// 					? 
																		// 					<input type="text" className="autofilssInput" value={checkedIdthreat == item.id ? threat2 : ""} onChange={(e) => {setThreat2(e.target.value); setCheckedItem(item); setCheckedIdthreat(item.id)}}></input>
																		// 					:
																		// 					<button onClick={(e) => {e.preventDefault(); setAddThreat2(true) ; setCheckedIdthreat(item.id)}}>+ Add</button>
																		// 				}
																		// 			</div>
																		// 		</ul>
																		// 	</div>
																		// </>
																		(typeof saveData == 'object') 
																		? <TableCellThreat item={item} threatSave={JSON.parse(threatData).find(obj => Object.keys(obj) == item.id)} key={item.id}/>
																		: <TableCellThreat item={item} key={item.id}/>
																	))}
																		{	(typeof saveData == 'object')
																		? <TableCellThreatNew threatNew={threatDataNew} />
																		: <TableCellThreatNew />}
																		{/* {<TableCellThreatNew />} */}
																		{/* <div className="autofilss">
																			<TableCellThreat addNew={true} />
																		</div> */}
																</div>
															</div>
														</div>
													</div>
													<div className="footsubmitem">
														<div className="footsubmitem-title">Event (Risk Effect)</div>
														<div className="footsubmitem-bottitle">
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/PHYSICALLY.svg"/><span>PHYSICALLY</span></div>
																<div className="listbu">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																// 		<>		 
																// 		<div className="hovers" style={item.physically[0] === "" ? {overflow: "hidden"} : {}}>
																	
																// 	<ul style={item.physically[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																// {item.physically.map((index) => (

																// 		<>	
																// 		{index === "" ? <div className="autofilss" key={index.id} >{addPhysically2 && checkedIdphysically == item.id ? 
																// 		<input type="text" className="autofilssInput" value={checkedIdphysically == item.id ? physically2 : ""} onChange={(e) => {setPhysically2(e.target.value); setCheckedItem(item); setCheckedIdphysically(item.id)}}></input>
																// 		:
																// 			<button onClick={(e) => {e.preventDefault(); setAddPhysically2(true); setCheckedIdphysically(item.id)}}>+ Add</button>
																// 		}</div> : 
																// 		<> 
																// 	<li className="autofilss" key={index.id} >
																// 	<input type="checkbox" checked={(physically.includes(index) && checkedIdphysically == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setPhysically(physically.includes(index) ? physically.filter((i) =>  i !== index) : [...physically, index]); setCheckedIdphysically(item.id)}}></span>
																// 	<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																	
																// 	</li>
																// 	</>
																// 	}
																// 	</>
																// 	))}
																// 	<div className="autofilss">
																// 	{addPhysically2 && checkedIdphysically == item.id ? 
																// 		<input type="text" className="autofilssInput" value={checkedIdphysically == item.id ? physically2 : ""} onChange={(e) => {setPhysically2(e.target.value); setCheckedItem(item); setCheckedIdphysically(item.id)}}></input>
																// 		:
																// 			<button onClick={(e) => {e.preventDefault(); setAddPhysically2(true); setCheckedIdphysically(item.id)}}>+ Add</button>
																// 		}
																// 	</div>
																// 	</ul>
																// 	</div>
																// 		</>
																	(typeof saveData == 'object') 
																	? <TableCellPhysically item={item} physicallySave={JSON.parse(physicallyData).find(obj => Object.keys(obj) == item.id)} key={item.id}/>
																	: <TableCellPhysically item={item} key={item.id}/>
																	))}
																	{	(typeof saveData == 'object')
																	? <TableCellPhysicallyNew physicallyNew={physicallyDataNew} />
																	: <TableCellPhysicallyNew />}
																	{/* {<TableCellPhysicallyNew />} */}
																	{/* <div className="autofilss">
																		<TableCellPhysically addNew={true} />
																	</div> */}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/MENTALLY.svg"/><span>MENTALLY</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																// 	<> 
																// 		<div className="hovers" style={item.mentally[0] === "" ? {overflow: "hidden"} : {}}>
																	
																// 	<ul style={item.mentally[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																// {item.mentally.map((index) => (

																// 		<>	
																// 		{index === "" ? <div className="autofilss" key={index.id} >{addMentally2 && checkedIdmentally == item.id ? 
																// 	<input type="text" className="autofilssInput" value={checkedIdmentally == item.id ? mentally2 : ""} onChange={(e) => {setMentally2(e.target.value); setCheckedItem(item); setCheckedIdmentally(item.id)}}></input>
																// 	:
																// 		<button onClick={(e) => {e.preventDefault(); setAddMentally2(true); setCheckedIdmentally(item.id)}}>+ Add</button>
																// 	}</div> : 
																// 		<> 
																// 	<li className="autofilss" key={index.id} >
																// 	<input type="checkbox" checked={(mentally.includes(index) && checkedIdmentally == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setMentally(mentally.includes(index) ? mentally.filter((i) =>  i !== index) : [...mentally, index]); setCheckedIdmentally(item.id)}}></span>
																// 	<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																	
																// 	</li>
																// 	</>
																// }
																// </>
																// ))}
																// <div className="autofilss">
																// 	{addMentally2 && checkedIdmentally == item.id ? 
																// 	<input type="text" className="autofilssInput" value={checkedIdmentally == item.id ? mentally2 : ""} onChange={(e) => {setMentally2(e.target.value); setCheckedItem(item); setCheckedIdmentally(item.id)}}></input>
																// 	:
																// 		<button onClick={(e) => {e.preventDefault(); setAddMentally2(true); setCheckedIdmentally(item.id)}}>+ Add</button>
																// 	}
																// 	</div>
																// </ul>
																// </div>
																// 	</>
																	<TableCellMentally item={item} />
																))}
																{<TableCellMentallyNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/EMOTIONALLY.svg"/><span>EMOTIONALLY</span></div>
																<div className="listbu">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																//         <>
																			
																//         <div className="hovers" style={item.emotionally[0] === "" ? {overflow: "hidden"} : {}}>
																	
																//     <ul style={item.emotionally[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																// {item.emotionally.map((index) => (

																//         <>	
																//         {index === "" ? <div className="autofilss" key={index.id} >{addEmotionally2 && checkedIdemotionally == item.id ? 
																//         <input type="text" className="autofilssInput" value={checkedIdemotionally == item.id ? emotionally2 : ""} onChange={(e) => {setEmotionally2(e.target.value); setCheckedItem(item); setCheckedIdemotionally(item.id)}}></input>
																//         :
																//             <button onClick={(e) => {e.preventDefault(); setAddEmotionally2(true); setCheckedIdemotionally(item.id)}}>+ Add</button>
																//         }</div> : 
																//         <> 
																//     <li className="autofilss" key={index.id} >
																//     <input type="checkbox" checked={(emotionally.includes(index) && checkedIdemotionally == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setEmotionally(emotionally.includes(index) ? emotionally.filter((i) =>  i !== index) : [...emotionally, index]); setCheckedIdemotionally(item.id)}}></span>
																//     <Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																	
																//     </li>
																//     </>
																//     }
																//     </>
																//     ))}
																//     <div className="autofilss">
																//         {addEmotionally2 && checkedIdemotionally == item.id ? 
																//         <input type="text" className="autofilssInput" value={checkedIdemotionally == item.id ? emotionally2 : ""} onChange={(e) => {setEmotionally2(e.target.value); setCheckedItem(item); setCheckedIdemotionally(item.id)}}></input>
																//         :
																//             <button onClick={(e) => {e.preventDefault(); setAddEmotionally2(true); setCheckedIdemotionally(item.id)}}>+ Add</button>
																//         }
																//         </div>
																//     </ul>
																//     </div>
																//     </>
																		<TableCellEmotionally item={item} />
																	))}
																	{<TableCellEmotionallyNew />}
																</div>
															</div>
														</div>
													</div>
													<div className="footsubmitem">
														<div className="footsubmitem-title">Who is at Risk</div>
														<div className="footsubmitem-bottitle">
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/PEOPLE.svg"/><span>PEOPLE</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
															//         <>	 
															//         <div className="hovers" style={item.people[0] === "" ? {overflow: "hidden"} : {}}>
																
															//     <ul style={item.people[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
															// {item.people.map((index) => (

															//         <>	
															//         {index === "" ? <div className="autofilss" key={index.id} >{addPeople2 && checkedIdpeople == item.id ? 
															//         <input type="text" className="autofilssInput" value={checkedIdpeople == item.id ? people2 : ""} onChange={(e) => {setPeople2(e.target.value); setCheckedItem(item); setCheckedIdpeople(item.id)}}></input>
															//         :
															//             <button onClick={(e) => {e.preventDefault(); setAddPeople2(true); setCheckedIdpeople(item.id)}}>+ Add</button>
															//         }</div> : 
															//         <> 
															//     <li className="autofilss" key={index.id} >
															//     <input type="checkbox" checked={(people.includes(index) && checkedIdpeople == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setPeople(people.includes(index) ? people.filter((i) =>  i !== index) : [...people, index]); setCheckedIdpeople(item.id)}}></span>
															//     <Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																
															//     </li>
															//     </>
															//     }
															//     </>
															//     ))}
															//     <div className="autofilss">
															//         {addPeople2 && checkedIdpeople == item.id ? 
															//         <input type="text" className="autofilssInput" value={checkedIdpeople == item.id ? people2 : ""} onChange={(e) => {setPeople2(e.target.value); setCheckedItem(item); setCheckedIdpeople(item.id)}}></input>
															//         :
															//             <button onClick={(e) => {e.preventDefault(); setAddPeople2(true); setCheckedIdpeople(item.id)}}>+ Add</button>
															//         }
															//     </div>
															//     </ul>
															//     </div>
															//     	</>
																	<TableCellPeople item={item} />
																))}
																{<TableCellPeopleNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/ENVIRONMENT.svg"/><span>ENVIRONMENT</span></div>
																<div className="listbu">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																	// 	<>
																				
																	// 	<div className="hovers" style={item.environment[0] === "" ? {overflow: "hidden"} : {}}>
																	
																	// <ul style={item.environment[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																	// {item.environment.map((index) => (
																	// <>	
																	// {index === "" ? <div className="autofilss" key={index.id} >{addEnvironment2 && checkedIdenvironment == item.id ? 
																	// 	<input type="text" className="autofilssInput" value={checkedIdenvironment == item.id ? environment2 : ""} onChange={(e) => {setEnvironment2(e.target.value); setCheckedItem(item); setCheckedIdenvironment(item.id)}}></input>
																	// 	:
																	// 		<button onClick={(e) => {e.preventDefault(); setAddEnvironment2(true); setCheckedIdenvironment(item.id)}}>+ Add</button>
																	// 	}</div> : 
																	// <> 
																	// <li className="autofilss" key={index.id} >
																	// <input type="checkbox" checked={(environment.includes(index) && checkedIdenvironment == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setEnvironment(environment.includes(index) ? environment.filter((i) =>  i !== index) : [...environment, index]); setCheckedIdenvironment(item.id)}}></span>
																	// <Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																	
																	// </li>
																	// 	</>
																	// }
																	// </>
																	// ))}
																	// <div className="autofilss">
																	// 	{addEnvironment2 && checkedIdenvironment == item.id ? 
																	// 	<input type="text" className="autofilssInput" value={checkedIdenvironment == item.id ? environment2 : ""} onChange={(e) => {setEnvironment2(e.target.value); setCheckedItem(item) ; setCheckedIdenvironment(item.id)}}></input>
																	// 	:
																	// 		<button onClick={(e) => {e.preventDefault(); setAddEnvironment2(true); setCheckedIdenvironment(item.id)}}>+ Add</button>
																	// 	}
																	// 	</div>
																	// </ul>
																	// </div>
																	// 	</>
																		<TableCellEnvironment item={item} />
																	))}
																	{<TableCellEnvironmentNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/ASSET.svg"/><span>ASSET</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																	// 		<>		 
																	// 		<div className="hovers" style={item.asset[0] === "" ? {overflow: "hidden"} : {}}>
																		
																	// 	<ul style={item.asset[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																	// {item.asset.map((index) => (

																	// 	<>	
																	// 	{index === "" ? <div className="autofilss" key={index.id} >{addAsset2 && checkedIdasset == item.id ? 
																	// 		<input type="text" className="autofilssInput" value={checkedIdasset == item.id ? asset2 : ""} onChange={(e) => {setAsset2(e.target.value); setCheckedItem(item); setCheckedIdasset(item.id)}}></input>
																	// 		:
																	// 			<button onClick={(e) => {e.preventDefault(); setAddAsset2(true); setCheckedIdasset(item.id)}}>+ Add</button>
																	// 		}</div> : 
																	// 	<> 
																	// 	<li className="autofilss" key={index.id} >
																	// 	<input type="checkbox" checked={(asset.includes(index) && checkedIdasset == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setAsset(asset.includes(index) ? asset.filter((i) =>  i !== index) : [...asset, index]); setCheckedIdasset(item.id)}}></span>
																	// 	<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																		
																	// 	</li>
																	// 	</>
																	// 	}
																	// 	</>
																	// 	))}
																	// 	<div className="autofilss">
																	// 		{addAsset2 && checkedIdasset == item.id ? 
																	// 		<input type="text" className="autofilssInput" value={checkedIdasset == item.id ? asset2 : ""} onChange={(e) => {setAsset2(e.target.value); setCheckedItem(item); setCheckedIdasset(item.id)}}></input>
																	// 		:
																	// 			<button onClick={(e) => {e.preventDefault(); setAddAsset2(true); setCheckedIdasset(item.id)}}>+ Add</button>
																	// 		}
																	// 		</div>
																	// 	</ul>
																	// 	</div>
																	// 	</>
																	<TableCellAsset item={item} />
																))}
																{<TableCellAssetNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/REPUTATION.svg"/><span>REPUTATION</span></div>
																<div className="listbu">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																// 		<>
																				
																// 			<div className="hovers" style={item.reputation[0] === "" ? {overflow: "hidden"} : {}}>
																		
																// 		<ul style={item.reputation[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																// 	{item.reputation.map((index) => (

																// 		<>	
																// 		{index === "" ? <div className="autofilss" key={index.id} >{addReputation2 && checkedIdreputation == item.id ? 
																// 			<input type="text" className="autofilssInput" value={checkedIdreputation == item.id ? reputation2 : ""} onChange={(e) => {setReputation2(e.target.value); setCheckedItem(item); setCheckedIdreputation(item.id)}}></input>
																// 			:
																// 				<button onClick={(e) => {e.preventDefault(); setAddReputation2(true); setCheckedIdreputation(item.id)}}>+ Add</button>
																// 			}</div> : 
																// 		<> <li className="autofilss" key={index.id} >
																// 			<input type="checkbox" checked={(reputation.includes(index) && checkedIdreputation == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setReputation(reputation.includes(index) ? reputation.filter((i) =>  i !== index) : [...reputation, index]); setCheckedIdreputation(item.id)}}></span>
																// 			<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																// 		</li>
																// 	</>
																// 	}
																// </>
																// ))}
																// <div className="autofilss">
																// 			{addReputation2 && checkedIdreputation == item.id ? 
																// 			<input type="text" className="autofilssInput" value={checkedIdreputation == item.id ? reputation2 : ""} onChange={(e) => {setReputation2(e.target.value); setCheckedItem(item); setCheckedIdreputation(item.id)}}></input>
																// 			:
																// 				<button onClick={(e) => {e.preventDefault(); setAddReputation2(true); setCheckedIdreputation(item.id)}}>+ Add</button>
																// 			}
																// 			</div>
																// </ul>

																// </div>

																// 		</>
																		<TableCellReputation item={item} />
																	))}
																	{<TableCellReputationNew />}
																</div>
															</div>
														</div>
													</div>
													<div className="footsubmitem">
														<div className="footsubmitem-title">risk rating</div>
														<TableRiskRating data={data} userTask={userTask} />
														{/* <div className="footsubmitem-bottitle">
															<div className="footsubmitem-one oflism1">
																<div className="footsubmitem-one-bottitle noimages"><span>Likelihood</span></div>
																<div className="listbu ">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																	<div className="autofilss" key={item.id}>
																			<div className="listonehood">
																				<label type="button" style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className={`listonehood-one ${
																					selectedItem === item.id && selectedLabels.includes(1) ? `selected${selectedLabel}` : 'selected0'
																					}`}
																					onClick={(e) => {e.preventDefault(); setSelectedItem(item.id); handleLabelClick(item.id, 1); }}>1</label>
																				<label type="button" style={selectedLabel == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																					selectedItem === item.id && selectedLabels.includes(2) ? `selected${selectedLabel}` : ''
																					}`}
																					onClick={(e) => {e.preventDefault(); setSelectedItem(item.id); handleLabelClick(item.id, 2);}}>2</label>
																				<label type="button" style={selectedLabel == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																						selectedItem === item.id && selectedLabels.includes(3) ? `selected${selectedLabel}` : ''
																						}`}
																						onClick={(e) => {e.preventDefault(); setSelectedItem(item.id); handleLabelClick(item.id, 3); }}>3</label>
																				<label type="button" style={selectedLabel == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																					selectedItem === item.id && selectedLabels.includes(4) ? `selected${selectedLabel}` : ''
																					}`}
																					onClick={(e) => {e.preventDefault(); setSelectedItem(item.id); handleLabelClick(item.id, 4);}}>4</label>
																				<label type="button" style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} className={`listonehood-one ${
																					selectedItem === item.id && selectedLabels.includes(5) ? `selected${selectedLabel}` : ''
																					}`}
																					onClick={(e) => {e.preventDefault(); setSelectedItem(item.id); handleLabelClick(item.id, 5);}}>5</label>
																			</div>
																	</div>
																	<TableCellLikehood item={item} />
																	))}
																	<div className="autofilss">
																	{addLikelihood ? 
																	<input type="text" className="autofilssInput" value={likelihood} onChange={(e) => setLikelihood(e.target.value)}></input>
																	:
																		<button onClick={(e) => {e.preventDefault(); setAddLikelihood(true)}}>+ Add</button>
																	}
																	</div>
																</div>
															</div>
															<div className="footsubmitem-one oflism1">
																<div className="footsubmitem-one-bottitle noimages"><span>Severity</span></div>
																<div className="listbu ">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																	// <div className="autofilss" key={item.id}>
																	// 	<div className="listonehood">
																	// 	<label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className={`listonehood-one ${
																	// 			selectedItemSeverity === item.id && selectedLabelsSeverity.includes(1) ? `selected${selectedLabelSeverity}` : 'selected0'
																	// 			}`}
																	// 			onClick={(e) => {e.preventDefault();setSelectedItemSeverity(item.id); handleLabelClickSeverity(item.id, 1); }}>1</label>
																	// 		<label style={selectedLabelSeverity == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																	// 			selectedItemSeverity === item.id && selectedLabelsSeverity.includes(2) ? `selected${selectedLabelSeverity}` : ''
																	// 			}`}
																	// 			onClick={(e) => {e.preventDefault();setSelectedItemSeverity(item.id); handleLabelClickSeverity(item.id, 2); }}>2</label>
																	// 		<label style={selectedLabelSeverity == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																	// 				selectedItemSeverity === item.id && selectedLabelsSeverity.includes(3) ? `selected${selectedLabelSeverity}` : ''
																	// 				}`}
																	// 				onClick={(e) => {e.preventDefault(); setSelectedItemSeverity(item.id); handleLabelClickSeverity(item.id, 3);}}>3</label>
																	// 		<label style={selectedLabelSeverity == "4" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																	// 			selectedItemSeverity === item.id && selectedLabelsSeverity.includes(4) ? `selected${selectedLabelSeverity}` : ''
																	// 			}`}
																	// 			onClick={(e) => {e.preventDefault();setSelectedItemSeverity(item.id); handleLabelClickSeverity(item.id, 4); }}>4</label>
																	// 		<label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} className={`listonehood-one ${
																	// 			selectedItemSeverity === item.id && selectedLabelsSeverity.includes(5) ? `selected${selectedLabelSeverity}` : ''
																	// 			}`}
																	// 			onClick={(e) => {e.preventDefault();setSelectedItemSeverity(item.id); handleLabelClickSeverity(item.id, 5); }}>5</label>
																	// 	</div>
																	// </div>
																	<TableCellSeverity item={item} />
																))}
																	<div className="autofilss">
																	{addseverity ? 
																	<input type="text" className="autofilssInput" value={severity} onChange={(e) => setSeverity(e.target.value)}></input>
																	:
																		<button onClick={(e) => {e.preventDefault(); setAddSeverity(true)}}>+ Add</button>
																	}
																	</div>
																</div>
															</div>
															<div className="footsubmitem-one oflism1">
																<div className="footsubmitem-one-bottitle noimages"><span>Danger Level</span></div>
																<div className="listbu ">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																		// <div className="autofilss" key={item.id}>
																		// 	<div className="listonehood">
																		// 	<label style={{borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px"}} className={`listonehood-one ${
																		// 			(selectedItemSeverity === item.id && selectedItem === item.id) &&  selectedLabelsResult.includes(1) ? `selectedResult${selectedLabelResult}` : 'selected0'
																		// 			}`}>1</label>
																		// 		<label style={selectedLabelResult == "2" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																		// 			(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(2) ? `selectedResult${selectedLabelResult}` : ''
																		// 			}`}>2</label>
																		// 		<label style={selectedLabelResult == "3" ? {borderTopRightRadius: "50px", borderBottomRightRadius: "50px"} : {}} className={`listonehood-one ${
																		// 				(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(3) ? `selectedResult${selectedLabelResult}` : ''
																		// 				}`}>3</label>
																		// 		<label style={{borderTopRightRadius: "50px", borderBottomRightRadius: "50px"}} className={`listonehood-one ${
																		// 			(selectedItemSeverity === item.id && selectedItem === item.id) && selectedLabelsResult.includes(4) ? `selectedResult${selectedLabelResult}` : ''
																		// 			}`}>4</label>
																		// 	</div>
																		// </div>
																		<TableCellDanger item={item} />
																	))}
																	<div className="autofilss">
																		{addDangerLevel ? 
																		<input type="text" className="autofilssInput" value={dangerLevel} onChange={(e) => setDangerLevel(e.target.value)}></input>
																		:
																			<button onClick={(e) => {e.preventDefault(); setAddDangerLevel(true)}}>+ Add</button>
																		}
																	</div>
																</div>
															</div>
														</div> */}
													</div>
													<div className="footsubmitem">
														<div className="footsubmitem-title">COntrol measure</div>
														<div className="footsubmitem-bottitle">
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/KNOWLEDGE.svg"/><span>KNOWLEDGE</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																// 	<>
																		
																// 	<div className="hovers" style={item.knowledge[0] === "" ? {overflow: "hidden"} : {}}>
																
																// <ul style={item.knowledge[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																// {item.knowledge.map((index) => (
																// <>	
																// {index === "" ? <div className="autofilss" key={index.id} >{addKnowledge2 && checkedIdknowledge == item.id ? 
																// 	<input type="text" className="autofilssInput" value={checkedIdknowledge == item.id ? knowledge2 : ""} onChange={(e) => {setKnowledge2(e.target.value); setCheckedItem(item); setCheckedIdknowledge(item.id)}}></input>
																// 	:
																// 		<button onClick={(e) => {e.preventDefault(); setAddKnowledge2(true); setCheckedIdknowledge(item.id)}}>+ Add</button>
																// 	}</div> : 
																// <>
																// <li className="autofilss" key={index.id} >
																// <input type="checkbox" checked={(knowledge.includes(index) && checkedIdknowledge == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setKnowledge(knowledge.includes(index) ? knowledge.filter((i) =>  i !== index) : [...knowledge, index]); setCheckedIdknowledge(item.id)}}></span>
																// <Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																
																// 	</li>
																// 			</>
																// 			}
																// 			</>
																// 		))}
																// 		<div className="autofilss">
																// 	{addKnowledge2 && checkedIdknowledge == item.id ? 
																// 	<input type="text" className="autofilssInput" value={checkedIdknowledge == item.id ? knowledge2 : ""} onChange={(e) => {setKnowledge2(e.target.value); setCheckedItem(item); setCheckedIdknowledge(item.id)}}></input>
																// 	:
																// 		<button onClick={(e) => {e.preventDefault(); setAddKnowledge2(true); setCheckedIdknowledge(item.id)}}>+ Add</button>
																// 	}
																// 	</div>
																// 		</ul>
																
																// 		</div>
																		
																// 	</>
																	<TableCellKnowledge item={item} />
																	))}
																	{<TableCellKnowledgeNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/ACTION.svg"/><span>ACTION</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
															// 		<>
																		
															// 		<div className="hovers" style={item.action[0] === "" ? {overflow: "hidden"} : {}}>
																
															// 	<ul style={item.action[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
															// {item.action.map((index) => (
															// 	<>	
															// 	{index === "" ? <div className="autofilss" key={index.id} >{addAction2 && checkedIdaction == item.id ? 
															// 		<input type="text" className="autofilssInput" value={checkedIdaction == item.id ? action2 : ""} onChange={(e) => {setAction2(e.target.value); setCheckedItem(item); setCheckedIdaction(item.id)}}></input>
															// 		:
															// 			<button onClick={(e) => {e.preventDefault(); setAddAction2(true); setCheckedIdaction(item.id)}}>+ Add</button>
															// 		}</div> : 
															// 	<>
															// 	<li className="autofilss" key={index.id} >
															// 	<input type="checkbox" checked={(action.includes(index) && checkedIdaction == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setAction(action.includes(index) ? action.filter((i) =>  i !== index) : [...action, index]); setCheckedIdaction(item.id)}}></span>
															// 	<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																
															// 		</li>
															// 				</>
															// 				}
															// 				</>
															// 			))}
															// 			<div className="autofilss">
															// 		{addAction2 && checkedIdaction == item.id ? 
															// 		<input type="text" className="autofilssInput" value={checkedIdaction == item.id ? action2 : ""} onChange={(e) => {setAction2(e.target.value); setCheckedItem(item); setCheckedIdaction(item.id)}}></input>
															// 		:
															// 			<button onClick={(e) => {e.preventDefault(); setAddAction2(true); setCheckedIdaction(item.id)}}>+ Add</button>
															// 		}
															// 		</div>
															// 			</ul>
																
															// 			</div>
																		
															// 		</>
																	<TableCellAction item={item} />
																	))}
																	{<TableCellActionNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/BEHAVIOR.svg"/><span>BEHAVIOR</span></div>
																<div className="listbu">
																	{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																// 		<>
																					
																// 		<div className="hovers" style={item.behavior[0] === "" ? {overflow: "hidden"} : {}}>
																	
																// 	<ul style={item.behavior[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																// {item.behavior.map((index) => (
																// 	<>	
																// 	{index === "" ? <div className="autofilss" key={index.id} >{addBehavior2 && checkedIdbehavior == item.id ? 
																// 		<input type="text" className="autofilssInput" value={checkedIdbehavior == item.id ? behavior2 : ""} onChange={(e) => {setBehavior2(e.target.value); setCheckedItem(item); ; setCheckedIdbehavior(item.id)}}></input>
																// 		:
																// 			<button onClick={(e) => {e.preventDefault(); setAddBehavior2(true); setCheckedIdbehavior(item.id)}}>+ Add</button>
																// 		}</div> : 
																// 	<>
																// 	<li className="autofilss" key={index.id} >
																// 	<input type="checkbox" checked={(behavior.includes(index) && checkedIdbehavior == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setBehavior(behavior.includes(index) ? behavior.filter((i) =>  i !== index) : [...behavior, index]); setCheckedIdbehavior(item.id)}}></span>
																// 	<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																	
																
																// 		</li>
																// 				</>
																// 				}
																// 				</>
																// 			))}
																// 			<div className="autofilss">
																// 		{addBehavior2 && checkedIdbehavior == item.id ? 
																// 		<input type="text" className="autofilssInput" value={ checkedIdbehavior == item.id ? behavior2 : ""} onChange={(e) => {setBehavior2(e.target.value); setCheckedIdbehavior(item.id); setCheckedItem(item);}}></input>
																// 		:
																// 			<button onClick={(e) => {e.preventDefault(); setAddBehavior2(true); setCheckedIdbehavior(item.id)}}>+ Add</button>
																// 		}
																// 		</div>
																// 			</ul>
																	
																// 			</div>
																			
																// 		</>
																		<TableCellBehavior item={item} />
																	))}
																	{<TableCellBehaviorNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/REFERENCE.svg"/><span>REFERENCE</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																	// <>
																		
																	// <div className="hovers" style={item.reference[0] === "" ? {overflow: "hidden"} : {}}>
																
																	// 	<ul style={item.reference[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
																	// 	{item.reference.map((index) => (
																	// 		<>	
																	// 		{index === "" ? <div className="autofilss" key={index.id} >{addReference2 && checkedIdreference == item.id ? 
																	// <input type="text" className="autofilssInput" value={checkedIdreference == item.id ? reference2 : ""} onChange={(e) => {setReference2(e.target.value); setCheckedIdreference(item.id); setCheckedItem(item);}}></input>
																	// :
																	// 	<button onClick={(e) => {e.preventDefault(); setAddReference2(true); setCheckedIdreference(item.id);}}>+ Add</button>
																	// }</div> : 
																	// 		<>	  
																	// 		<li className="autofilss" key={index.id} >
																	// 		<input type="checkbox" checked={(reference.includes(index) && checkedIdreference == item.id) ? true : false}/><span onClick={(e) => {e.preventDefault(); setReference(reference.includes(index) ? reference.filter((i) =>  i !== index) : [...reference, index]); setCheckedIdreference(item.id)}}></span>
																	// 		<Popover content={index}><div className="truncate" style={{width: "300px"}}>{index}</div></Popover>
																			
																	// 		</li>
																	// 		</>
																	// 		}
																	// 		</>
																	// 	))}
																	// 	<div className="autofilss">
																	// {addReference2 && checkedIdreference == item.id ? 
																	// <input type="text" className="autofilssInput" value={checkedIdreference == item.id ? reference2 : ""} onChange={(e) => {setReference2(e.target.value); setCheckedItem(item); setCheckedIdreference(item.id);}}></input>
																	// :
																	// 	<button onClick={(e) => {e.preventDefault(); setAddReference2(true); setCheckedIdreference(item.id);}}>+ Add</button>
																	// }
																	// </div>
																	// 	</ul>
																
																	// 	</div>
																		
																	// </>
																	<TableCellReference item={item} />
																	))}
																	{<TableCellReferenceNew />}
																</div>
															</div>
															<div className="footsubmitem-one">
																<div className="footsubmitem-one-bottitle"><img src="/img/REGISTRAR.svg"/><span>REGISTRAR</span></div>
																<div className="listbu">
																{data.risks.filter(item => item.category == userTask).filter(item => item.approve).map((item) => (
																	// <>
																	// {console.log(item)}
																	// {item.registrar === "" 
																	// ? 
																	// <div className="autofilss" key={item.id}>
																	// 	<input type="text" className="autofilssInput" value={user.name} onChange={(e) => {setRegistrar2(e.target.value);  setCheckedIdregistrar(item.id);}}></input>
																	// 	{/* {addRegistrar2 && checkedIdregistrar == item.id
																	// 	? 
																	// 	<input type="text" className="autofilssInput" value={checkedIdregistrar == item.id ? registrar2 : ""} onChange={(e) => {setRegistrar2(e.target.value);  setCheckedIdregistrar(item.id);}}></input>
																	// 	:
																	// 	<button onClick={(e) => {e.preventDefault(); setAddRegistrar2(true); setCheckedIdregistrar(item.id);}}>+ Add</button>
																	// 	} */}
																	// </div> 
																	// : 
																	// <div className="autofilss" key={item.id}>
																	// 	<span><input type="checkbox"/></span>
																	// 	<Popover content={item.registrar}><div className="truncate" style={{width: "300px"}}>{item.registrar}</div></Popover>
																			
																	// </div>}
																	// </>
																	<TableCellRegistrar item={item} />
																	))}
																	<div className="autofilss">
																	<input type="text" className="autofilssInput" placeholder={(userName == '') ? userEmail : userName} value={registrar} onChange={(e) => setRegistrar(e.target.value)}></input>
																	{/* {addRegistrar ? 
																	<input type="text" className="autofilssInput" value={registrar} onChange={(e) => setRegistrar(e.target.value)}></input>
																	:
																		<button onClick={(e) => {e.preventDefault(); setAddRegistrar(true)}}>+ Add</button>
																	} */}
																	</div>
																</div>
															</div>
															
														</div>
													</div>
												</div>
												
											</div>
											<div className="footsubm" style={{display: "flex"}}>
												<input type="submit" placeholder="" onClick={handleOnSubmit} value="SUBMIT"/>
												<input type="submit" placeholder="" onClick={handleOnSave} value="SAVE TEMPLATE"/>
											</div>
											
										</form>
									</div>
									<div className="footsubm-head" style={{overflowX: "hidden", width: "1080px", marginTop: "40px"}}>
										<div className="footsubm-headin">
											<div className="footsubmitem">
												<div className="footsubmitem-title" style={{width: "100%", marginBottom: "3px"}}>legend</div>
												<div className="footsubmitem-bottitle">
													<div className="footsubmitem-one">
														<div className="footsubmitem-title">
															<Popover content={"Likelihood: the probability of a hazard causing harm"}>
															<span>Likelihood</span>
															</Popover>
															
														</div>
														<div className="listbu">
															<Popover content={"Good controls in place. Controls do not rely on people using them. Very unlikely to breakdown. People rarely engage in this activity. Score (1)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
																<div className="listonehood" style={{padding: "0px", background: "#FCE5E5", minWidth: "50px", maxWidth: "50px", margin: "0px 10px 0px 0px"}}>
																	<div className="listonehood-one"></div>	
																</div>
																<div>Remote</div>			
															</div>
															</>
															</Popover>
															<Popover content={"Reasonable controls in place but they do rely on people using them. Controls unlikely to breakdown. This situation is unlikely. Score(2)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
																<div className="listonehood" style={{padding: "0px", background: "#F6BFBF", minWidth: "90px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>	
																</div>
																<div>Improbable</div>		
															</div>
															</>
															</Popover>
															<Popover content={"Inadequate controls in place or are likely to breakdown. Controls rely on personal compliance. This situation sometimes arises. Score(3)"}>	
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
																<div className="listonehood" style={{padding: "0px", background: "#ED7F7F", minWidth: "130px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Occasional</div>		
															</div>
															</>
															</Popover>
															<Popover content={"Poor controls in place. Heavy reliance on personal compliance (lots of room for human error). The situation often arises. Score(4)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
																<div className="listonehood" style={{padding: "0px", background: "#E44040", minWidth: "170px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Very Likely</div>		
															</div>
															</>
															</Popover>	
															<Popover content={"No controls in place, exposure to the hazard are expected to occur in most circumstances. Score(5)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start", display: "flex"}}>
																<div className="listonehood" style={{padding: "0px", background: "#DB0000", minWidth: "210px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Almost Certain</div>		
															</div>
															</>
															</Popover>					
														</div>
													</div>
												
													<div className="footsubmitem-one">
														<div className="footsubmitem-title" style={{width: "98%", marginLeft: "1px"}}>
															<Popover content={"Severity: the consequences of any resulting injury or ill-health"}>
															<span>Severity</span>
															</Popover>
														</div>
														<div className="listbu">
															<Popover content={"Insignificant injury, health effects, damage or disruption to work. Score(1)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#FCE5E5", minWidth: "50px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>	
																</div>
																<div>Minor</div>			
															</div>
															</>
															</Popover>
															<Popover content={"Minor loss of process / slight damage to property & can cause injuries. Score(2)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#F6BFBF", minWidth: "90px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>	
																</div>
																<div>Serious</div>		
															</div>
															</>
															</Popover>
															<Popover content={"Moderate loss of process / damage to property & reportable injuries causing disability. Score(3)"}>	
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#ED7F7F", minWidth: "130px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Reportable</div>		
															</div>
															</>
															</Popover>
															<Popover content={"Critical loss of process / damage to property / Major injuries & fatality. Score(4)"}>	
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#E44040", minWidth: "170px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Major</div>		
															</div>
															</>
															</Popover>
															<Popover content={"Lost of business & multiple fatalities. Score(5)"}>	
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#DB0000", minWidth: "210px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Catastrophic</div>		
															</div>
															</>
															</Popover>										
														</div>
													</div>
												
													<div className="footsubmitem-one">
														<div className="footsubmitem-title">
														<Popover content={"Result: Results of danger level"}>
															<span>Result</span>
														</Popover>
														</div>
														<div className="listbu">
															<Popover content={"low risk -> (0-5)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#2BE564", minWidth: "50px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>	
																</div>
																<div>Low Risk</div>			
															</div>
															</>
															</Popover>
															<Popover content={"moderate risk -> (6-10)"}>
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#E5DE2B", minWidth: "90px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>	
																</div>
																<div>Moderate Risk</div>		
															</div>
															</>
															</Popover>
															<Popover content={"high risk -> (11-15)"}>	
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#DB0000", minWidth: "130px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>High Risk</div>		
															</div>
															</>
															</Popover>
															<Popover content={"extremely high -> (16-25)"}>	
															<>
															<div className="autofilss" style={{padding: "0px", border: "none", justifyContent: "start"}}>
																<div className="listonehood" style={{padding: "0px", background: "#0C0C0C", minWidth: "170px", margin: "0px 10px 0px 0px"}}>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>
																	<label className="listonehood-one"></label>		
																</div>
																<div>Extremely High Risk</div>		
															</div>
															</>	
															</Popover>					
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
						: 
						<Main />
					}
					<div>
						<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModal} onCancel={closeModal} id="getmylogin"  footer={null}>
							<div className="succespopup aboutforms">
								<div className="succtext1">Your Hazard & Risk Assessment form has been submitted</div>
								<div className="succtext1"><img src="/img/task_alt.svg"/></div>
								<div data-fancybox-close className="succtext3"  type="button" onClick={closeModal}>enter</div>
							</div>
						</Modal>
						<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModalSave} onCancel={closeModalSave} id="getmylogin"  footer={null}>
							<div className="succespopup aboutforms">
								<div className="succtext1">Your Hazard & Risk Assessment form has been saved</div>
								<div className="succtext1"><img src="/img/task_alt.svg"/></div>
								<div data-fancybox-close className="succtext3"  type="button" onClick={closeModalSave}>ok</div>
							</div>
						</Modal>
						<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModalApprove} onCancel={closeModalApprove} id="getmylogin"  footer={null}>
							<div className="succespopup aboutforms">
								<div className="succtext1">Hazard & Risk Assessment has been Approve</div>
								<div className="succtext1"><img src="/img/task_alt.svg"/></div>
								<div data-fancybox-close className="succtext3"  type="button" onClick={closeModalApprove}>enter</div>
							</div>
						</Modal>
						<Modal style={{padding: 0, border: "1px solid #E7DB59", borderRadius: "4px"}} open={openModalDecline} onCancel={closeModalDecline} id="getmylogin"  footer={null}>
							<div className="succespopup aboutforms">
								<div className="succtext1">Hazard & Risk Assessment has been Decline</div>
								<div className="succtext1"><img src="/img/task_alt.svg"/></div>
								<div data-fancybox-close className="succtext3"  type="button" onClick={closeModalDecline}>enter</div>
							</div>
						</Modal>
					</div>
				</div>
				: 
				<Spin style={{margin: "auto"}}></Spin> 
			}
		</>
	)
}
export default RiskTable;