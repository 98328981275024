import axios from "axios";

// const apiUrl = process.env.API_BASE_URL
const API_URL = process.env.REACT_APP_API_BASE_URL;


const $api = axios.create({
    // withCredentials: true,
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('authToken')}`
    return config;
})

export default $api;