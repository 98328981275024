import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setReference, setNewReference } from "../../slices/riskSlice";

const TableCellReference = ({item, addNew}) => {

    const [reference2, setReference2] = useState([]);
    const [checkedIdreference, setCheckedIdreference] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [addReference, setAddReference] = useState([]);
    const [addReference2, setAddReference2] = useState([]);
    const [reference, setStateReference] = useState([]);
    const dispatch = useDispatch();

    return (
        <>		
            {/* {(!item && addNew) &&
                <>
                    {addReference ? 
                        <input type="text" className="autofilssInput" value={reference} onChange={(e) => dispatch(setNewReference(e.target.value)) && setStateReference(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddReference(true)}}>+ Add</button>
                    }
                </>
            }												 */}
            {item &&
                <div class="hovers" style={item.reference[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.reference[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.reference.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addReference.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setReference2(
                                                                (reference2.some((el, ind) => ind == indx)) 
                                                                ? reference2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...reference2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setReference(
                                                                    [   
                                                                        item.id, 
                                                                        (reference2.some((el, ind) => ind == indx)) 
                                                                        ? [...reference2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...reference2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddReference([...addReference, true]); setCheckedIdreference(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addReference2 && checkedIdreference == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdreference == item.id ? reference2 : ""} onChange={(e) => {dispatch(setReference([item.id, [e.target.value]])) && setReference2(e.target.value); setCheckedIdreference(item.id); setCheckedItem(item);}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddReference2(true); setCheckedIdreference(item.id);}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <>	  
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(reference.includes(index) && checkedIdreference == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault(); 
                                                reference.some(i => i == index)
                                                    ? dispatch(setReference([item.id, (reference2.length != 0) ? [...reference.filter((i) =>  i !== index), ...reference2] : reference.filter((i) =>  i !== index)])) && setStateReference(reference.filter((i) =>  i !== index))
                                                    : dispatch(setReference([item.id, (reference2.length != 0) ? [...[...reference, index], ...reference2] : [...reference, index]])) && setStateReference([...reference, index])
                                                setCheckedIdreference(item.id)}
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setReference(reference.includes(index) ? reference.filter((i) =>  i !== index) : [...reference, index]); setCheckedIdreference(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addReference2 && checkedIdreference == item.id 
                            && 
                            addReference2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setReference2(
                                            (reference2.some((el, ind) => ind == index)) 
                                            ? reference2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...reference2, e.target.value]
                                        ); 
                                        dispatch(
                                            setReference([item.id,
                                                (reference2.some((el, ind) => ind == index)) 
                                                ? [...reference, ...[...reference2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...reference, ...[...reference2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.reference[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddReference2([...reference2, true]); setCheckedIdreference(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addReference2 && checkedIdreference == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={checkedIdreference == item.id ? reference2 : ""} onChange={(e) => {dispatch(setReference([item.id, [...reference, e.target.value]])) && setReference2([e.target.value]); setCheckedItem(item); setCheckedIdreference(item.id);}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddReference2(true); setCheckedIdreference(item.id);}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellReference;