import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setAction, setNewAction } from "../../slices/riskSlice";

const TableCellActionNew = ({item, addNew}) => {

    const dispatch = useDispatch();
    const [action2, setStateAction2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdaction, setCheckedIdaction] = useState("");

	const [addAction2, setAddAction2] = useState(false);
    const [action, setStateAction] = useState([]);
    const [actionArray, setStateActionArray] = useState([]);
    
    const [actionArrayUl, setStateActionArrayUl] = useState([]);

    return (
        <>
            {actionArray.map((element, index) => 
                <>
                    {element
                        &&
                        <div className={`hovers ${index}`}>
                            <ul style={{padding: '0', margin: '0'}}>
                                {
                                    // console.log(actionArrayUl)
                                    actionArrayUl.map((item, indx) =>
                                        (indx == index) && item.map((i, ind) =>
                                        i &&
                                        <li class="autofilss">
                                            <input type="text" className="autofilssInput" onChange={(e) => dispatch(setNewAction([index.toString(), ind, e.target.value ]))}></input>
                                        </li>
                                    ))
                                }
                                <div className="autofilss">
                                    <button onClick={(e) => {e.preventDefault(); setStateActionArrayUl(actionArrayUl.map((item, ind) => (ind == index) ? item = [...item, true] : item));}}>+ Add</button>
                                </div>  
                            </ul>
                        </div>   
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStateActionArray([...actionArray, true]); setStateActionArrayUl([...actionArrayUl, [true]])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellActionNew;