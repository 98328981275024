import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setThreat, setNewThreat } from "../../slices/riskSlice";
import { setAttention, setHistory, setTraining, setHazardList, setNewHazardList, unsetHazardList } from "../../slices/riskSlice";

const TableCellIHTNew = ({hazardNew, attentionNew, historyNew, trainingNew}) => {

    const dispatch = useDispatch();
    const [hazardsArray, setStateHazardsArray] = useState((typeof hazardNew != 'undefined' && hazardNew.length > 0) ? [...hazardNew.map(item => item = Object.values(item))].flat() : []);
    const [hazardsArrayDiv, setStateHazardsArrayDiv] = useState([]);
    
    // const [attention, setStateAttention] = useState((typeof attentionNew != 'undefined') ? [...attentionNew.map(item => Object.values(item))].flat() : []);
    const [addImportant, setAddImportant] = useState((typeof attentionNew != 'undefined' && attentionNew.length > 0) ? [...attentionNew.map(item => Object.values(item))].flat() : [false]);
    // const [history, setStateHistory] = useState((typeof historyNew != 'undefined') ? [...historyNew.map(item => Object.values(item))].flat() : []);
    const [addTraining, setAddTraining] = useState((typeof historyNew != 'undefined' && historyNew.length > 0) ? [...historyNew.map(item => Object.values(item))].flat() : [false]);
    // const [training, setStateTraining] = useState((typeof trainingNew != 'undefined') ? [...trainingNew.map(item => Object.values(item))].flat() : []);
    const [addHistory, setAddHistory] = useState((typeof trainingNew != 'undefined' && trainingNew.length > 0) ? [...trainingNew.map(item => Object.values(item))].flat() : [false]);

    // console.log(hazardsArray)
    // console.log(typeof hazardNew)
    // console.log(hazardNew.length)

    return (
        <>
            {hazardsArray.map((element, index) => 
                <>
                    {element &&
                        <div className="autofilss" key={index}>
                            <input type="text" defaultValue={(typeof element == 'string') ? element : ''} className="autofilssInput"  onChange={(e) => {e.preventDefault(); dispatch(setNewHazardList([index, e.target.value]))}}></input>
                            <div>
                                <Popover content={ <div className="autofilss">
                                    {(typeof addImportant[index] != 'undefined') && addImportant[index] ? 
                                    <input type="text" defaultValue={(addImportant.some((item, ind) => ind == index && typeof item == 'string')) ? addImportant[index] : ''} className="autofilssInput" style={{color: "#000"}} onChange={(e) => dispatch(setAttention([index, e.target.value]))}></input>
                                    :
                                        <button onClick={(e) => {e.preventDefault(); setAddImportant((addImportant[index] == false) ? [true] : [...addImportant, true])}}>+ Add</button>
                                    }
                                    </div>} title="Important">
                                    <Button className='autofilssButton'>I</Button>
                                </Popover>
                                <Popover content={ <div className="autofilss">
                                    {(typeof addHistory[index] != 'undefined') && addHistory[index] ? 
                                    <input type="text" defaultValue={(addHistory.some((item, ind) => ind == index && typeof item == 'string')) ? addHistory[index] : ''} className="autofilssInput" style={{color: "#000"}} onChange={(e) => dispatch(setHistory([index, e.target.value]))}></input>
                                    :
                                        <button onClick={(e) => {e.preventDefault(); setAddHistory((addHistory[index] == false) ? [true] : [...addHistory, true])}}>+ Add</button>
                                    }
                                    </div>} title="History">
                                    <Button className='autofilssButton'>H</Button>
                                </Popover>
                                <Popover content={ <div className="autofilss">
                                    {(typeof addTraining[index] != 'undefined') && addTraining[index] ? 
                                    <input type="text" defaultValue={(addTraining.some((item, ind) => ind == index && typeof item == 'string')) ? addTraining[index] : ''} className="autofilssInput" style={{color: "#000"}} onChange={(e) => dispatch(setTraining([index, e.target.value]))}></input>
                                    :
                                        <button onClick={(e) => {e.preventDefault(); setAddTraining((addTraining[index] == false) ? [true] : [...addTraining, true])}}>+ Add</button>
                                    }
                                    </div>
                                } title="Training">
                                    <Button className='autofilssButton'>T</Button>
                                </Popover>
                            </div>
                        </div>  
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStateHazardsArray([...hazardsArray, true])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellIHTNew;