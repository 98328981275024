import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setReference, setNewReference } from "../../slices/riskSlice";

const TableCellReferenceNew = ({item, addNew}) => {

    const dispatch = useDispatch();
    const [reference2, setStateReference2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdreference, setCheckedIdreference] = useState("");

	const [addReference2, setAddReference2] = useState(false);
    const [reference, setStateReference] = useState([]);
    const [referenceArray, setStateReferenceArray] = useState([]);
    
    const [referenceArrayUl, setStateReferenceArrayUl] = useState([]);

    return (
        <>
            {referenceArray.map((element, index) => 
                <>
                    {element
                        &&
                        <div className={`hovers ${index}`}>
                            <ul style={{padding: '0', margin: '0'}}>
                                {
                                    // console.log(referenceArrayUl)
                                    referenceArrayUl.map((item, indx) =>
                                        (indx == index) && item.map((i, ind) =>
                                        i &&
                                        <li class="autofilss">
                                            <input type="text" className="autofilssInput" onChange={(e) => dispatch(setNewReference([index.toString(), ind, e.target.value ]))}></input>
                                        </li>
                                    ))
                                }
                                <div className="autofilss">
                                    <button onClick={(e) => {e.preventDefault(); setStateReferenceArrayUl(referenceArrayUl.map((item, ind) => (ind == index) ? item = [...item, true] : item));}}>+ Add</button>
                                </div>  
                            </ul>
                        </div>   
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStateReferenceArray([...referenceArray, true]); setStateReferenceArrayUl([...referenceArrayUl, [true]])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellReferenceNew;