import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/userSlise.js';
import riskReducer from '../slices/riskSlice.js';
import ratingReducer from '../slices/ratingSlice.js';

const reducer = {
  user: userReducer,
  risk: riskReducer,
  rating: ratingReducer,
}

export default configureStore({
  reducer: reducer,
  devTools: true,
  // reducer: {
  //   user: userReducer,
  //   risk: riskReducer,
  // },
});