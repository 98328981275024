import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setAction, setNewAction} from "../../slices/riskSlice";

const TableCellAction = ({item, addNew}) => {
    const [action2, setAction2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdaction, setCheckedIdaction] = useState("");
    const [addAction, setAddAction] = useState([]);
    const [addAction2, setAddAction2] = useState([]);
    const [action, setStateAction] = useState([]);
    const dispatch = useDispatch();

    return (
        <>		
            {/* {(!item && addNew) &&
                <>
                    {addAction ? 
                        <input type="text" className="autofilssInput" value={action} onChange={(e) => dispatch(setNewAction(e.target.value)) && setStateAction(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddAction(true)}}>+ Add</button>
                    }
                </>
            }												 */}
            {item &&
                <div class="hovers" style={item.action[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.action[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.action.map((index) => (
                            <>	
                            {index === "" 
                                ? 
                                <div class="hovers" >
                                    <ul style={{padding: "0px", margin: "0px"}}>
                                        {addAction.map((elem,indx) => 
                                            elem &&
                                            <li class="autofilss" key={index.id}>
                                                <input type="text" class="autofilssInput" onChange={(e) => 
                                                    {
                                                        e.preventDefault(); 
                                                        // console.log(e.target.value)
                                                        setAction2(
                                                            (action2.some((el, ind) => ind == indx)) 
                                                            ? action2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                            : [...action2, e.target.value]
                                                        );
                                                        dispatch(
                                                            setAction(
                                                                [   
                                                                    item.id, 
                                                                    (action2.some((el, ind) => ind == indx)) 
                                                                    ? [...action2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                    : [...action2, e.target.value]
                                                                ]
                                                            )
                                                        );
                                                    }
                                                }></input>
                                            </li>
                                        )}
                                        <div class="autofilss">
                                            <button onClick={(e) => {e.preventDefault(); setAddAction([...addAction, true]); setCheckedIdaction(item.id)}}>+ Add</button>
                                        </div>                                        
                                    </ul>
                                </div>
                                // <div class="autofilss" key={index.id} >
                                //     {addAction2 && checkedIdaction == item.id 
                                //         ? 
                                //         <input type="text" class="autofilssInput" value={checkedIdaction == item.id ? action2 : ""} onChange={(e) => {dispatch(setAction([item.id, [e.target.value]])) && setAction2(e.target.value); setCheckedItem(item); setCheckedIdaction(item.id)}}></input>
                                //         :
                                //         <button onClick={(e) => {e.preventDefault(); setAddAction2(true); setCheckedIdaction(item.id)}}>+ Add</button>
                                //     }
                                // </div> 
                                : 
                                <>
                                    <li class="autofilss" key={index.id} >
                                        <input type="checkbox" checked={(action.includes(index) && checkedIdaction == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                        <span onClick={(e) => {e.preventDefault(); 
                                            action.some(i => i == index)
                                                ? dispatch(setAction([item.id, (action2.length != 0) ? [...action.filter((i) =>  i !== index), ...action2] : action.filter((i) =>  i !== index)])) && setStateAction(action.filter((i) =>  i !== index))
                                                : dispatch(setAction([item.id, (action2.length != 0) ? [...[...action, index], ...action2] : [...action, index]])) && setStateAction([...action, index])
                                            setCheckedIdaction(item.id)}
                                        }></span>
                                        {/* <span onClick={(e) => {e.preventDefault(); setAction(action.includes(index) ? action.filter((i) =>  i !== index) : [...action, index]); setCheckedIdaction(item.id)}}></span> */}
                                        <Popover content={index}>
                                            <div class="truncate" style={{width: "300px"}}>{index}</div>
                                        </Popover>
                                    </li>
                                </>
                            }
                            </>
                        ))}
                        {addAction2 && checkedIdaction == item.id 
                            && 
                            addAction2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setAction2(
                                            (action2.some((el, ind) => ind == index)) 
                                            ? action2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...action2, e.target.value]
                                        ); 
                                        dispatch(
                                            setAction([item.id,
                                                (action2.some((el, ind) => ind == index)) 
                                                ? [...action, ...[...action2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...action, ...[...action2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.action[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddAction2([...action2, true]); setCheckedIdaction(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addAction2 && checkedIdaction == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={checkedIdaction == item.id ? action2 : ""} onChange={(e) => {dispatch(setAction([item.id, [...action, e.target.value]])) && setAction2([e.target.value]); setCheckedItem(item); setCheckedIdaction(item.id)}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddAction2(true); setCheckedIdaction(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellAction;