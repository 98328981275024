import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setKnowledge, setNewKnowledge } from "../../slices/riskSlice";

const TableCellKnowledge = ({item, addNew}) => {

    const [knowledge2, setKnowledge2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdknowledge, setCheckedIdknowledge] = useState("");
    const [addKnowledge, setAddKnowledge] = useState([]);
    const [addKnowledge2, setAddKnowledge2] = useState([]);
    const [knowledge, setStateKnowledge] = useState([]);
    const dispatch = useDispatch();

    return (
        <>       
            {/* {(!item && addNew) &&
                <>
                    {addKnowledge ? 
                        <input type="text" className="autofilssInput" value={knowledge} onChange={(e) => dispatch(setNewKnowledge(e.target.value)) && setStateKnowledge(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddKnowledge(true)}}>+ Add</button>
                    }
                </>
            }                                                        */}
            {item &&
                <div class="hovers" style={item.knowledge[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.knowledge[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.knowledge.map((index) => (
                            <>	
                                {index === "" 
                                    ? 
                                    <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addKnowledge.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            // console.log(e.target.value)
                                                            setKnowledge2(
                                                                (knowledge2.some((el, ind) => ind == indx)) 
                                                                ? knowledge2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...knowledge2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setKnowledge(
                                                                    [   
                                                                        item.id, 
                                                                        (knowledge2.some((el, ind) => ind == indx)) 
                                                                        ? [...knowledge2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...knowledge2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddKnowledge([...addKnowledge, true]); setCheckedIdknowledge(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addKnowledge2 && checkedIdknowledge == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdknowledge == item.id ? knowledge2 : ""} onChange={(e) => {dispatch(setKnowledge([item.id, [e.target.value]])) && setKnowledge2(e.target.value); setCheckedItem(item); setCheckedIdknowledge(item.id)}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddKnowledge2(true); setCheckedIdknowledge(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <>
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(knowledge.includes(index) && checkedIdknowledge == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault(); 
                                                knowledge.some(i => i == index)
                                                    ? dispatch(setKnowledge([item.id, (knowledge2.length != 0) ? [...knowledge.filter((i) =>  i !== index), ...knowledge2] : knowledge.filter((i) =>  i !== index)])) && setStateKnowledge(knowledge.filter((i) =>  i !== index))
                                                    : dispatch(setKnowledge([item.id, (knowledge2.length != 0) ? [...[...knowledge, index], ...knowledge2] : [...knowledge, index]])) && setStateKnowledge([...knowledge, index])
                                                setCheckedIdknowledge(item.id)}
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setKnowledge(knowledge.includes(index) ? knowledge.filter((i) =>  i !== index) : [...knowledge, index]); setCheckedIdknowledge(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addKnowledge2 && checkedIdknowledge == item.id 
                            && 
                            addKnowledge2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setKnowledge2(
                                            (knowledge2.some((el, ind) => ind == index)) 
                                            ? knowledge2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...knowledge2, e.target.value]
                                        ); 
                                        dispatch(
                                            setKnowledge([item.id,
                                                (knowledge2.some((el, ind) => ind == index)) 
                                                ? [...knowledge, ...[...knowledge2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...knowledge, ...[...knowledge2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.knowledge[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddKnowledge2([...knowledge2, true]); setCheckedIdknowledge(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addKnowledge2 && checkedIdknowledge == item.id 
                            ? 
                            <input type="text" class="autofilssInput" value={checkedIdknowledge == item.id ? knowledge2 : ""} onChange={(e) => {dispatch(setKnowledge([item.id, [...knowledge, e.target.value]])) && setKnowledge2([e.target.value]); setCheckedItem(item); setCheckedIdknowledge(item.id)}}></input>
                            :
                            <button onClick={(e) => {e.preventDefault(); setAddKnowledge2(true); setCheckedIdknowledge(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellKnowledge;