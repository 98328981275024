import '../App.css';
const Contractors = () => {
	return (
		<div>
		<main>
			<div class="container">
			<div class="header-title center"><h1>CONTRACTORS</h1> </div>
				<div class="contractors-bl">
					<div class="contractors-img"><img src="img/contractors.png"/></div>
					<div class="contractors-list">
						<div class="contractors-tree">
							<div class="contractors-uls">
								<ul>
									<li>Energy development<br/>
									<span>Oman</span></li>
									<li><span>Oman</span> liquified  natural<br/>
									gas llC. </li>
									<li>Ray Petroleum</li>
								</ul>
							</div>
						</div>

						<div class="contractors-tree">
							<div class="contractors-uls">
								<ul>
									<li><span>Oman</span> Cables </li>
									<li>occidental petroleum<br/>
									corporation</li>

								</ul>
							</div>
						</div>

						<div class="contractors-tree">
							<div class="contractors-uls">
								<ul>
									<li>MB Peteroleum<br/><span>oman</span></li>
									<li>SHELL</li>

								</ul>
							</div>
						</div>

					</div>
				</div>
				
			</div>
		</main>
	</div>
	)
}
export default Contractors;