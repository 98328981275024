import React, { useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setEmotionally, setNewEmotionally } from "../../slices/riskSlice";

const TableCellEmotionally = ({item, addNew}) => {

    const [emotionally2, setEmotionally2] = useState([]);
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdemotionally, setCheckedIdemotionally] = useState("");
    const [addEmotionally, setAddEmotionally] = useState([]);
	const [addEmotionally2, setAddEmotionally2] = useState([]);
    const [emotionally, setStateEmotionally] = useState([]);
    const dispatch = useDispatch();

    return (
        <> 
            {/* {(!item && addNew) &&
                <>
                    {addEmotionally ? 
                        <input type="text" className="autofilssInput" value={emotionally} onChange={(e) => dispatch(setNewEmotionally(e.target.value)) && setStateEmotionally(e.target.value)}></input>
                        :
                        <button onClick={(e) => {e.preventDefault(); setAddEmotionally(true)}}>+ Add</button>
                    }
                </>
            } */}
            {item &&
                <div class="hovers" style={item.emotionally[0] === "" ? {overflow: "hidden"} : {}}>
                    <ul style={item.emotionally[0] === "" ? {overflow: "hidden", padding: "0px", margin: "0px"} : {padding: "0px", margin: "0px"}}>
                        {item.emotionally.map((index) => (
                            <>	
                                {index === "" 
                                    ? <div class="hovers" >
                                        <ul style={{padding: "0px", margin: "0px"}}>
                                            {addEmotionally.map((elem,indx) => 
                                                elem &&
                                                <li class="autofilss" key={index.id}>
                                                    <input type="text" class="autofilssInput" onChange={(e) => 
                                                        {
                                                            e.preventDefault(); 
                                                            console.log(e.target.value)
                                                            setEmotionally2(
                                                                (emotionally2.some((el, ind) => ind == indx)) 
                                                                ? emotionally2.map((el,i) => (i == indx) ? el = e.target.value : el)
                                                                : [...emotionally2, e.target.value]
                                                            );
                                                            dispatch(
                                                                setEmotionally(
                                                                    [   
                                                                        item.id, 
                                                                        (emotionally2.some((el, ind) => ind == indx)) 
                                                                        ? [...emotionally2.map((el,i) => (i == indx) ? el = e.target.value : el)] 
                                                                        : [...emotionally2, e.target.value]
                                                                    ]
                                                                )
                                                            );
                                                        }
                                                    }></input>
                                                </li>
                                            )}
                                            <div class="autofilss">
                                                <button onClick={(e) => {e.preventDefault(); setAddEmotionally([...addEmotionally, true]); setCheckedIdemotionally(item.id)}}>+ Add</button>
                                            </div>                                        
                                        </ul>
                                    </div>
                                    // <div class="autofilss" key={index.id} >
                                    //     {addEmotionally2 && checkedIdemotionally == item.id 
                                    //         ? 
                                    //         <input type="text" class="autofilssInput" value={checkedIdemotionally == item.id ? emotionally2 : ""} onChange={(e) => {dispatch(setEmotionally([item.id, [e.target.value]])) && setEmotionally2(e.target.value); setCheckedItem(item); setCheckedIdemotionally(item.id)}}></input>
                                    //         :
                                    //         <button onClick={(e) => {e.preventDefault(); setAddEmotionally2(true); setCheckedIdemotionally(item.id)}}>+ Add</button>
                                    //     }
                                    // </div> 
                                    : 
                                    <> 
                                        <li class="autofilss" key={index.id} >
                                            <input type="checkbox" checked={(emotionally.includes(index) && checkedIdemotionally == item.id) ? true : false} onChange={e => setCheckedItem(e.target.value)}/>
                                            <span onClick={(e) => {e.preventDefault();
                                            emotionally.some(i => i == index)
                                                ? dispatch(setEmotionally([item.id, (emotionally2.length != 0) ? [...emotionally.filter(i => i != index), ...emotionally2] : emotionally.filter(i => i != index)])) && setStateEmotionally(emotionally.filter(i => i != index))
                                                : dispatch(setEmotionally([item.id, (emotionally2.length != 0) ? [...[...emotionally, index], ...emotionally2] : [...emotionally, index]])) && setStateEmotionally([...emotionally, index]);
                                            setCheckedIdemotionally(item.id)}
                                            }></span>
                                            {/* <span onClick={(e) => {e.preventDefault(); setEmotionally(emotionally.includes(index) ? emotionally.filter((i) =>  i !== index) : [...emotionally, index]); setCheckedIdemotionally(item.id)}}></span> */}
                                            <Popover content={index}>
                                                <div class="truncate" style={{width: "300px"}}>{index}</div>
                                            </Popover>
                                        </li>
                                    </>
                                }
                            </>
                        ))}
                        {addEmotionally2 && checkedIdemotionally == item.id 
                            && 
                            addEmotionally2.map((elem, index) => 
                                elem &&
                                <li class="autofilss">
                                    <input type="text" class="autofilssInput" onChange={(e) => {
                                        e.preventDefault(); 
                                        setEmotionally2(
                                            (emotionally2.some((el, ind) => ind == index)) 
                                            ? emotionally2.map((el, ind) => (ind == index) ? el = e.target.value : el) 
                                            : [...emotionally2, e.target.value]
                                        ); 
                                        dispatch(
                                            setEmotionally([item.id,
                                                (emotionally2.some((el, ind) => ind == index)) 
                                                ? [...emotionally, ...[...emotionally2.map((el, ind) => (ind == index) ? el = e.target.value : el)]] 
                                                : [...emotionally, ...[...emotionally2, e.target.value]]]
                                            )
                                        );
                                    }}></input>
                                </li>
                            )
                        }
                        {item.emotionally[0] != "" &&
                            <div class="autofilss">
                                <button onClick={(e) => {e.preventDefault(); setAddEmotionally2([...emotionally2, true]); setCheckedIdemotionally(item.id)}}>+ Add</button>
                            </div>
                        }
                        {/* <div class="autofilss">
                            {addEmotionally2 && checkedIdemotionally == item.id 
                                ? 
                                <input type="text" class="autofilssInput" value={checkedIdemotionally == item.id ? emotionally2 : ""} onChange={(e) => {dispatch(setEmotionally([item.id, [...emotionally, e.target.value]])) && setEmotionally2([e.target.value]); setCheckedItem(item); setCheckedIdemotionally(item.id)}}></input>
                                :
                                <button onClick={(e) => {e.preventDefault(); setAddEmotionally2(true); setCheckedIdemotionally(item.id)}}>+ Add</button>
                            }
                        </div> */}
                    </ul>
                </div>
            }
        </>
    )
};

export default TableCellEmotionally;