import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { Provider} from 'react-redux';
import ProtectedRoute from './routing/ProtectedRoute';

import './App.css';
import NavBar  from './components/NavBar'
import Footer from './components/Footer'
import About from './pages/About'
import Gettoknow from './pages/Gettoknow'
import Solutions from './pages/Solutions'
import Contact from './pages/Contact'
import Contractors from './pages/Contractors'
import Main from './pages/Main'
import Lkabinet from './pages/Lkabinet'
import LkDashboard from './pages/LkDashboard'
import LkNotification from './pages/LkNotification'
import Risk from './pages/Risk'
import MyRisk from './pages/MyRisk'
import Dashboard from './pages/Dashboard'
import RiskTable from './pages/RiskTable'
import Forgot from './pages/ForgotPassword'
import Templates from './pages/Templates'
import store from './store/store.js';
import ReviewRiskTable from './pages/ReviewRiskTable';


function App() {

	
  return (
	<div class="oversite">
		<Provider store={store}>
			<Router>
				<NavBar />
				<Routes>
					<Route path="/about" exact element={<About/>} />
					<Route path="/gettoknow" exact element={<Gettoknow/>} />
					<Route path="/contractors" exact element={<Contractors/>} />
					<Route path="/solutions" exact element={<Solutions/>} />
					<Route path="/contacts" exact element={<Contact/>} />
					<Route path="/" exact element={<Main/>} />
					<Route path="/login" exact element={<Main/>} />

					{/* <Route path="/lk/dashboard" exact element={<LkDashboard/>} /> */}
					{/* <Route path="/lk/notification" exact element={<LkNotification/>} /> */}
					{/* <Route path="/risk/" exact element={<Risk/>} /> */}
					{/* <Route path="/myrisk/" exact element={<MyRisk/>} /> */}
					{/* <Route path="/dashboard/" exact element={<Dashboard/>} /> */}
					{/* <Route path="/risktable/" exact element={<RiskTable/>} /> */}
					{/* <Route path="/risktable2/" exact element={<RiskTable/>} /> */}
					{/* <Route path="/reviewrisktable/" exact element={<ReviewRiskTable/>} /> */}
					{/* <Route path="/templates/" exact element={<Templates/>} /> */}
					{/* <Route path="/forgot/" exact element={<Forgot/>} /> */}
					{/* <Route path="/lk" exact element={<Lkabinet/>} /> */}
					<Route element={<ProtectedRoute />}>
						<Route path="/lk" exact element={<Lkabinet/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/lk/dashboard" exact element={<LkDashboard/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/lk/notification" exact element={<LkNotification/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/risk/" exact element={<Risk/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/myrisk/" exact element={<MyRisk/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/dashboard/" exact element={<Dashboard/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/risktable/" exact element={<RiskTable/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/risktable2/" exact element={<RiskTable/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/templates/" exact element={<Templates/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/forgot/" exact element={<Forgot/>} />
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path="/reviewrisktable/" exact element={<ReviewRiskTable/>} />
					</Route>
					<Route path="*" exact element={<Main/>} />
				</Routes>
			</Router>
			<Footer/>
		</Provider>
	</div>

  )
}
export default App;
