import React, { useEffect, useState } from "react";
import { Modal, Spin, Button, Popover} from "antd";
import { useDispatch } from "react-redux";
import { setEnvironment, setNewEnvironment } from "../../slices/riskSlice";

const TableCellEnvironmentNew = ({item, addNew}) => {

    const dispatch = useDispatch();
    const [environment2, setStateEnvironment2] = useState("");
    const [checkedItem, setCheckedItem] = useState(null);
    const [checkedIdenvironment, setCheckedIdenvironment] = useState("");

	const [addEnvironment2, setAddEnvironment2] = useState(false);
    const [environment, setStateEnvironment] = useState([]);
    const [environmentArray, setStateEnvironmentArray] = useState([]);
    
    const [environmentArrayUl, setStateEnvironmentArrayUl] = useState([]);

    return (
        <>
            {environmentArray.map((element, index) => 
                <>
                    {element
                        &&
                        <div className={`hovers ${index}`}>
                            <ul style={{padding: '0', margin: '0'}}>
                                {
                                    // console.log(environmentArrayUl)
                                    environmentArrayUl.map((item, indx) =>
                                        (indx == index) && item.map((i, ind) =>
                                        i &&
                                        <li class="autofilss">
                                            <input type="text" className="autofilssInput" onChange={(e) => dispatch(setNewEnvironment([index.toString(), ind, e.target.value ]))}></input>
                                        </li>
                                    ))
                                }
                                <div className="autofilss">
                                    <button onClick={(e) => {e.preventDefault(); setStateEnvironmentArrayUl(environmentArrayUl.map((item, ind) => (ind == index) ? item = [...item, true] : item));}}>+ Add</button>
                                </div>  
                            </ul>
                        </div>   
                    }    
                </>
            )}
            <div className="autofilss">
                <button onClick={(e) => {e.preventDefault(); setStateEnvironmentArray([...environmentArray, true]); setStateEnvironmentArrayUl([...environmentArrayUl, [true]])}}>+ Add</button>
            </div>  
        </>
    )
};

export default TableCellEnvironmentNew;